import React from "react";

export default function Mobile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="67"
      fill="none"
      viewBox="0 0 57 67"
    >
      <g filter="url(#filter0_d_360_2963)">
        <path
          fill="url(#paint0_linear_360_2963)"
          d="M41.629 34.58l9.59-9.465A2.625 2.625 0 0052 23.256a2.598 2.598 0 00-.78-1.858 2.666 2.666 0 00-1.884-.77 2.695 2.695 0 00-1.883.77l-5.05 4.956V10.088c0-1.45-1.202-2.637-2.671-2.637-1.47 0-2.672 1.187-2.672 2.637v16.266l-5.05-4.956a2.666 2.666 0 00-1.883-.77 2.694 2.694 0 00-1.883.77 2.624 2.624 0 00-.78 1.858 2.598 2.598 0 00.78 1.86l9.59 9.464c1.07 1.028 2.753 1.028 3.794 0z"
        ></path>
        <path
          fill="url(#paint1_linear_360_2963)"
          d="M37.06 46.997H10.343V15.36h18.702V2.179H10.343C7.404 2.179 5 4.55 5 7.45v47.455c0 2.9 2.404 5.273 5.343 5.273H37.06c2.939 0 5.343-2.373 5.343-5.273V41.724H37.06v5.273z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_360_2963"
          width="55.5"
          height="66.5"
          x="0.75"
          y="0.429"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.5"></feOffset>
          <feGaussianBlur stdDeviation="2.125"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.110987 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_360_2963"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_360_2963"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_360_2963"
          x1="5"
          x2="5"
          y1="2.179"
          y2="60.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_360_2963"
          x1="5"
          x2="5"
          y1="2.179"
          y2="60.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
