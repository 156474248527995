"use client";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import useIsTablet from "hooks/useIsTablet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IPwaComponent } from "interfaces/pwas";
import { getToken } from "components/Stats";
import Preloader from "components/GlobalPreloader/Preloader";
import { IFilterState } from "components/Filter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Calendar from "assets/icons/Calendar";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StatsListTable from "components/StatsList/StatsListTable";
import { useAppStore } from "store/appStore";

const StatsTable = ({ loading, pwas, clicks, totalInstallCount, allClicks }: IPwaComponent) => {
  const [view, setView] = useState<"table" | "grid">("grid");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState<IFilterState>({ status: [], country: [], categoryVertical: [] });
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const appData = useAppStore();
  const { t } = useTranslation();

  console.log("vertical: ", appData);

  if (loading) {
    return <Preloader />;
  }

  const TypographyStyled = styled(Typography)(() => ({
    color: "#6D6777",
    fontFamily: "var(--cygreMedium), sans-serif",
  }));

  const SwitchButton = styled(Button)(({ active }: { active: boolean }) => ({
    borderRadius: 10,
    backgroundColor: active ? "#ffffff" : "transparent",
    padding: "0px 5px",
    minWidth: "auto",
    color: "#F4701B",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  }));

  const ViewSwitch = ({ view, toggleView }: { view: "table" | "grid"; toggleView: () => void }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F6F7FB",
        padding: "5px",
        borderRadius: "10px",
        width: "74px",
        height: "40px",
      }}
    >
      <SwitchButton onClick={() => view !== "grid" && toggleView()} active={view === "grid"}>
        {view === "grid" ? <ViewModuleIcon /> : <ViewModuleOutlinedIcon sx={{ color: "#6D6777" }} />}
      </SwitchButton>
      <SwitchButton onClick={() => view !== "table" && toggleView()} active={view === "table"}>
        {view === "table" ? <MenuIcon /> : <MenuIcon sx={{ color: "#6D6777" }} />}
      </SwitchButton>
    </div>
  );

  const toggleView = () => {
    setView(view === "table" ? "grid" : "table");
  };

  const filteredItems = pwas?.filter((item: any) => {
    const matchesSearch = item.appName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilters = Object.keys(filter).every((filterType) => {
      return filter[filterType].length === 0 || filter[filterType].includes(item[filterType]);
    });
    return matchesSearch && matchesFilters;
  });

  const groupedClicks = clicks?.reduce((acc: any, click: any) => {
    const { day, campaign_unique_clicks, cpc, clicks, installs, regs, deposits } = click;

    console.log("grou", click);

    if (!acc[day]) {
      acc[day] = {
        clicks: 0,
        campaign_unique_clicks: 0,
        cpc: 0,
        installs: 0,
        deposits: 0,
        regs: 0,
      };
    }

    acc[day].clicks += clicks;
    acc[day].campaign_unique_clicks += campaign_unique_clicks;
    acc[day].cpc += cpc;
    acc[day].installs += installs;
    acc[day].regs += regs;
    acc[day].deposits += deposits;

    return acc;
  }, {});

  const today = new Date();
  const formattedDate = today.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const groupedStats =
    pwas && allClicks
      ? pwas.map((pwa) => {
          const pwaClicks = allClicks.filter((click: any) => click.campaign === pwa.appName);

          const totalClicks = pwaClicks.reduce((sum: number, click: any) => sum + (click.clicks || 0), 0);

          const totalUniqueClicks = pwaClicks.reduce(
            (sum: number, click: any) => sum + (click.campaign_unique_clicks || 0),
            0
          );
          const totalInstalls = pwaClicks.reduce((sum: number, click: any) => sum + (click.installs || 0), 0);
          const totalRegs = pwaClicks.reduce((sum: number, click: any) => sum + (click.regs || 0), 0);
          const totalDeposits = pwaClicks.reduce((sum: number, click: any) => sum + (click.deposits || 0), 0);

          return {
            appName: pwa.appName,
            clicks: totalClicks,
            uniqueClicks: totalUniqueClicks,
            installs: totalInstalls,
            regs: totalRegs,
            deposits: totalDeposits,
            icon: pwa.creatives?.icon || "",
          };
        })
      : [];

  const renderCards = () => {
    if (!groupedStats || groupedStats.length === 0) {
      return null;
    }

    return groupedStats.map((stat, index) => {
      const iconSrc = typeof stat.icon === "string" ? stat.icon : URL.createObjectURL(stat.icon);

      return (
        <Grid item xs={12} lg={12 / 2} xl={12 / 3} sx={{ overflow: "hidden" }}>
          <Card
            key={index}
            tabIndex={-1}
            sx={{
              borderRadius: "8px",
              margin: "8px",
              boxShadow: "0px 0px 4px 0px rgba(165, 163, 174, 0.3)",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                paddingBottom: "16px !important",
              }}
            >
              <Box
                sx={{
                  borderBottom: "2px solid #EEEEEE",
                  padding: "20px",
                  fontFamily: "var(--cygreMedium), sans-serif",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconSrc}
                  alt={`${stat.appName} icon`}
                  style={{ width: "30px", height: "30px", marginRight: "10px", borderRadius: "50%" }}
                />

                {stat.appName}
              </Box>
              <Box sx={{ borderBottom: "2px solid #EEEEEE" }}>
                <Box
                  sx={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    {t("clicks")}: {stat.clicks | 0}
                  </Box>
                  <Box>
                    {t("installs")}: {totalInstallCount}
                  </Box>
                  <Box>
                    {t("regs")}: {stat.regs | 0}
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    {t("unique")}: {stat.uniqueClicks | 0}
                  </Box>
                  <Box>
                    {t("open")}: {stat.clicks | 0}
                  </Box>
                  <Box>
                    {t("deps")}: {stat.deposits | 0}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ borderBottom: "2px solid #EEEEEE" }}>
                <Box
                  sx={{
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginRight: "20px",
                  }}
                >
                  <Box>
                    {t("push")}: {0 | 0}
                  </Box>
                  <Box>
                    {t("reopen")}: {0 | 0}
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    paddingBottom: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    paddingLeft: "24px",
                  }}
                >
                  <Box>
                    {t("cpi")}: {0}$
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginRight: "5px",
                  }}
                >
                  <Box>
                    {t("crInst")}: {0 | 0}
                  </Box>
                  <Box>
                    {t("crPush")}: {0 | 0}
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "5px",
                    paddingBottom: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    paddingLeft: "24px",
                  }}
                >
                  <Box>
                    {t("crInstPush")}: {0 | 0}$
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <Box>
      <Box sx={{ overflow: "auto", width: { sm: "auto" } }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            alignItems: "normal",
            marginBottom: isMobile ? "15px" : "0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "normal",
              width: "100%",
              marginBottom: "16px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {/* <SearchBar onSearch={() => {}} placeholder={t("searchname")} /> */}
            {/* <StatsFilter filter={filter} setFilter={setFilter} /> */}
            {/* <GroupButton isMobile={isMobile} t={t} />
          <ColumnButton isMobile={isMobile} t={t} /> */}
          </Box>
          {isTablet ? null : <ViewSwitch view={view} toggleView={toggleView} />}
        </Grid>

        {view === "table" ? (
          <div style={{ maxWidth: "100vw", overflow: "auto" }}>
            <StatsListTable totalInstallCount={totalInstallCount} groupedClicks={groupedClicks} />
          </div>
        ) : (
          <Grid container spacing={2}>
            {Object.entries(groupedClicks).length > 0 ? (
              Object.entries(groupedClicks).map(([day, aggregatedData]: [string, any]) => {
                const formatDate = (dateString: string): string => {
                  const date = new Date(dateString);
                  return date.toLocaleDateString("ru-RU", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  });
                };

                console.log("agre", aggregatedData);

                const formattedDate = formatDate(day);
                return (
                  <Grid item xs={12} lg={12 / 2} xl={12 / 3}>
                    <Card
                      key={day}
                      tabIndex={-1}
                      sx={{
                        borderRadius: "8px",
                        margin: "8px",
                        boxShadow: "0px 0px 4px 0px rgba(165, 163, 174, 0.3)",
                        height: "100%",
                      }}
                    >
                      <CardContent
                        sx={{
                          boxShadow: "none",
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          paddingBottom: "16px !important",
                        }}
                      >
                        <Box
                          sx={{
                            borderBottom: "2px solid #EEEEEE",
                            padding: "20px",
                            fontFamily: "var(--cygreMedium), sans-serif",
                            fontWeight: "500",
                          }}
                        >
                          {formattedDate}
                        </Box>
                        <Box sx={{ borderBottom: "2px solid #EEEEEE" }}>
                          <Box
                            sx={{
                              padding: "10px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <Box>
                              {t("clicks")}: {aggregatedData.clicks | 0}
                            </Box>
                            <Box>
                              {t("installs")}: {totalInstallCount}
                            </Box>
                            <Box>
                              {t("regs")}: {aggregatedData.regs | 0}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              padding: "10px",
                              paddingBottom: "10px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <Box>
                              {t("unique")}: {aggregatedData.campaign_unique_clicks | 0}
                            </Box>
                            <Box>
                              {t("open")}: {aggregatedData.clicks | 0}
                            </Box>
                            <Box>
                              {t("deps")}: {aggregatedData.deposits | 0}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ borderBottom: "2px solid #EEEEEE" }}>
                          <Box
                            sx={{
                              padding: "5px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              marginRight: "20px",
                            }}
                          >
                            <Box>
                              {t("push")}: {0 | 0}
                            </Box>
                            <Box>
                              {t("reopen")}: {0 | 0}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              padding: "10px",
                              paddingBottom: "5px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              paddingLeft: "24px",
                            }}
                          >
                            <Box>
                              {t("cpi")}: {0}$
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              padding: "10px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              marginRight: "5px",
                            }}
                          >
                            <Box>
                              {t("crInst")}: {0 | 0}
                            </Box>
                            <Box>
                              {t("crPush")}: {0 | 0}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              padding: "5px",
                              paddingBottom: "5px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              paddingLeft: "24px",
                            }}
                          >
                            <Box>
                              {t("crInstPush")}: {0 | 0}$
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <>{renderCards()}</>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default StatsTable;
