import { ChangeEvent } from "react";
import { faker } from "@faker-js/faker";
import { IComment } from "interfaces/mockupApp";

export const handleDownloadAvatar = (
  e: ChangeEvent<HTMLInputElement>,
  setAvatar: (url: string) => void,
  setFormData: (updateFn: (prevState: any) => any) => void
) => {
  if (e.target.files && e.target.files[0]) {
    const reader = new FileReader();
    reader.onload = () => {
      setAvatar(reader.result as string);
      setFormData((prevState) => ({
        ...prevState,
        avatar: reader.result as string,
      }));
    };
    reader.readAsDataURL(e.target.files[0]);
  }
};

export const handleGenerateRandomAvatar = (
  setAvatar: (url: string) => void,
  setFormData: (updateFn: (prevState: any) => any) => void
) => {
  const randomAvatar = faker.image.avatar();
  setAvatar(randomAvatar);
  setFormData((prevState) => ({
    ...prevState,
    avatar: randomAvatar,
  }));
};

export const updateCommentAvatar = (
  author: string,
  newAvatar: string,
  comments: IComment[],
  saveAppData: (data: any) => void
) => {
  const updatedComments = comments.map((comment) =>
    comment.author === author ? { ...comment, avatar: newAvatar } : comment
  );
  saveAppData({ comments: updatedComments });
};

export const handleGenerateRandomAvatarForComment = (
  author: string,
  comments: IComment[],
  saveAppData: (data: any) => void
) => {
  const randomAvatar = faker.image.avatar();
  updateCommentAvatar(author, randomAvatar, comments, saveAppData);
};

export const deleteComment = (author: string, comments: IComment[], saveAppData: (data: any) => void) => {
  const updatedComments = comments.filter((comment) => comment.author !== author);
  saveAppData({ comments: updatedComments });
};
