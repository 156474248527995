import { useTheme, useMediaQuery } from "@mui/material";

const useIsTablet = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return isTablet;
};

export default useIsTablet;
