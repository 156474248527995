import React from "react";
import Button from "@mui/material/Button";
import { ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  const handleChange = React.useCallback(
    (newLocale: string) => () => {
      void i18n.changeLanguage(newLocale);
      const days = 30;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = "; expires=" + date.toUTCString();
      document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`;
    },
    []
  );
  return (
    <ButtonGroup
      variant="text"
      aria-label="Basic button group"
      sx={{
        "& .MuiButton-root": {
          color: "black",
          borderColor: "black",
          fontSize: 16,
          borderWidth: "1px",
          height: 11,
        },
        "& .MuiButton-root.active": {
          textDecoration: "underline",
        },
      }}
    >
      <Button className={currentLocale === "en" ? "active" : ""} onClick={handleChange("en")}>
        EN
      </Button>
      <Button className={currentLocale === "ru" ? "active" : ""} onClick={handleChange("ru")}>
        RU
      </Button>
      <Button className={currentLocale === "ua" ? "active" : ""} onClick={handleChange("ua")}>
        UA
      </Button>
    </ButtonGroup>
  );
}
