import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import useFetch from "hooks/useFetch";
import CustomButton from "components/Button";
import { Typography } from "@mui/material";
import CustomInput from "components/Input";
import { getToken } from "utils/getToken";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "520px",
  maxHeight: "80vh",
};

interface IModalCheckDomain {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function CopyGPlayModal({ open, setOpen }: IModalCheckDomain) {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const token = getToken();

  const { loading, triggerFetch } = useFetch({
    url: "",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // setFormData((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  const handleCopyImage = async () => {
    // handleClose();
    // const result = await triggerFetch();
    // if (result) {
    //   handleClose();
    // }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-check-domain-title"
        aria-describedby="modal-check-domain-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                textAlign: "left",
                fontSize: "24px",
                lineHeight: "45px",
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#2E263D",
              }}
            >
              {t("сopyfromGooglePlay")}
            </Typography>
            <Box aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "14px",
                lineHeight: "19px",
                fontFamily: "var(--cygreRegular), sans-serif",
                color: "#2E263D",
              }}
            >
              {t("Insertthelink")}
            </Typography>
          </Box>
          <Box
            sx={{
              paddingTop: "44px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="linkapp"
              placeholder=""
              name="linkapp"
              height="40px"
              label={t("linkonapp")}
              autoFocus
              //   value={formData.domain}
              onChange={handleInputChange}
              customBgColor="#F6F7F9"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "24px",
            }}
          >
            <CustomButton
              textButton={t("cancel")}
              height={40}
              customColor="#6D6777"
              customBorderColor="#C9D0D8"
              customHoverColor="transparent"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleClose}
            />
            <Box sx={{ width: "24px" }}></Box>
            <CustomButton
              textButton={t("сopyimages")}
              height={40}
              customBgColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleCopyImage}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
