import React from "react";

function CalendarClock() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <path
        fill="#1B84FF"
        d="M2 6.808h14v-2.5a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H2.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.5zM2.308 19.5c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 01.5 17.692V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V1.154c0-.22.074-.402.22-.55a.746.746 0 01.55-.22c.219 0 .402.074.549.22.146.148.22.33.22.55V2.5h7.577V1.135c0-.213.072-.391.215-.535a.727.727 0 01.535-.215c.213 0 .39.072.534.215a.726.726 0 01.216.535V2.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283V9.02a.725.725 0 01-.215.535.725.725 0 01-.535.215.725.725 0 01-.535-.215A.725.725 0 0116 9.02v-.713H2v9.384c0 .077.032.148.096.212a.294.294 0 00.212.096h6.117c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.535.726.726 0 01-.535.215H2.308zm12.884 1c-1.248 0-2.31-.438-3.186-1.314-.876-.875-1.314-1.937-1.314-3.186 0-1.249.438-2.31 1.314-3.187.875-.875 1.938-1.313 3.186-1.313 1.25 0 2.311.438 3.187 1.313.875.876 1.313 1.938 1.313 3.187 0 1.249-.438 2.31-1.313 3.186-.876.876-1.938 1.314-3.187 1.314zm.443-4.68V13.5a.425.425 0 00-.131-.312.425.425 0 00-.312-.13.425.425 0 00-.311.13.425.425 0 00-.131.312v2.304a.904.904 0 00.271.648l1.525 1.525c.087.087.19.132.307.136a.42.42 0 00.316-.136.432.432 0 00.14-.311.432.432 0 00-.14-.312l-1.534-1.535z"
      ></path>
    </svg>
  );
}

export default CalendarClock;
