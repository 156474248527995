import React, { useRef, useEffect, useState } from "react";
import HeaderSection from "components/HeaderSection";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import Cached from "assets/icons/cached";
import Flight from "assets/icons/flight";
import DuckFootIcon from "assets/icons/DuckFootIcon";
import Interaction from "assets/icons/interaction";
import Mobile from "assets/icons/mobile";
import Safety from "assets/icons/Safety";
import Scalability from "assets/icons/scalability";
import Zipper from "assets/icons/zipper";
import Feature from "./Feature";

export default function MainFeatures() {
  const { t } = useTranslation();
  const [showIcon1, setShowIcon1] = useState(false);
  const [showIcon2, setShowIcon2] = useState(false);
  const [showIcon3, setShowIcon3] = useState(false);
  const [showIcon4, setShowIcon4] = useState(false);

  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => setShowIcon1(true), 0);
            setTimeout(() => setShowIcon2(true), 500);
            setTimeout(() => setShowIcon3(true), 1000);
            setTimeout(() => setShowIcon4(true), 1500);

            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.66 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const features = [
    {
      title: t("offlinework"),
      text: t("offlineworkdescription"),
      icon: <Flight />,
    },
    {
      title: t("installationondevice"),
      text: t("installationondevicedescription"),
      icon: <Mobile />,
    },
    {
      title: t("fastloading"),
      text: t("fastloadingdescription"),
      icon: <Zipper />,
    },
    {
      title: t("scalability"),
      text: t("scalabilitydescription"),
      icon: <Scalability />,
    },
    {
      title: t("security"),
      text: t("securitydescription"),
      icon: <Safety />,
    },
    {
      title: t("effortlessupdates"),
      text: t("effortlessupdatesdescription"),
      icon: <Cached />,
    },
    {
      title: t("deviceinteraction"),
      text: t("deviceinteractiondescription"),
      icon: <Interaction />,
    },
  ];

  return (
    <Box
      ref={sectionRef}
      id="main-features"
      sx={{
        width: "100%",
        paddingTop: "80px",
        paddingBottom: "72px",
        position: "relative",
      }}
    >
      <HeaderSection title={t("mainfeatures")} />
      <Box sx={{ paddingTop: "56px", display: "grid", gap: "16px" }}>
        <Grid container spacing={2}>
          {features.slice(0, 2).map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Feature title={item.title} text={item.text} icon={item.icon} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          {features.slice(2, 5).map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Feature title={item.title} text={item.text} icon={item.icon} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          {features.slice(5, 7).map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Feature title={item.title} text={item.text} icon={item.icon} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {showIcon1 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: "84%",
            left: "107%",
            transform: "rotate(49deg)",
            opacity: showIcon1 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon2 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: { xs: "97%", lg: "88%" },
            left: { xs: "99%", xl: "102%" },
            transform: "rotate(92deg)",
            opacity: showIcon2 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon3 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: { xs: "97%", md: "94%", lg: "94%" },
            left: { xs: "81%", md: "90%", lg: "95%", xl: "98%" },
            transform: "rotate(150deg)",
            opacity: showIcon3 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon4 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: { xs: "96%", md: "", lg: "93%" },
            left: { xs: "61%", md: "81%", lg: "89%", xl: "92%" },
            transform: "rotate(130deg)",
            opacity: showIcon4 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
    </Box>
  );
}
