import IIcon from "interfaces/icon";
import React from "react";

function Trash({ width = 16, height = 18 }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 16 18">
      <path
        fill="#2E263D"
        d="M3.308 17.5a1.74 1.74 0 01-1.277-.531 1.74 1.74 0 01-.531-1.277V3h-.25a.725.725 0 01-.534-.216A.726.726 0 01.5 2.25c0-.213.072-.391.216-.535A.725.725 0 011.25 1.5H5c0-.245.086-.454.259-.626a.852.852 0 01.625-.259h4.232c.244 0 .453.087.625.259A.853.853 0 0111 1.5h3.75c.213 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.391-.216.534A.726.726 0 0114.75 3h-.25v12.692c0 .497-.177.923-.531 1.277a1.74 1.74 0 01-1.277.531H3.308zM13 3H3v12.692a.3.3 0 00.087.222.3.3 0 00.22.086h9.385a.3.3 0 00.222-.086.3.3 0 00.086-.222V3zM6.154 14c.213 0 .39-.072.535-.216a.727.727 0 00.215-.534v-7.5a.726.726 0 00-.216-.535A.726.726 0 006.154 5a.725.725 0 00-.535.215.727.727 0 00-.215.535v7.5c0 .213.072.39.216.534a.727.727 0 00.534.216zm3.693 0c.212 0 .39-.072.534-.216a.727.727 0 00.215-.534v-7.5a.726.726 0 00-.216-.535A.727.727 0 009.846 5a.725.725 0 00-.534.215.727.727 0 00-.216.535v7.5c0 .213.072.39.216.534a.726.726 0 00.535.216z"
      ></path>
    </svg>
  );
}

export default Trash;
