import React from "react";

function ShowFalse() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" fill="none" viewBox="0 0 18 10">
      <path
        fill="#1C1C1C"
        d="M6.79 10l-1.606-.426.654-2.414a9.173 9.173 0 01-2.691-1.538l-1.79 1.77L.18 6.23l1.79-1.77A8.958 8.958 0 010 .295L1.636 0a7.378 7.378 0 002.569 4.354A7.54 7.54 0 009 6.071a7.54 7.54 0 004.795-1.717A7.378 7.378 0 0016.364 0L18 .294a8.957 8.957 0 01-1.972 4.166l1.79 1.77-1.175 1.162-1.79-1.77A9.173 9.173 0 0112.16 7.16l.655 2.415L11.21 10l-.656-2.415a9.255 9.255 0 01-3.11 0L6.79 10z"
      ></path>
    </svg>
  );
}

export default ShowFalse;
