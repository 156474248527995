import React from "react";
import IIcon from "interfaces/icon";

function ImportPwa({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none" viewBox="0 0 14 19">
      <path
        fill="#6D6777"
        d="M7 18.17c-1.795 0-3.353-.583-4.676-1.748S.229 13.794.008 12.032a.822.822 0 01.215-.687.872.872 0 01.677-.296c.273 0 .502.074.686.22.185.146.301.332.349.56.186 1.28.76 2.332 1.72 3.16A4.973 4.973 0 007 16.227c1.27 0 2.384-.413 3.345-1.24.96-.826 1.534-1.88 1.72-3.16a.941.941 0 01.349-.56 1.07 1.07 0 01.686-.219c.273 0 .499.099.677.296.178.196.25.426.215.688-.221 1.76-.993 3.224-2.316 4.39C10.353 17.586 8.795 18.17 7 18.17zm-.963-9.565V1.14c0-.276.092-.506.277-.692A.928.928 0 017 .169c.273 0 .502.094.686.28a.944.944 0 01.277.692v7.464l1.7-1.713a.903.903 0 01.67-.285.95.95 0 01.683.305c.186.2.282.428.289.682a.91.91 0 01-.29.682l-3.202 3.23c-.12.12-.248.206-.38.256-.134.05-.278.074-.433.074-.155 0-.299-.025-.432-.074a1.068 1.068 0 01-.38-.257L2.983 8.276a.932.932 0 01-.273-.666.972.972 0 01.273-.698.94.94 0 01.687-.301c.258 0 .487.1.686.301l1.68 1.693z"
      ></path>
    </svg>
  );
}

export default ImportPwa;
