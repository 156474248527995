// components/SearchBar.tsx
import React, { useState } from "react";
import { List, ListItem, ListItemText, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface ISearch {
  placeholder?: string;
  onSearch: (value: string) => void;
}

const SearchBar: React.FC<ISearch> = ({ placeholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div>
      <OutlinedInput
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleSearch}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="search" edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          width: { xs: "100%", sm: "295px" },
          fontFamily: "var(--cygreRegular), sans-serif",
          fontWeight: 400,
          fontSize: 16,
          height: "40px",
          backgroundColor: "#F6F7FB",
          borderRadius: "8px",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
    </div>
  );
};

export default SearchBar;

