import React from "react";

export default function Flight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <g filter="url(#filter0_d_355_4917)">
        <path
          fill="url(#paint0_linear_355_4917)"
          d="M11.554 35.625l-6.042-3.323c-.443-.242-.695-.594-.755-1.057-.06-.463.07-.856.393-1.178l.725-.725a1.27 1.27 0 01.573-.333c.222-.06.433-.07.635-.03l5.316.725 9.426-9.425L5.39 11.337c-.604-.322-.956-.825-1.057-1.51-.1-.685.09-1.269.574-1.752l.604-.604c.282-.282.594-.463.936-.544.343-.08.695-.08 1.058 0l21.931 5.619 9.485-9.365c.685-.685 1.541-1.027 2.568-1.027 1.027 0 1.883.342 2.568 1.027.685.685 1.027 1.54 1.027 2.568 0 1.027-.342 1.883-1.027 2.568l-9.425 9.425 5.619 21.81c.08.403.07.795-.03 1.178-.101.383-.293.715-.575.997l-.302.302c-.563.564-1.218.785-1.963.665-.745-.121-1.3-.524-1.662-1.209l-8.82-16.13-9.425 9.424.724 5.196c.04.282.02.544-.06.785-.08.242-.221.464-.423.665l-.302.302a1.7 1.7 0 01-1.45.514c-.564-.06-.987-.353-1.269-.877l-3.141-5.74z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_355_4917"
          width="49.277"
          height="49.072"
          x="0.058"
          y="0.404"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.5"></feOffset>
          <feGaussianBlur stdDeviation="2.125"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.110987 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_355_4917"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_355_4917"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_355_4917"
          x1="4.308"
          x2="4.308"
          y1="2.154"
          y2="42.726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
