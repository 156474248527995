import React from "react";

function DomainVerificationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 24 24">
      <path
        fill="#6D6777"
        d="M4.308 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308zm0-1.5h15.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V8H4v9.692c0 .077.032.148.096.212a.294.294 0 00.212.096zm6.642-3.985l3.677-3.677a.75.75 0 01.537-.227.732.732 0 01.547.227.743.743 0 01.233.543.743.743 0 01-.233.542l-4.128 4.129a.868.868 0 01-.633.271.867.867 0 01-.633-.271L8.29 13.523a.75.75 0 01-.229-.537.732.732 0 01.229-.548.743.743 0 01.542-.232c.206 0 .387.077.542.232l1.577 1.577zM4.308 18H4V6h.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 00.212.096z"
      ></path>
    </svg>
  );
}

export default DomainVerificationIcon;
