const showPreviewIcon = (icon: string | File): string => {
  if (typeof icon === "string") {
    return icon;
  } else if (icon instanceof File) {
    return URL.createObjectURL(icon);
  }
  return "";
};

export default showPreviewIcon;
