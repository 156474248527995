import React, { useState, FormEvent } from "react";
import { Box, Typography } from "@mui/material";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "hooks/useFetch";
import Lock from "assets/icons/Lock";
import ToastMessage from "components/ToastMessage";
import ShowFalse from "assets/icons/ShowFalse";
import ShowTrue from "assets/icons/ShowYes";
// import { useNavigate } from "react-router";
import globalLoaderStore from "store/globalLoaderStore";

interface IForgotPassword {
  onClose: (value: boolean) => void;
  setAuthMode: (value: string) => void;
}

export function PasswordRecovery({ onClose, setAuthMode }: IForgotPassword) {
  const { t } = useTranslation();
  const setIsLoading = globalLoaderStore((state) => state.setIsGlobalLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("forgotPass");

  const [newPsw, setNewPsw] = useState(false);
  const [confirmPsw, setConfirmPsw] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const toggleNewPsw = () => {
    setNewPsw(!newPsw);
  };
  const toggleConfirmPsw = () => {
    setConfirmPsw(!confirmPsw);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "newPassword") {
      if (value.length < 8) {
        setErrors({ ...errors, newPassword: t("passwordToShort") });
      } else {
        setErrors({ ...errors, newPassword: "" });
      }
    }

    if (name === "confirmPassword") {
      if (value !== formData.newPassword) {
        setErrors({ ...errors, confirmPassword: t("passwordsDoNotMatch") });
      } else {
        setErrors({ ...errors, confirmPassword: "" });
      }
    }
  };

  const { loading, triggerFetch } = useFetch({
    url: `auth/reset-password/${token}`, // Использование токена из параметров
    method: "PATCH",
    body: { password: formData.newPassword },
    context: { no401Redirect: true },
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await triggerFetch();
    if (result) {
      ToastMessage({ mode: "info", message: result.message });
      setTimeout(() => {
        navigate("/");
        setAuthMode("login");
        setIsLoading(false);
      }, 1500);
    } else {
      ToastMessage({ mode: "error", message: result.message });
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", paddingTop: "24px" }}>
      <Box sx={{ width: "100%" }} component="form" onSubmit={handleSubmit} noValidate>
        <Typography
          sx={{
            fontSize: { xs: 34, md: 38 },
            lineHeight: "34px",
            textAlign: "center",
            paddingBottom: "12px",
          }}
          variant="subtitle1"
        >
          {t("passwordrecovery")}
        </Typography>
        <Typography sx={{ textAlign: "center", marginBottom: "10px" }} variant="subtitle2">
          {t("enteryournewpassword")}
        </Typography>
        <Box>
          <CustomInput
            margin="normal"
            required
            fullWidth
            id="newPassword"
            placeholder={t("newpsw")}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            iconStart={<Lock color="#000" width={19} height={20} />}
            type={newPsw ? "text" : "password"}
            iconEnd={
              <Box sx={{ cursor: "pointer", paddingTop: "5px" }} onClick={toggleNewPsw}>
                {newPsw ? <ShowTrue /> : <ShowFalse />}
              </Box>
            }
            customBgColor="#F6F7F9"
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword}
          />
        </Box>
        <Box>
          <CustomInput
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            placeholder={t("passwordconfirmation")}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            iconStart={<Lock color="#000" width={19} height={20} />}
            type={confirmPsw ? "text" : "password"}
            iconEnd={
              <Box sx={{ cursor: "pointer", paddingTop: "5px" }} onClick={toggleConfirmPsw}>
                {confirmPsw ? <ShowTrue /> : <ShowFalse />}
              </Box>
            }
            customBgColor="#F6F7F9"
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
        </Box>
        <Box sx={{ paddingTop: "15px" }}>
          <CustomButton
            loading={loading}
            customFontSize="18px"
            height={57}
            width="100%"
            textButton={t("buttonpasswordrecovery")}
            customBgColor="#000"
            customHoverColor="#F4701B"
            disabled={
              !formData.newPassword ||
              !formData.confirmPassword ||
              errors.newPassword !== "" ||
              errors.confirmPassword !== ""
            }
          />
        </Box>
        <Box
          sx={{ paddingTop: "10px", cursor: "pointer" }}
          onClick={() => {
            // router.push("/");
            onClose(false);
          }}
        >
          <Typography sx={{ textAlign: "center" }} variant="subtitle2">
            <span style={{ paddingRight: "5px" }}>&#8592;</span>
            {t("goBack")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
