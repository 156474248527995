import React from "react";

function Installs() {
  return (
    <div
      style={{
        background: "rgba(22, 177, 255, 0.16)",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28">
        <path
          fill="#16B1FF"
          d="M8.105 21h11.79c.313 0 .575-.106.787-.318a1.07 1.07 0 00.318-.788c0-.313-.106-.575-.318-.787a1.07 1.07 0 00-.787-.317H8.105c-.313 0-.575.105-.787.317a1.07 1.07 0 00-.318.788c0 .314.106.576.318.787.212.212.474.318.787.318zm4.79-8.82l-1.95-1.926a1.055 1.055 0 00-.758-.314 1.071 1.071 0 00-.798.34c-.212.21-.317.469-.317.773 0 .304.106.563.32.776l3.676 3.676c.266.266.577.4.932.4.355 0 .666-.134.932-.4l3.676-3.676c.204-.204.312-.457.324-.758a1.032 1.032 0 00-.327-.798 1.08 1.08 0 00-.762-.329 1.08 1.08 0 00-.788.31l-1.95 1.927V6.63c0-.313-.106-.575-.318-.787A1.07 1.07 0 0014 5.526c-.314 0-.576.106-.788.318a1.07 1.07 0 00-.317.788v5.549zM14.003 28c-1.937 0-3.757-.367-5.46-1.102a14.14 14.14 0 01-4.447-2.992 14.138 14.138 0 01-2.993-4.444C.368 17.758 0 15.939 0 14.002c0-1.936.367-3.756 1.102-5.46a14.14 14.14 0 012.992-4.446A14.14 14.14 0 018.54 1.103C10.242.368 12.06 0 13.997 0c1.937 0 3.757.367 5.46 1.102a14.14 14.14 0 014.447 2.992 14.14 14.14 0 012.993 4.445C27.632 10.242 28 12.06 28 13.997c0 1.937-.367 3.757-1.102 5.46a14.14 14.14 0 01-2.992 4.447 14.14 14.14 0 01-4.444 2.993c-1.704.735-3.523 1.103-5.46 1.103zM14 25.79c3.291 0 6.079-1.143 8.363-3.427S25.79 17.291 25.79 14c0-3.291-1.143-6.079-3.427-8.363S17.291 2.21 14 2.21c-3.291 0-6.079 1.142-8.363 3.426C3.353 7.92 2.21 10.709 2.21 14c0 3.291 1.142 6.079 3.426 8.363C7.92 24.647 10.709 25.79 14 25.79z"
        ></path>
      </svg>
    </div>
  );
}

export default Installs;
