import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface Option {
  name: string;
  value: string;
  label: string;
}

interface CustomSelectWithCheckboxesProps {
  options: Option[];
  title?: string;
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
  placeholder?: string;
}

const CustomFormControl = styled(FormControl)<{ noBorder?: boolean }>(({ noBorder }) => ({
  "& .MuiOutlinedInput-root": {
    height: 40,
    borderRadius: "8px",
    backgroundColor: "#F6F7FB",
    fontFamily: "var(--cygreMedium), sans-serif",
    border: "none",
    "& fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "#F4701B",
    },
  },
}));

const CustomSelect = styled(Select)<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  height: 40,

  "& .MuiSelect-select": {
    fontSize: "14px",
    border: "none",
    fontFamily: "var(--cygreMedium), sans-serif",
    color: "#808080",
  },
  "& .MuiSelect-icon": {
    color: "#808080",
  },
}));

const CustomCheckbox = styled(Checkbox)({
  color: "#808080",
  "&.Mui-checked": {
    color: "#F4701B",
  },
});

const CustomListItemText = styled(ListItemText)({
  color: "#000",
});

const CustomSelectWithCheckboxes: React.FC<CustomSelectWithCheckboxesProps> = ({
  options,
  title,
  selectedValues,
  onChange,
  placeholder,
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];
    const updatedValues = value.includes("") ? value.filter((val) => val !== "") : value;
    onChange(updatedValues);
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <CustomFormControl fullWidth sx={{ border: "none" }}>
        {title && (
          <Typography
            sx={{
              fontFamily: "var(--cygreMedium), sans-serif",
              color: "#2E263D",
              fontSize: 14,
              paddingBottom: "4px",
            }}
            id="custom-select-label"
          >
            {title}
          </Typography>
        )}
        <CustomSelect
          labelId="custom-select-label"
          id="custom-select-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(", ")}
          displayEmpty={!!placeholder}
        >
          {placeholder && !selectedValues.length && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <CustomCheckbox
                checked={selectedValues.indexOf(option.value) > -1}
                onChange={(e) => {
                  const newValues = [...selectedValues];
                  if (e.target.checked) {
                    newValues.push(option.value);
                  } else {
                    const index = newValues.indexOf(option.value);
                    if (index > -1) {
                      newValues.splice(index, 1);
                    }
                  }
                  onChange(newValues);
                }}
              />
              <ListItemText
                primary={option.label}
                sx={{
                  span: {
                    fontFamily: "var(--cygreMedium), sans-serif",
                    color: "#2E263D",
                    fontSize: 16,
                  },
                }}
              />
            </MenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </Box>
  );
};

export default CustomSelectWithCheckboxes;
