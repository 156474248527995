import { PriceCard } from "components/Price/Price";
import useCountriesForFirstRegion from "mokcup/regionFirst";
import useCountriesForSecondRegion from "mokcup/regionSecond";
import useCountriesForThirdRegion from "mokcup/regionThird";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProfileTariffsPage = () => {
  const { t } = useTranslation();
  const countriesForFirstRegion = useCountriesForFirstRegion();
  const countriesForSecondRegion = useCountriesForSecondRegion();
  const countriesForThirdRegion = useCountriesForThirdRegion();

  const priceData = [
    { region: 1, price: "0,09", countries: countriesForFirstRegion },
    { region: 2, price: "0,07", countries: countriesForSecondRegion },
    { region: 3, price: "0,05", countries: countriesForThirdRegion },
  ];

  return (
    <Box>
      <Typography sx={{ marginBottom: "16px" }} variant="h4">
        {t("tariffs")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        {priceData.map((item, index) => (
          <Box
            key={index}
            sx={{
              minWidth: "250px",
              flexBasis: { xs: "100%", lg: "calc(33.33% - 12px)" },
              maxWidth: { xs: "100%", lg: "calc(33.33% - 12px)" },
              boxSizing: "border-box",
            }}
          >
            <PriceCard
              isLayout={false}
              region={item.region}
              price={item.price}
              countries={item.countries}
              showButton={false}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProfileTariffsPage;
