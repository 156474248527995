import IIcon from "interfaces/icon";
import React from "react";

function Lock({ color, width = 14, height = 17 }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 14 17">
      <path
        fill={color}
        d="M2.256 16.917c-.415 0-.77-.147-1.065-.442A1.452 1.452 0 01.75 15.41V7.59c0-.415.147-.77.441-1.065.295-.294.65-.441 1.065-.441h.994V4.417c0-1.04.365-1.926 1.095-2.655C5.075 1.032 5.959.667 7 .667c1.04 0 1.926.365 2.655 1.095.73.73 1.095 1.614 1.095 2.655v1.667h.993c.416 0 .771.147 1.066.441.294.295.441.65.441 1.065v7.82c0 .416-.147.771-.441 1.066-.295.294-.65.441-1.066.441H2.256zm0-1.25h9.487a.25.25 0 00.185-.072.25.25 0 00.072-.184V7.59a.25.25 0 00-.072-.185.25.25 0 00-.185-.072H2.256a.25.25 0 00-.184.072A.25.25 0 002 7.59v7.82a.25.25 0 00.072.185.25.25 0 00.184.072zM7 12.959c.405 0 .75-.142 1.033-.426.284-.284.425-.628.425-1.033 0-.405-.141-.749-.425-1.033A1.407 1.407 0 007 10.042c-.405 0-.75.142-1.033.425a1.407 1.407 0 00-.425 1.033c0 .405.141.75.425 1.033.284.284.628.426 1.033.426zM4.5 6.084h5V4.417a2.41 2.41 0 00-.73-1.77A2.41 2.41 0 007 1.916a2.41 2.41 0 00-1.77.73 2.41 2.41 0 00-.73 1.77v1.667z"
      ></path>
    </svg>
  );
}

export default Lock;
