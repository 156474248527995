import TuneIcon from "@mui/icons-material/Tune";
import { Box, Button } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IKeyVal } from "components/Table";

export interface IFilterSubItem {
  title: string;
  id: unknown;
  code: unknown;
}

export interface IFilterItem {
  title: string;
  codeTop: string;
  subItems?: IFilterSubItem[];
}

interface IFilterButton {
  menuData: IFilterItem[];
  filter: IFilterState;
  handleApply: (filter: any) => void;
}

export interface IFilterState {
  [key: string]: string[];
}

const FilterButton = ({ menuData, filter, handleApply }: IFilterButton) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const hoverColor = "#F4701B";
  const hoverBackgroundColor = alpha(hoverColor, 0.1);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [tempFilter, setTempFilter] = useState<IFilterState>(filter);

  const handleFilterChange = (param: IKeyVal) => {
    const key = Object.keys(param)?.[0];

    setTempFilter((prev) => {
      const newParam = prev[key];
      const saveParam = newParam.includes(param[key])
        ? newParam?.filter((p) => p !== param[key])
        : [...newParam, param[key]];

      return { ...prev, [key]: saveParam };
    });
  };

  const clearFilter = () => {
    const clearedFilter: any = {};

    Object.keys(tempFilter).forEach((key) => {
      clearedFilter[key] = [];
    });

    setTempFilter(clearedFilter);
    handleApply(clearedFilter);
    setIsOpened(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        variant="contained"
        size="medium"
        sx={{
          position: "relative",
          backgroundColor: "#F6F7FB",
          color: "#6D6777",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontWeight: 400,
          boxShadow: "none",
          textTransform: "none",
          height: 40,
          marginLeft: { xs: 0, sm: "10px" },
          width: { xs: "100%", sm: "140px" },
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: hoverBackgroundColor,
            color: hoverColor,
            "& .MuiSvgIcon-root": {
              color: hoverColor,
            },
          },
        }}
        startIcon={<TuneIcon sx={{ color: "#6D6777" }} />}
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      >
        {t("filter")}
      </Button>
      {isOpened && (
        <div className="menu">
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              height: "48px",
              background: "#F6F7FB",
            }}
          >
            <Button
              color="inherit"
              variant="text"
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                cursor: "pointer",
                color: "#B4B1B9",
                textTransform: "initial",
                "&:hover": {
                  color: "#F4701B",
                },
              }}
              onClick={clearFilter}
            >
              {t("clear")}
            </Button>
            <Button
              color="inherit"
              variant="text"
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                cursor: "pointer",
                textTransform: "initial",
                color: "#B4B1B9",
                "&:hover": {
                  color: "#F4701B",
                },
              }}
              onClick={() => handleApply(tempFilter)}
            >
              {t("apply")}
            </Button>
          </Box>
          {menuData.map(({ title, codeTop, subItems }, index) => (
            <div
              key={codeTop}
              className="menu-item"
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {t(`${title}`)}
              {subItems && subItems.length > 0 && hoveredItem === index && (
                <div className="submenu">
                  {subItems.map((subItem: any) => (
                    <label key={subItem.code} className="submenu-item">
                      <input
                        type="checkbox"
                        onChange={() => handleFilterChange({ [codeTop]: subItem.code })}
                        checked={tempFilter[codeTop]?.includes(subItem.code)}
                      />
                      <span>{t(`${subItem.title}`)}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          ))}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              height: "48px",
              background: "#F6F7FB",
            }}
          >
            <Button
              color="inherit"
              variant="text"
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                cursor: "pointer",
                color: "#B4B1B9",
                textTransform: "initial",
                "&:hover": {
                  color: "#F4701B",
                },
              }}
              onClick={clearFilter}
            >
              {t("clear")}
            </Button>
            <Button
              color="inherit"
              variant="text"
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                cursor: "pointer",
                textTransform: "initial",
                color: "#B4B1B9",
                "&:hover": {
                  color: "#F4701B",
                },
              }}
              onClick={() => handleApply(tempFilter)}
            >
              {t("apply")}
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default FilterButton;
