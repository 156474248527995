import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import { ChangeEvent } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";

export default function MoreInfo() {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const { errors, clearError } = useErrorAppStore();
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
    if (value) {
      clearError(field);
    }
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontFamily: "var(--cygreRegular), sans-serif" }}>{t("additionalinformation")}</Typography>
      </AccordionSummary>
      <Box>
        <AccordionDetails>
          <Box
            sx={{
              maxWidth: "480px",
              paddingTop: "24px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="developerSite"
              height="40px"
              placeholder={t("website")}
              name="developerSite"
              label={t("site")}
              value={appData.developerSite}
              onChange={(e) => handleInputChange(e, "developerSite")}
              customBgColor="#F6F7F9"
              error={!!errors.developerSite}
              helperText={errors.developerSite}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="developerEmail"
              height="40px"
              placeholder={t("enteremail")}
              name="developerEmail"
              label={t("emailCaption")}
              value={appData.developerEmail}
              onChange={(e) => handleInputChange(e, "developerEmail")}
              customBgColor="#F6F7F9"
              error={!!errors.developerEmail}
              helperText={errors.developerEmail}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="adressCompany"
              height="40px"
              placeholder={t("address")}
              name="adressCompany"
              label={t("adresscompany")}
              value={appData.adressCompany}
              onChange={(e) => handleInputChange(e, "adressCompany")}
              customBgColor="#F6F7F9"
              error={!!errors.adressCompany}
              helperText={errors.adressCompany}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="linkPolicy"
              height="40px"
              placeholder={t("policyLink")}
              name="linkPolicy"
              label={t("linkPolicy")}
              value={appData.linkPolicy}
              onChange={(e) => handleInputChange(e, "linkPolicy")}
              customBgColor="#F6F7F9"
              error={!!errors.linkPolicy}
              helperText={errors.linkPolicy}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="dateOfUpdates"
              height="40px"
              placeholder={t("lastUpdateDate")}
              name="dateOfupdates"
              label={t("dateOfupdates")}
              value={appData.dateOfUpdates}
              onChange={(e: any) => handleInputChange(e, "dateOfUpdates")}
              customBgColor="#F6F7F9"
              error={!!errors.dateOfUpdates}
              helperText={errors.dateOfUpdates}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="author"
              height="40px"
              placeholder={t("installCount")}
              name="numberOfInst"
              label={t("numberOfinstallations")}
              value={appData.numberOfInst}
              onChange={(e: any) => handleInputChange(e, "numberOfInst")}
              customBgColor="#F6F7F9"
              error={!!errors.numberOfInst}
              helperText={errors.numberOfInst}
            />
          </Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          ></Box>
        </AccordionDetails>
      </Box>
    </Accordion>
  );
}
