import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import CommentsTable from "./CommentsTable";
import CustomButton from "components/Button";
import GenerateCommentModal from "./GenerateCommentModal";
import { useState } from "react";
import AddCommentModal from "./AddCommentModal";

interface IComments {
  languages: any;
}

export default function Comments({ languages }: IComments) {
  const { t } = useTranslation();
  const [generatecomment, setGeneratecomment] = useState(false);
  const [addComments, setaddComments] = useState(false);

  const handleAddComments = () => {
    setaddComments(true);
  };

  const handleGeneratecomment = () => {
    setGeneratecomment(true);
  };
  return (
    <>
      <Accordion
        sx={{
          boxShadow: "none",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontFamily: "var(--cygreRegular), sans-serif" }}>{t("comments")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <CommentsTable />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              textButton={t("generatecomment")}
              height={40}
              customColor="#FFFFFF"
              customBgColor="#2E263D"
              customBorderRadius="8px"
              customHoverColor="#2E263D"
              customFontSize="14px"
              width={"100%"}
              onClick={handleGeneratecomment}
            />
            <Box
              sx={{
                width: "20px",
              }}
            ></Box>
            <CustomButton
              textButton={t("addmanualcomment")}
              height={40}
              width={"100%"}
              customColor="#6D6777"
              customBorderColor="#C9D0D8"
              customHoverColor="transparent"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleAddComments}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <GenerateCommentModal
        open={generatecomment}
        setOpen={setGeneratecomment}
        // translations={undefined}
        languages={languages}
      />
      <AddCommentModal
        title={t("addingcomment")}
        open={addComments}
        setOpen={setaddComments}
        translations={undefined}
        languages={undefined}
      />
    </>
  );
}
