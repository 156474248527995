import { Box, Typography, List, ListItem, Link } from "@mui/material";
import Lock from "assets/icons/Lock";
import Person from "assets/icons/Person";
import AttachMoney from "assets/icons/AttachMoney";
import Notification from "assets/icons/Notification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

// import NotificationSettings from "./components/NotificationSettings";
// import Faq from "@/assets/icons/Faq";
// import PasswordChange from "./components/PasswordChange";
// import { withAuth } from "HOC/withAuth";

// type Tab = "/profile" | "/profile/notifications" | "/profile/tariffs" | "/profile/changepsw" | "/profile/faq";

const getItemStyles = (isActive: boolean) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  borderRadius: "8px",
  backgroundColor: isActive ? "rgba(244, 112, 27, 0.08)" : "transparent",
  cursor: "pointer",
});

const getIconStyles = (isActive: boolean) => ({
  width: "36px",
  height: "36px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  marginRight: "8px",
  background: isActive ? "#F4701B" : "#F6F7FB",
});

const getIconColor = (isActive: boolean) => (isActive ? "#fff" : "#6D6777");

export const ProfileSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isActiveItem = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Box
      sx={{
        // padding: "0 20px",
        paddingRight: { xs: "0", md: "20px" },
        width: { xs: "100%", md: 300 },
        marginBottom: { xs: "24px", md: 0 },
        border: { xs: "1px solid #A5A3AE4D", md: "none" },
        borderRadius: "8px",
      }}
    >
      <List>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile"))}
          onClick={() => {
            navigate("profile");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile"))}>
            <Person color={getIconColor(isActiveItem("/profile"))} />
          </Box>
          <Typography variant="body2">{t("profile")}</Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/notifications"))}
          onClick={() => {
            navigate("/profile/notifications");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/notifications"))}>
            <Notification color={getIconColor(isActiveItem("/profile/notifications"))} />
          </Box>
          <Typography variant="body2">{t("settingnotification")}</Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/tariffs"))}
          onClick={() => {
            navigate("/profile/tariffs");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/tariffs"))}>
            <AttachMoney color={getIconColor(isActiveItem("/profile/tariffs"))} />
          </Box>
          <Typography variant="body2">{t("tariffs")}</Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/changepsw"))}
          onClick={() => {
            navigate("/profile/changepsw");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/changepsw"))}>
            <Lock color={getIconColor(isActiveItem("/profile/changepsw"))} />
          </Box>
          <Typography variant="body2">{t("changepsw")}</Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/faq"))}
          onClick={() => {
            navigate("/profile/faq");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/faq"))}>
            <Lock color={getIconColor(isActiveItem("/profile/faq"))} />
          </Box>
          <Typography variant="body2">FAQ</Typography>
        </ListItem>
      </List>
    </Box>
  );
};
