import styled from "@emotion/styled";
import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import CustomizedSwitches from "./Switch";

interface Switch {
  description: string;
  label: string;
  isSelected: boolean;
  handleChange?: () => void;
}

const TrackSwitch = ({ description, label, isSelected, handleChange }: Switch) => {
  const TextFieldStyled = styled(TextField)(() => {
    return {
      ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
        border: "none",
      },
    };
  });
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          padding: "8px",
          borderRadius: "8px",
          flexDirection: "column",
          marginLeft: "12px",
          marginBottom: "25px",
        }}
      >
        <p
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#2E263D",
            margin: "0",
            fontFamily: "var(--cygreMedium), sans-serif",
            paddingBottom: "8px",
          }}
        >
          {label}
        </p>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#6D6777",
              margin: "0",
              maxWidth: "566px",
              lineHeight: "20px",
            }}
          >
            {description}
          </p>
          <CustomizedSwitches isSelected={isSelected} handleChange={handleChange} />
        </div>
      </Box>
    </div>
  );
};

export default TrackSwitch;
