import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CustomButton from "components/Button";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

interface IModalCheckDomain {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function AnswerCheckModal({ open, setOpen }: IModalCheckDomain) {
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
    >
      <Box sx={style}>
        <>
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "24px",
              color: "#2E263D",
              marginBottom: "24px",
              fontFamily: "var(--cygreRegular), sans-serif",
              letterSpacing: "0.5px",
            }}
          >
            {t("checkdomain")}
          </Typography>
          <Box
            sx={{
              width: "200px",
              margin: "auto",
              marginTop: 2,
            }}
          >
            <CustomButton
              textButton="OK"
              height={40}
              width="100%"
              customBgColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleClose}
            />
          </Box>
        </>
      </Box>
    </Modal>
  );
}
