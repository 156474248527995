import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const stats = [
  { title: "100K+", text: "landingStatsClients", end: 100, suffix: "K+" },
  { title: "750K+", text: "landingStatsInstalls", end: 750, suffix: "K+" },
  { title: "4.8", text: "landingStatsAverage", end: 4.8, suffix: "" },
];

function useIntersectionObserver(ref: MutableRefObject<Element | null>, options: IntersectionObserverInit): boolean {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.disconnect();
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return inView;
}

function animateValue(element: HTMLSpanElement | null, start: number, end: number, duration: number) {
  if (!element) return;
  let startTimestamp: number | null = null;
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    element.innerText = (progress * (end - start) + start).toFixed(end % 1 !== 0 ? 1 : 0);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

export default function LandingStats() {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const inView = useIntersectionObserver(ref, { threshold: 0.1 });
  const [startCount, setStartCount] = useState(false);
  const refs = useRef<(HTMLSpanElement | null)[]>([]);

  useEffect(() => {
    if (inView) {
      setStartCount(true);
      stats.forEach((item, index) => {
        if (refs.current[index]) {
          animateValue(refs.current[index], 0, item.end, 2500);
        }
      });
    }
  }, [inView]);

  return (
    <Box ref={ref}>
      <Box
        sx={{
          backgroundColor: "#000",
        }}
      >
        <Box
          sx={{
            padding: { xs: "16px 10px", md: "42px 0" },
            display: "flex",
            alignItems: "center",
            maxWidth: "1240px",
            margin: "0 auto",
            justifyContent: "space-around",
          }}
        >
          {stats.map((item, index) => (
            <Box key={index}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "32px",
                    md: "48px",
                    lg: "56px",
                  },
                  lineHeight: "64px",
                  paddingBottom: "16px",
                  textAlign: "center",
                  transition: "all 0.5s ease-out",
                  opacity: startCount ? 1 : 0,
                  color: "#fff",
                }}
              >
                <span ref={(el: any) => (refs.current[index] = el)}>0</span>
                {startCount && item.suffix}
              </Typography>
              <Typography
                sx={{
                  lineHeight: "21px",
                  fontSize: { xs: "14px", md: "16px" },
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {t(item.text)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
