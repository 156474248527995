import React, { useState, useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomButton from "components/Button";

interface INSInputs {
  nsFirst: string;
  nsSecond: string;
  status?: string;
  handleClose: any;
  id?: string;
}

export default function NSInputs({ nsFirst, nsSecond, status, handleClose, id }: INSInputs) {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const FIVE_HOURS_MS = 5 * 60 * 60 * 1000;

  useEffect(() => {
    const lastCheckedTime = localStorage.getItem(`lastCheckedTime_${id}`);
    if (lastCheckedTime) {
      const timePassed = Date.now() - parseInt(lastCheckedTime, 10);
      if (timePassed < FIVE_HOURS_MS) {
        setTimeLeft(FIVE_HOURS_MS - timePassed);
      }
    }
    setIsInitialLoad(false);
  }, [id]);

  useEffect(() => {
    if (timeLeft !== null) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => (prev !== null ? prev - 1000 : null));
      }, 1000);

      if (timeLeft <= 0) {
        clearInterval(timer);
        setTimeLeft(null);
      }

      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    // alert(t("copiedtoclipboard"));
  };

  const handleCheckStatus = () => {
    handleClose();
    localStorage.setItem(`lastCheckedTime_${id}`, Date.now().toString());
    setTimeLeft(FIVE_HOURS_MS);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom: 3,
      }}
    >
      <Typography
        id="modal-check-domain-title"
        sx={{
          textAlign: "left",
          fontSize: "14px",
          lineHeight: "19px",
          fontFamily: "var(--cygreRegular), sans-serif",
          color: "#2E263D",
        }}
      >
        {t("binddomain")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "#F6F7F9",
          padding: "8px",
          borderRadius: "8px",
        }}
      >
        <InputBase
          readOnly
          value={nsFirst}
          sx={{ flex: 1, paddingLeft: "8px", fontFamily: "var(--cygreRegular), sans-serif" }}
        />
        <IconButton onClick={() => handleCopy(nsFirst)}>
          <ContentCopyIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "#F6F7F9",
          padding: "8px",
          borderRadius: "8px",
        }}
      >
        <InputBase
          readOnly
          value={nsSecond}
          sx={{
            flex: 1,
            paddingLeft: "8px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        />
        <IconButton onClick={() => handleCopy(nsSecond)}>
          <ContentCopyIcon />
        </IconButton>
      </Box>

      {status === "pending" && !isInitialLoad && (
        <Box
          sx={{
            margin: "auto",
            marginTop: 3,
            width: 300,
          }}
        >
          {timeLeft === null && (
            <CustomButton
              textButton={t("checkstatusdomain")}
              height={40}
              width={300}
              customBgColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleCheckStatus}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
