import useModalStore from "store/modalStore";
import { useLocation } from "react-router";
import { useEffect } from "react";

const ResetPassword = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forgotPassToken = searchParams.get("forgotPass");
  const { openModal } = useModalStore();
  useEffect(() => {
    if (forgotPassToken?.length) {
      openModal("passwordRecovery");
    }
  }, [forgotPassToken]);

  return null;
};

export default ResetPassword;
