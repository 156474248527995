import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import { useRef, useState } from "react";
import { debounce } from "utils/debounce";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";

export default function SupportPage() {
  const { t, i18n } = useTranslation();
  const [feedBack, setFeedBack] = useState<string>("");
  const textArea = useRef<HTMLTextAreaElement>(null);
  const token = getToken();
  const { triggerFetch, loading } = useFetch({
    url: "support/feedback",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      supportFeedback: feedBack,
      locale: i18n.language,
    },
  });

  const sendFeedBack = debounce(async () => {
    setFeedBack("");
    if (textArea.current) {
      textArea.current.value = "";
    }

    const resp = await triggerFetch();
    if (resp.success) {
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
  }, 500);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h4" sx={{ fontSize: "24px" }}>
            {t("supportPageTitle")}
          </Typography>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "12px",
                color: "#2E263D",
                fontFamily: "var(--cygreRegular), sans-serif",
                marginBottom: "4px",
              }}
            >
              {t("email")}
            </Typography>
            <a href="mailto:ducksay.pwa@gmail.com">ducksay.pwa@gmail.com</a>
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "12px",
                color: "#2E263D",
                fontFamily: "var(--cygreRegular), sans-serif",
                marginBottom: "4px",
              }}
            >
              Telegram
            </Typography>
            <a href="https://t.me/ducksaypwa" target={"_blank"}>
              @ducksaypwa
            </a>
          </Box>
          <Box sx={{ marginBottom: "16px", display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "12px",
                color: "#2E263D",
                fontFamily: "var(--cygreRegular), sans-serif",
                marginBottom: "4px",
              }}
            >
              {t("supportFeedBack")}
            </Typography>
            <textarea
              id="feedback"
              ref={textArea}
              onChange={(e) => setFeedBack(e.target.value)}
              style={{
                width: "50%",
                minHeight: "200px",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "8px",
                // borderColor: error ? "red" : "#ccc",
                resize: "vertical",
              }}
            />
            <CustomButton
              textButton={t("send")}
              height={40}
              loading={loading}
              // width="100%"
              customBgColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              disabled={!feedBack}
              onClick={sendFeedBack}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
