import { useMemo, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import MapCountryTable from "./MapCountryTable";
import { world_map } from "./world-map";
import { getCountryWithCenterData } from "utils/mapsHelper";
import { IPwaStatisticsItem } from "pages/DashboardPage";
import Phone from "assets/icons/Phone";
import { useTranslation } from "react-i18next";

const geoUrl = world_map;

export interface ICountryStatistics {
  amount: number;
  pwaCount: number;
  countries: {
    name: string;
    icon: string;
    installCount: number;
  }[];
  country: string;
  flag: string;
}

interface IStatsMap {
  statistics: IPwaStatisticsItem[];
  groupedStatistics: ICountryStatistics[];
}

export default function StatsMap({ statistics, groupedStatistics }: IStatsMap) {
  const [selectCountry, setSelectCountries] = useState<any>([]);
  const { t } = useTranslation();
  // console.log("groupedStatistics:", groupedStatistics);

  const filteredRows = useMemo(() => statistics.filter((row) => row.country.trim() !== ""), [statistics]);

  useEffect(() => {
    const result = getCountryWithCenterData(filteredRows, world_map);
    setSelectCountries(result);
  }, [filteredRows]);

  const getCountryValue = (countryCode: string) => {
    const countryData = filteredRows.find((country: any) => country.country === countryCode);
    return countryData || null;
  };

  const getGroupedCountryData = (countryCode: string) => {
    const countryData = groupedStatistics.find((group) => group.country === countryCode);
    return countryData || null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: { xs: "column", xl: "row" },
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          width: { xs: "100%", xl: "65%" },
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ComposableMap projectionConfig={{ rotate: [-12, -8, 5], scale: 180 }} height={500}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const countryValue = getCountryValue(geo.properties.name);
                const groupedCountryData = getGroupedCountryData(geo.properties.name);

                return (
                  <Tooltip
                    key={geo.rsmKey}
                    title={
                      groupedCountryData ? (
                        <Box sx={{ width: "220px", color: "white" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingBottom: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img style={{ width: "24px", height: "24px" }} src={groupedCountryData.flag} alt="flag" />
                              <Typography
                                sx={{
                                  paddingLeft: "10px",
                                  fontSize: "14px",
                                  fontFamily: "var(--cygreMedium), sans-serif",
                                }}
                              >
                                {groupedCountryData.country}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Phone color="#fff" />
                              <Typography
                                sx={{
                                  fontFamily: "var(--cygreMedium), sans-serif",
                                  paddingLeft: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                {groupedCountryData.pwaCount} PWA
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "236px",
                              background: "#5c5959",
                              marginLeft: "-8px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "16px" }}>
                              {t("name")}
                            </Typography>
                            <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "16px" }}>
                              {t("install")}
                            </Typography>
                          </Box>
                          <Box sx={{ paddingTop: "10px" }}>
                            {groupedCountryData.countries.map((country) => (
                              <Box
                                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                key={country.name}
                              >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <img
                                    style={{ width: "28px", height: "28px", borderRadius: "50%" }}
                                    src={country.icon}
                                    alt={country.name}
                                  />
                                  <Typography
                                    sx={{
                                      paddingLeft: "10px",
                                      fontFamily: "var(--cygreMedium), sans-serif",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {country.name}
                                  </Typography>
                                </Box>
                                <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "14px" }}>
                                  {country.installCount} installs
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                    placement="top"
                    arrow
                  >
                    <Geography
                      geography={geo}
                      data-country={geo.properties.name}
                      style={{
                        default: { fill: countryValue ? "#8C57FF" : "#E7E7E7", outline: "none" },
                        hover: { fill: countryValue ? "#6B26FF" : "#E7E7E7", outline: "none" },
                        pressed: { fill: "#FF5722", outline: "none" },
                      }}
                    />
                  </Tooltip>
                );
              })
            }
          </Geographies>
          {selectCountry.map((item: any, index: any) => (
            <Marker key={index} coordinates={[item.longitude, item.latitude]}>
              <span data-country={item.country}></span>
              <svg>
                <circle cx="10" cy="10" r="3" fill="#2E263D" opacity="1" />
                <circle cx="10" cy="10" r="6" fill="#2E263D" opacity="0.7" />
                <circle cx="10" cy="10" r="10" fill="#2E263D" opacity="0.4" />
              </svg>
            </Marker>
          ))}
        </ComposableMap>
      </Box>

      <Box sx={{ overflowX: "auto", width: { xs: "100%", xl: "35%" } }}>
        <MapCountryTable rows={statistics} />
      </Box>
    </Box>
  );
}
