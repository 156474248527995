export interface ITextsToTranslate {
  productivity: string;
  reviews: string;
  ratedFor: string;
  age: string;
  installations: string;
  install: string;
  support: string;
  website: string;
  email: string;
  adress: string;
  privacy: string;
  about: string;
  description: string;
  ratings: string;
  language: string;
  openBtn: string;
  installBtn: string;
  dataSafety: string;
  dataSafetyText: string;
  dataShare: string;
  location: string;
  dataEncripted: string;
  dataCollect: string;
  downloads: string;
  peoplefoundthishelpful: string;
  errorInstall: string;
}

export const textsToTranslate: ITextsToTranslate = {
  productivity: "Productivity",
  downloads: "Downloads",
  ratedFor: "Rated for",
  reviews: "Reviews",
  age: "Age",
  installations: "Installations",
  install: "Install",
  support: "App support",
  website: "Website",
  email: "E-mail",
  adress: "Adress",
  privacy: "Privacy policy",
  about: "About app",
  description: "Description",
  ratings: "Ratings and reviews",
  language: "Language",
  openBtn: "Open",
  installBtn: "Install",
  dataSafety: "Data safety",
  dataSafetyText:
    "Security is determined by how the app collects and shares your data. How the app protects your privacy and data may vary depending on how you use the app, your region, and your age. The developer who provides this information may update it.",
  dataShare: "This app may share these data types with third parties",
  dataCollect: "This app may collect these data types",
  location: "Location, Personal info, and App activity",
  dataEncripted: "Data is encrypted in transit",
  peoplefoundthishelpful: "people found this helpful",
  errorInstall: "error on Install",
};
