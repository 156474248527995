import React, { useState, useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterButton, { IFilterItem } from "./FilterButton";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";

import useMenuData from "mokcup/menuData";
import { IKeyVal } from "components/Table";

export interface IFilterState {
  status: string[];
  [key: string]: string[];
}

interface IFilter {
  filter: IFilterState;
  setFilter: React.Dispatch<React.SetStateAction<IFilterState>>;
}

const Filter = ({ filter, setFilter }: IFilter) => {
  const [pwasFilter, setPwasFilter] = useState<boolean>(false);
  const [pwasFilterItems, setPwasFilterItems] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const menuData = useMenuData();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setPwasFilter(false);
    setPwasFilterItems("");
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleApply = (filter: IFilterState) => {
    setFilter(filter);
    closeMenu();
  };

  const handleCancel = () => {
    // setTempFilter(filter);
    closeMenu();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // console.log(filter);

  return (
    <div ref={dropdownRef}>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={pwasFilter}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>
          <MenuItem onClick={() => setPwasFilterItems("country")}>menu1</MenuItem>
          <Menu open={pwasFilterItems === "country"} anchorEl={anchorEl} onClose={closeMenu}>
            {/*<MenuItem onClick={() => handleFilterChange({ country: "Cuba" })}>submenu1-1</MenuItem>*/}
          </Menu>
        </div>
        <div>
          <MenuItem onClick={() => setPwasFilterItems("status")}>menu2</MenuItem>
          <Menu open={pwasFilterItems === "status"} anchorEl={anchorEl} onClose={closeMenu}>
            {/*<MenuItem onClick={() => handleFilterChange({ status: "active" })}>submenu2-1</MenuItem>*/}
          </Menu>
        </div>
        <div>
          <MenuItem onClick={() => setPwasFilterItems("vertical")}>menu3</MenuItem>
          <Menu open={pwasFilterItems === "vertical"} anchorEl={anchorEl} onClose={closeMenu}>
            {/*<MenuItem onClick={() => handleFilterChange({ vertical: "nutra" })}>submenu3-1</MenuItem>*/}
          </Menu>
        </div>
        <Button variant="contained" onClick={handleCancel}>
          {t("cancel")}
        </Button>
      </Menu>
      <Box sx={{ position: "relative" }}>
        <FilterButton menuData={menuData} filter={filter} handleApply={handleApply} />
      </Box>
    </div>
  );
};

export default Filter;
