import { useState, useCallback } from "react";

interface UseFetchProps {
  url: string;
  method?: string;
  headers?: HeadersInit;
  body?: any;
  context?: {
    no401Redirect?: boolean;
  };
}

function useFetch({ url, method = "GET", headers, body, context }: UseFetchProps) {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // const controller = new AbortController();

  const triggerFetch = useCallback(
    async (overrideBody?: any, customUrl?: string, customOptions?: any) => {
      setLoading(true);
      setError(null);
      // const signal = controller.signal;

      const options: RequestInit = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        ...customOptions,
      };

      const finalBody = overrideBody && Object.keys(overrideBody).length ? { ...body, ...overrideBody } : body;

      if (finalBody) {
        options.body = JSON.stringify(finalBody);
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${customUrl ? customUrl : url}`, options);

        if (response.status === 401 && context?.no401Redirect) {
          return await response.json();
        }

        if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.assign("/");
          return null;
        }
        const result = await response.json();

        if (!response.ok) {
          setData(result);
          throw new Error(result.message);
        }

        setData(result);
        return result;
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        }
        return err;
      } finally {
        setLoading(false);
      }
    },
    [url, method, headers, body]
  );

  return { data, error, loading, triggerFetch, setError };
}

export default useFetch;
