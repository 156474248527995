import IIcon from "interfaces/icon";
import React from "react";

function AttachMoney({ color }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" fill="none" viewBox="0 0 8 16">
      <path
        fill={color}
        d="M4.005 15.291a.604.604 0 01-.446-.18.605.605 0 01-.18-.445v-1.118a4 4 0 01-1.673-.681C1.23 12.524.85 12.049.564 11.44a.545.545 0 01-.003-.466.636.636 0 01.362-.353c.146-.061.3-.06.46.007.16.066.28.177.359.332.241.459.552.81.932 1.052s.863.363 1.45.363c.6 0 1.131-.143 1.591-.43.46-.286.69-.74.69-1.362 0-.54-.173-.97-.518-1.292-.346-.323-1.06-.659-2.145-1.01-1.136-.358-1.925-.778-2.366-1.259-.442-.48-.663-1.085-.663-1.814 0-.839.294-1.499.883-1.98.589-.481 1.183-.749 1.784-.802V1.333c0-.177.06-.326.18-.445.119-.12.267-.18.445-.18.177 0 .325.06.445.18s.18.268.18.445v1.093c.511.067.96.216 1.346.446.387.23.71.533.97.908a.498.498 0 01.062.452.575.575 0 01-.337.359.6.6 0 01-.456.022 1.026 1.026 0 01-.435-.298 2.15 2.15 0 00-.707-.536c-.275-.13-.618-.196-1.027-.196-.627 0-1.13.153-1.512.458-.38.306-.571.695-.571 1.167 0 .485.195.873.585 1.164.39.29 1.115.595 2.175.913.984.3 1.72.725 2.205 1.279.485.553.727 1.218.727 1.993 0 .912-.293 1.619-.879 2.122-.586.503-1.301.801-2.146.894v1.093c0 .178-.06.326-.18.446a.604.604 0 01-.445.18z"
      ></path>
    </svg>
  );
}

export default AttachMoney;
