import { useState } from "react";

interface UseFetchFormDataProps {
  url: string;
  method?: string;
  headers?: HeadersInit;
}

function useFetchFormData({ url, method = "POST", headers }: UseFetchFormDataProps) {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const triggerFetch = async (formData: FormData) => {
    setLoading(true);
    setError(null);

    const options: RequestInit = {
      method,
      headers: {
        ...headers,
      },
      body: formData,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);

      if (response.status === 401) {
        window.location.assign("/");
        return null;
      }

      const result = await response.json();
      setData(result);
      return result;
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message);
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, triggerFetch };
}

export default useFetchFormData;
