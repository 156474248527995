import React from "react";

function Ring() {
  return (
    <div
      style={{
        background: "#8C57FF29",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" fill="none" viewBox="0 0 24 30">
        <path
          fill="#8C57FF"
          d="M1.875 25.327c-.319 0-.586-.108-.801-.324a1.09 1.09 0 01-.324-.802c0-.318.108-.585.324-.801a1.09 1.09 0 01.801-.323h1.587V11.884c0-2.017.622-3.8 1.868-5.35 1.244-1.55 2.843-2.542 4.795-2.974v-.935c0-.52.182-.964.546-1.328A1.806 1.806 0 0111.998.75c.52 0 .963.182 1.329.547.365.365.548.807.548 1.328v.934c1.952.433 3.55 1.425 4.796 2.975 1.245 1.55 1.867 3.333 1.867 5.35v11.193h1.587c.319 0 .586.108.801.323.216.216.324.484.324.803 0 .318-.108.585-.324.8a1.09 1.09 0 01-.801.324H1.875zm10.122 4.211a2.606 2.606 0 01-1.914-.796 2.614 2.614 0 01-.795-1.915h5.424c0 .748-.266 1.387-.798 1.917-.531.53-1.17.794-1.917.794zm-6.285-6.46h12.576V11.883c0-1.736-.614-3.218-1.841-4.446C15.219 6.21 13.737 5.596 12 5.596c-1.736 0-3.219.614-4.447 1.842-1.228 1.228-1.841 2.71-1.841 4.446v11.193z"
        ></path>
      </svg>
    </div>
  );
}

export default Ring;
