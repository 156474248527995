import IIcon from "interfaces/icon";
import React from "react";

function Creatives({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <mask
        id="mask0_159_7900"
        style={{ maskType: "alpha" }}
        width="20"
        height="21"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0.67H20V20.67H0z"></path>
      </mask>
      <g mask="url(#mask0_159_7900)">
        <path
          fill={color}
          d="M4.503 17.753a.73.73 0 01-.538-.215A.73.73 0 013.75 17v-1.436c0-.2.039-.394.117-.58.078-.185.185-.346.322-.483L14.745 3.958c.13-.129.271-.223.426-.283a1.293 1.293 0 01.94 0c.154.06.288.154.403.283l1.03 1.03c.13.116.224.25.284.404a1.29 1.29 0 010 .94c-.06.155-.154.297-.283.426L7 17.314a1.494 1.494 0 01-.483.322 1.476 1.476 0 01-.579.117H4.503zM5 16.503h1.03l8.492-8.48-.51-.531-.533-.511-8.48 8.492v1.03zm9.011-9.011l-.532-.511 1.043 1.043-.51-.532zM11.78 17.753a5.36 5.36 0 002.71-.715c.832-.476 1.248-1.14 1.248-1.993 0-.434-.122-.82-.365-1.156-.244-.338-.581-.637-1.011-.899a.71.71 0 00-.47-.12.574.574 0 00-.405.218.619.619 0 00-.134.462.526.526 0 00.244.413c.275.163.492.333.652.509.16.176.24.367.24.573 0 .394-.272.736-.813 1.025-.542.289-1.174.433-1.896.433a.605.605 0 00-.446.18.605.605 0 00-.18.445c0 .177.06.326.18.445.12.12.268.18.446.18zM7.917 5.878c0 .243-.138.462-.413.66-.275.197-.858.497-1.75.9-1.073.47-1.815.89-2.224 1.262-.409.372-.613.82-.613 1.345 0 .345.088.647.266.906.177.259.398.482.661.67a.57.57 0 00.452.129.626.626 0 00.41-.242.603.603 0 00-.1-.87 1.31 1.31 0 01-.328-.293.497.497 0 01-.111-.3c0-.21.14-.416.419-.62.279-.204.835-.485 1.667-.843 1.158-.496 1.933-.945 2.325-1.346.392-.4.588-.854.588-1.358 0-.684-.288-1.237-.864-1.658-.576-.423-1.33-.634-2.26-.634a3.95 3.95 0 00-1.577.31c-.48.206-.839.458-1.076.756a.589.589 0 00.093.865c.138.11.289.156.452.136a.692.692 0 00.418-.203c.21-.21.453-.365.726-.465.274-.099.595-.149.963-.149.613 0 1.078.104 1.397.31.32.207.479.451.479.732z"
        ></path>
      </g>
    </svg>
  );
}

export default Creatives;
