"use client";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "components/Button";
// import { useRouter } from "next/navigation";
import HeroDuck from "components/HeroDuck";
import useModalStore from "store/modalStore";
import HeaderSection from "components/HeaderSection";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// import { getToken } from "app/[locale]/(DashboardLayout)/pwa/components/Pwas";

export default function Hero() {
  const { t } = useTranslation();
  const router = useNavigate();
  const [session, setSession] = useState("");
  // useEffect(() => {
  //   const token = getToken();
  //
  //   setSession(token!);
  // }, []);
  const { openModal } = useModalStore();

  const handleClickBtn = () => {
    if (session) {
      // router.push("/dashboard");
    } else {
      openModal("signup");
    }
  };

  return (
    <>
      <Box
        id="create-pwa"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            lg: "row",
            md: "row",
            xl: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          position: "relative",
          padding: "150px 0",
        }}
      >
        <Box>
          <Box
            sx={{
              paddingBottom: { xs: "50px", md: "140px" },
              marginTop: { xs: "-60px", lg: "-160px", xl: "-140px" },
            }}
          >
            <Box
              sx={{
                maxWidth: { lg: "330px", xs: "240px" },
                position: "relative",
                border: "4px solid #000",
                borderRadius: "26px",
                padding: {
                  lg: "28px 20px 22px",
                  xs: "17px 20px 22px",
                },
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#fff",

                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: "19%",
                  borderStyle: "solid",
                  borderWidth: "0 0 18px 28px",
                  borderColor: "transparent transparent #000000",
                  transform: "translate(-50%, -100%) skew(58deg)",
                  transformOrigin: "bottom",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: "18%",
                  borderStyle: "solid",
                  borderWidth: "0 0 13.3px 19.3px",
                  borderColor: "transparent transparent #fff",
                  transform: "translate(calc(-50% + 0.8px), -100%) skew(58deg)",
                  transformOrigin: "bottom",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--overdoze), sans-serif",
                  fontSize: { lg: "36px", md: "24px", xs: "24px" },
                  fontWeight: 700,
                  lineHeight: { lg: "30px", md: "20px", xs: "20px" },
                  textAlign: "center",
                  color: "#000",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                }}
              >
                {t("isthisanapplication")}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              maxWidth: "500px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HeaderSection title={t("createyourPWAapplicationeasilyandquickly")} />
            <Box
              sx={{
                alignSelf: "flex-start",
                marginTop: { xl: "72px", lg: "64px", md: "42px", xs: "42px" },
                width: { xl: "75%", sm: "auto", xs: "100%" },
                minWidth: "330px",
              }}
            >
              <CustomButton
                width="100%"
                textButton={t("createaPWAapplication")}
                customBgColor="#000"
                customFontSize="20px"
                onClick={handleClickBtn}
                customHoverColor="#F4701B"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: { xs: "150px", md: "0px" },
          }}
        >
          <HeroDuck />
        </Box>
      </Box>
    </>
  );
}
