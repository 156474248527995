import React from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

interface IPhoneMockup {
  children: React.ReactNode;
}

const PhoneMockup: React.FC<IPhoneMockup> = ({ children }) => {
  return (
    // @ts-ignore
    <DeviceFrameset device="Samsung Galaxy S5" color="black">
      {children}
    </DeviceFrameset>
  );
};

export default PhoneMockup;
