import React from "react";

export default function Zipper() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="67"
      fill="none"
      viewBox="0 0 56 67"
    >
      <g filter="url(#filter0_d_355_3761)">
        <path
          fill="url(#paint0_linear_355_3761)"
          fillRule="evenodd"
          d="M30.749 2.438l.214-.01.293.019.13.02.202.05.09.027.205.08.085.041.176.1.128.086.192.155.142.142c.052.055.099.112.143.17l.12.18.103.189.061.138.067.193.049.2.012.076.005.043.013.09.01.212v18.428h15.578c1.748 0 2.787 1.896 1.913 3.341l-.114.171L26.83 59.013c-1.226 1.674-3.843.897-4.017-1.091l-.009-.21v-18.43l-15.578.002c-1.748 0-2.787-1.896-1.913-3.34l.114-.172L29.19 3.3l.116-.139c.1-.111.21-.21.327-.297l.18-.12.19-.102.199-.082.136-.045.201-.048.209-.03z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_355_3761"
          width="54.5"
          height="66"
          x="0.746"
          y="0.678"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.5"></feOffset>
          <feGaussianBlur stdDeviation="2.125"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.110987 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_355_3761"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_355_3761"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_355_3761"
          x1="4.996"
          x2="4.996"
          y1="2.428"
          y2="59.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
