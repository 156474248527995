import { useTranslation } from "react-i18next";
import { countries } from "mokcup/countries";

export default function useMenuData() {
  const { t } = useTranslation();

  const menuData = [
    {
      title: t("status"),
      codeTop: "status",
      subItems: [
        { title: "Active", id: "1.1", code: "active" },
        { title: "Not active", id: "1.2", code: "notactive" },
        { title: "Pause", id: "1.2", code: "pause" },
      ],
    },
    {
      title: t("country"),
      codeTop: "country",
      subItems: countries.map((country, index) => ({
        title: t(`${country}`),
        id: `2.${index + 1}`,
        code: t(`${country}`),
      })),
    },
    {
      title: t("vertical"),
      codeTop: "categoryVertical",
      subItems: [
        { title: t("nutra"), id: "3.1", code: t("nutra") },
        { title: t("betting"), id: "3.2", code: t("betting") },
        { title: t("dating"), id: "3.3", code: t("dating") },
        { title: t("gambling"), id: "3.4", code: t("gambling") },
        { title: t("trading"), id: "3.4", code: "trading" },
      ],
    },
  ];

  return menuData;
}
