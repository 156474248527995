import { useTranslation } from "react-i18next";

export default function useWhites() {
  const { t } = useTranslation();

  const whites = [
    { value: t("nutra"), label: t("nutra") },
    { value: t("betting"), label: t("betting") },
    { value: t("dating"), label: t("dating") },
    { value: t("gambling"), label: t("gambling") },
    { value: t("trading"), label: t("trading") },
  ];

  return whites;
}
