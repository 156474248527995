import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="35"
      fill="none"
      viewBox="0 0 33 35"
    >
      <g filter="url(#filter0_f_159_8929)" opacity="0.2">
        <ellipse
          cx="15.561"
          cy="29.722"
          fill="#FFC200"
          rx="14.021"
          ry="3.311"
        ></ellipse>
      </g>
      <g filter="url(#filter1_iiii_159_8929)">
        <path
          fill="#FF6C2B"
          d="M4.277 14.809c.912.125 2.453.121 3.345.121l-.456-2.126H5.16c-1.278 0-1.916.516-1.916 1.184 0 .335.37.73 1.034.82z"
        ></path>
      </g>
      <g filter="url(#filter2_f_159_8929)">
        <path
          fill="#FF3901"
          d="M6.984 14.262c-.073-.462-1.369-1.154-2.16-1.245l.396-.304.638-.395 1.43.395c.03.577-.231 2.01-.304 1.549z"
        ></path>
      </g>
      <path
        stroke="url(#paint0_linear_159_8929)"
        strokeWidth="0.121"
        d="M7.622 14.93c-.892 0-2.433.004-3.345-.121"
      ></path>
      <g filter="url(#filter3_f_159_8929)">
        <path
          fill="url(#paint1_linear_159_8929)"
          d="M27.645 29.726c0 1.842-5.284 3.335-11.802 3.335S4.04 31.44 4.04 29.6c0-1.842 5.284-3.21 11.802-3.21s11.802 1.494 11.802 3.336z"
        ></path>
      </g>
      <g filter="url(#filter4_f_159_8929)" opacity="0.8">
        <path
          fill="url(#paint2_linear_159_8929)"
          d="M27.58 29.437c0 1.966-5.298 3.56-11.833 3.56-7.082 0-11.834-1.783-11.834-3.749 0-1.966 5.298-3.37 11.833-3.37 6.536 0 11.835 1.593 11.835 3.559z"
        ></path>
        <path
          stroke="#EF4900"
          strokeWidth="0.243"
          d="M27.46 29.437c0 .433-.293.87-.88 1.287-.584.415-1.436.794-2.5 1.114-2.128.64-5.074 1.038-8.333 1.038-3.533 0-6.477-.445-8.535-1.131-1.029-.344-1.83-.745-2.37-1.177-.543-.432-.808-.881-.808-1.32 0-.432.29-.856.877-1.256.583-.398 1.436-.753 2.5-1.05 2.128-.593 5.075-.944 8.335-.944 3.26 0 6.206.398 8.333 1.038 1.065.32 1.917.7 2.501 1.114.587.418.88.854.88 1.287z"
        ></path>
      </g>
      <g filter="url(#filter5_f_159_8929)">
        <path
          fill="url(#paint3_linear_159_8929)"
          d="M4.615 8.552c.392.06 2.065.03 2.795 0-.183.729-.274 2.885.095 4.07.28.9-.122.698-.943.698C.57 11.528 1.817 7.944 2.76 7.944c.821 0 .95.467 1.855.608z"
        ></path>
      </g>
      <g filter="url(#filter6_iiii_159_8929)">
        <path
          fill="url(#paint4_linear_159_8929)"
          d="M4.615 8.552c.483.09 2.065.03 2.795 0-.183.729-.274 2.885.095 4.07.28.9-.122.698-.943.698C.57 11.528 1.817 7.944 2.76 7.944c.821 0 .955.438 1.855.608z"
        ></path>
      </g>
      <path
        stroke="url(#paint5_linear_159_8929)"
        strokeWidth="0.061"
        d="M4.615 8.552c.483.09 2.065.03 2.795 0-.183.729-.274 2.885.095 4.07.28.9-.122.698-.943.698C.57 11.528 1.817 7.944 2.76 7.944c.821 0 .955.438 1.855.608z"
      ></path>
      <g filter="url(#filter7_f_159_8929)" opacity="0.4">
        <path
          fill="#FFC700"
          d="M2.122 25.227c0-6.537 5.089-8.15 7.634-8.14l14.233-.456c1.947 0 2.768-2.825 4.441-2.825s3.29 3.3 2.829 8.99c-.674 8.293-8.216 9.781-16.424 9.781-6.336 0-12.713-.814-12.713-7.35z"
        ></path>
        <path
          stroke="url(#paint6_linear_159_8929)"
          strokeWidth="0.03"
          d="M2.122 25.227c0-6.537 5.089-8.15 7.634-8.14l14.233-.456c1.947 0 2.768-2.825 4.441-2.825s3.29 3.3 2.829 8.99c-.674 8.293-8.216 9.781-16.424 9.781-6.336 0-12.713-.814-12.713-7.35z"
        ></path>
      </g>
      <g filter="url(#filter8_iiiiiiii_159_8929)">
        <path
          fill="#FFC700"
          d="M2.122 25.227c0-6.537 4.68-8.14 7.634-8.14l14.233-.456c1.947 0 2.768-2.825 4.441-2.825s3.29 3.3 2.829 8.99c-.674 8.293-8.216 9.781-16.424 9.781-7.09 0-12.713-1.124-12.713-7.35z"
        ></path>
      </g>
      <path
        stroke="url(#paint7_linear_159_8929)"
        strokeOpacity="0.3"
        strokeWidth="0.03"
        d="M2.122 25.227c0-6.537 4.68-8.14 7.634-8.14l14.233-.456c1.947 0 2.768-2.825 4.441-2.825s3.29 3.3 2.829 8.99c-.674 8.293-8.216 9.781-16.424 9.781-7.09 0-12.713-1.124-12.713-7.35z"
      ></path>
      <g filter="url(#filter9_f_159_8929)" opacity="0.4">
        <path
          fill="#FE9101"
          d="M24.263 5.727a3.66 3.66 0 01-.2-.26 7.866 7.866 0 00-2.268-1.836c-1.205-.657-2.108-.84-2.519-.891a2.07 2.07 0 01-.244-.02s.085 0 .244.02c.587.015 1.455-.161 1.455-.688 0-.547.065-1.033.49-1.033 1.156 0 1.582 1.367 1.947 2.612.228.778.619 1.445.895 1.836.134.162.2.26.2.26z"
        ></path>
      </g>
      <g filter="url(#filter10_iii_159_8929)">
        <path
          fill="#FFF200"
          d="M24.263 5.727a2.688 2.688 0 01-.077-.095c-.245-.29-1.07-1.187-2.451-1.94-1.35-.736-2.282-.917-2.59-.96.57.045 1.529-.142 1.586-.71.057-.575.065-1.003.49-1.003 1.156 0 1.582 1.367 1.947 2.612a6.406 6.406 0 001.018 2.001c.051.06.077.095.077.095z"
        ></path>
      </g>
      <mask
        id="mask0_159_8929"
        style={{ maskType: "alpha" }}
        width="30"
        height="20"
        x="2"
        y="13"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#FFC700"
          d="M2.148 25.196c-.237-6.532 4.68-8.14 7.634-8.14l13.99-.729c1.947 0 3.012-2.551 4.684-2.551 1.673 0 3.29 3.299 2.829 8.99-.673 8.292-8.216 9.78-16.424 9.78-7.09 0-12.488-1.12-12.713-7.35z"
        ></path>
        <path
          stroke="url(#paint8_linear_159_8929)"
          strokeOpacity="0.3"
          strokeWidth="0.121"
          d="M2.148 25.196c-.237-6.532 4.68-8.14 7.634-8.14l13.99-.729c1.947 0 3.012-2.551 4.684-2.551 1.673 0 3.29 3.299 2.829 8.99-.673 8.292-8.216 9.78-16.424 9.78-7.09 0-12.488-1.12-12.713-7.35z"
        ></path>
        <path
          stroke="url(#paint9_linear_159_8929)"
          strokeWidth="0.121"
          d="M2.148 25.196c-.237-6.532 4.68-8.14 7.634-8.14l13.99-.729c1.947 0 3.012-2.551 4.684-2.551 1.673 0 3.29 3.299 2.829 8.99-.673 8.292-8.216 9.78-16.424 9.78-7.09 0-12.488-1.12-12.713-7.35z"
        ></path>
      </mask>
      <g mask="url(#mask0_159_8929)">
        <g filter="url(#filter11_dif_159_8929)">
          <path
            fill="url(#paint10_linear_159_8929)"
            d="M23.485 18.527c4.754-.542 5.82-3.877 4.988-4.582-.833-.705-1.728.636-3.8 1.83-2.072 1.193-8.38 1.288-12.342 1.098-3.962-.19-6.715.868-7.142 1.465-.426.596 2.403 0 6.684.486 4.153.471 5.669.381 11.612-.297z"
          ></path>
        </g>
        <g filter="url(#filter12_f_159_8929)">
          <path
            fill="#FB8A00"
            d="M25.72 30.821c0 .874-4.006 2.176-8.947 2.176-4.942 0-8.948-.942-8.948-1.816 0-.874 4.006.36 8.948.36 4.941 0 8.947-1.593 8.947-.72z"
          ></path>
        </g>
      </g>
      <g filter="url(#filter13_iiif_159_8929)">
        <path
          fill="#FF8100"
          d="M25.723 10.262c-.07 2.865-.521 4.85-2.011 5.883 0 3.315-12.635 3.484-13.917.758-.625-.389-1.186-.974-1.572-1.821-.335-.737.733-1.913.564-2.743-.638-.516-1.469-2.08-1.469-3.17 0-4.367 3.77-6.753 8.977-6.753 1.881 0 3.007 1.435 4.588 2.582 2.002 1.451 4.84 2.883 4.84 5.264z"
        ></path>
      </g>
      <mask
        id="mask1_159_8929"
        style={{ maskType: "alpha" }}
        width="8"
        height="7"
        x="1"
        y="7"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="url(#paint11_linear_159_8929)"
          stroke="url(#paint12_linear_159_8929)"
          strokeWidth="0.061"
          d="M4.554 8.612c.483.091 2.065.03 2.794 0-.182.73.726 2.886 1.095 4.07.28.9-1.121.699-1.942.699-5.992-1.792-4.745-5.376-3.802-5.376.821 0 .955.437 1.855.607z"
        ></path>
      </mask>
      <g filter="url(#filter14_ddf_159_8929)" mask="url(#mask1_159_8929)">
        <path
          fill="url(#paint13_linear_159_8929)"
          d="M6.643 12.166c-3.978-.82-4.374-2.703-3.978-4.197l4.71.398c0 .182-.039.92.21 1.892.324 1.265 3.481 2.819-.942 1.907z"
        ></path>
      </g>
      <g filter="url(#filter15_iiii_159_8929)">
        <path
          fill="#FFB600"
          d="M25.75 10.262c0 2.268-.269 4.479-1.978 5.853-2.494 2.004-10.797 2.52-13.768.91-.597-.371-1.34-.986-1.74-1.73-.278-.518.574-2.19.392-2.795-.392-.79-1.307-1.985-1.307-3.748 0-3.88 3.765-6.397 8.972-6.397 5.889 0 9.428 3.54 9.428 7.907z"
        ></path>
      </g>
      <g filter="url(#filter16_diiif_159_8929)">
        <path
          fill="url(#paint14_linear_159_8929)"
          d="M8.144 25.47c0-2.892 2.372-4 3.558-4.192 1.703-.276 3.041.334 5.2.334 2.07 0 3.346-.364 5.05-.243 1.243.089 1.915 1.482 1.364 2.582-.304.607-1.364 1.66-3.01 2.126.76 0 2.618-.95 2.801-.79.21.183 0 1.52-.855 2.345-1.786 2.1-5.887 2.515-7.6 2.515-2.22 0-6.508-1.063-6.508-4.677z"
        ></path>
        <path
          stroke="#ED9600"
          strokeWidth="0.061"
          d="M8.144 25.47c0-2.892 2.372-4 3.558-4.192 1.703-.276 3.041.334 5.2.334 2.07 0 3.346-.364 5.05-.243 1.243.089 1.915 1.482 1.364 2.582-.304.607-1.364 1.66-3.01 2.126.76 0 2.618-.95 2.801-.79.21.183 0 1.52-.855 2.345-1.786 2.1-5.887 2.515-7.6 2.515-2.22 0-6.508-1.063-6.508-4.677z"
        ></path>
      </g>
      <g filter="url(#filter17_f_159_8929)" opacity="0.5">
        <path
          stroke="url(#paint15_linear_159_8929)"
          strokeWidth="0.182"
          d="M20.366 25.895c1.064-.122 2.676-1.124 3.01-2.126"
        ></path>
      </g>
      <g filter="url(#filter18_ddiif_159_8929)">
        <path
          fill="url(#paint16_linear_159_8929)"
          d="M10.698 7.64c.33-.82.234-1.28-.06-1.397-.613-.243-1.222.127-1.734.79-.704.911-.851 2.215-.304 2.46.882.395 1.89-1.333 2.098-1.853z"
        ></path>
        <path
          stroke="url(#paint17_linear_159_8929)"
          strokeWidth="0.061"
          d="M10.698 7.64c.33-.82.234-1.28-.06-1.397-.613-.243-1.222.127-1.734.79-.704.911-.851 2.215-.304 2.46.882.395 1.89-1.333 2.098-1.853z"
        ></path>
      </g>
      <g filter="url(#filter19_ddif_159_8929)" opacity="0.95">
        <ellipse
          cx="0.495"
          cy="0.707"
          fill="#fff"
          rx="0.495"
          ry="0.707"
          transform="rotate(34.282 -5.278 18.85) skewX(-.072)"
        ></ellipse>
        <path
          stroke="#1C1C1C"
          strokeWidth="0.061"
          d="M10.094 7.372a.85.85 0 01-.387.32c-.14.052-.276.046-.378-.024-.103-.07-.158-.194-.16-.343a.847.847 0 01.157-.477.85.85 0 01.388-.32c.14-.052.276-.046.378.024.103.07.157.194.16.343.001.149-.05.32-.158.477z"
        ></path>
      </g>
      <g filter="url(#filter20_if_159_8929)" opacity="0.9">
        <ellipse
          cx="0.27"
          cy="0.385"
          fill="#fff"
          rx="0.27"
          ry="0.385"
          transform="rotate(29.009 -10.621 20.814) skewX(-.065)"
        ></ellipse>
        <path
          stroke="#1C1C1C"
          strokeWidth="0.061"
          d="M9.02 8.345a.449.449 0 01-.189.187c-.07.035-.14.037-.193.007-.054-.03-.089-.09-.097-.167a.448.448 0 01.06-.259.449.449 0 01.188-.187c.07-.034.14-.037.193-.007.055.03.089.09.097.168a.448.448 0 01-.06.258z"
        ></path>
      </g>
      <g filter="url(#filter21_f_159_8929)">
        <path
          fill="#FF5A11"
          d="M9.078 15.347c.427.152 1.28.152 1.28-1.066.04-1.733-.928-2.867-1.706-3.157-.13-.048-.285-.104-.37-.073-.256.122.193.256-.285.305-.597.06-1.105.883-1.105 2.163 0 1.077.764 1.462 1.105 1.615.273.122.835.213 1.081.213z"
        ></path>
        <path
          stroke="url(#paint18_linear_159_8929)"
          strokeWidth="0.182"
          d="M8.251 10.965h0l-.008.004c-.078.037-.134.09-.137.17a.206.206 0 00.014.08.567.567 0 00.009.022.915.915 0 01-.14.024c-.34.035-.639.284-.848.672-.21.39-.34.93-.34 1.582 0 .563.2.95.45 1.216.246.264.536.404.71.482.146.065.363.12.57.159.2.037.4.06.532.062.226.077.565.117.856-.029.307-.153.53-.496.53-1.128.04-1.765-.946-2.937-1.766-3.242a2.003 2.003 0 00-.211-.07.397.397 0 00-.22-.004z"
        ></path>
      </g>
      <g filter="url(#filter22_ddiif_159_8929)">
        <path
          fill="#FF5A11"
          d="M9.079 15.347c.426.152 1.28.152 1.28-1.066.039-1.733-.928-2.867-1.707-3.157-.13-.048-.285-.103-.37-.073-.256.122.194.256-.284.305-.598.06-1.166.883-1.166 2.163 0 1.28.824 1.523 1.166 1.676.273.122.834.152 1.08.152z"
        ></path>
        <path
          stroke="url(#paint19_linear_159_8929)"
          strokeWidth="0.121"
          d="M8.262 10.994h0l-.006.002c-.073.035-.117.082-.12.145a.175.175 0 00.013.068.567.567 0 00.01.025h0l.007.018a.11.11 0 01.002.006.184.184 0 01-.046.017.948.948 0 01-.13.02c-.325.034-.631.272-.854.655-.223.384-.367.92-.367 1.57 0 .654.212 1.05.474 1.303.22.213.474.32.646.392l.082.035c.146.065.362.104.568.127.202.022.401.03.527.03.222.078.555.117.838-.025.293-.146.513-.477.513-1.1.04-1.755-.94-2.915-1.746-3.214a1.987 1.987 0 00-.208-.069.369.369 0 00-.203-.005z"
        ></path>
      </g>
      <g filter="url(#filter23_iif_159_8929)">
        <path
          fill="url(#paint20_radial_159_8929)"
          d="M9.812 13.563a1.307 1.307 0 01-2.616 0c0-1.549.586-2.004 1.308-2.004.722 0 1.308 1.283 1.308 2.004z"
        ></path>
      </g>
      <g filter="url(#filter24_f_159_8929)">
        <path
          fill="url(#paint21_linear_159_8929)"
          d="M18.583 15.486c.758-.425 1.64-1.005 1.994-1.365.088.198.84-.13.797.303-.053.541-3.671 1.555-2.791 1.062z"
        ></path>
      </g>
      <g filter="url(#filter25_f_159_8929)" opacity="0.4">
        <path
          fill="url(#paint22_radial_159_8929)"
          d="M22.232 2.108c.233.46.53 1.325.233 1.439-.299.114-1.095-.462-1.312-1.026-.216-.564-.15-1.114.148-1.228.298-.114.616.189.931.815z"
        ></path>
      </g>
      <g filter="url(#filter26_f_159_8929)">
        <ellipse
          cx="17.081"
          cy="10.101"
          fill="url(#paint23_radial_159_8929)"
          rx="5.323"
          ry="3.736"
        ></ellipse>
      </g>
      <defs>
        <filter
          id="filter0_f_159_8929"
          width="30.469"
          height="9.048"
          x="0.326"
          y="25.198"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.607"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_iiii_159_8929"
          width="4.623"
          height="2.612"
          x="3"
          y="12.561"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.849" dy="-0.485"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.262906 0 0 0 0 0.0367188 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.303"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.545098 0 0 0 0 0.309804 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.728" dy="-0.243"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.32549 0 0 0 0 0.00392157 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.243"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.596078 0 0 0 0 0.32549 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
        </filter>
        <filter
          id="filter2_f_159_8929"
          width="2.951"
          height="2.519"
          x="4.582"
          y="12.075"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.121"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter3_f_159_8929"
          width="24.817"
          height="7.884"
          x="3.434"
          y="25.784"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.303"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter4_f_159_8929"
          width="24.396"
          height="7.848"
          x="3.549"
          y="25.513"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.182"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter5_f_159_8929"
          width="5.871"
          height="5.638"
          x="1.844"
          y="7.823"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.061"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter6_iiii_159_8929"
          width="6.053"
          height="5.76"
          x="1.692"
          y="7.671"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.243" dy="-0.667"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.262906 0 0 0 0 0.0367188 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.303"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.545098 0 0 0 0 0.309804 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.971" dy="-2.427"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.415686 0 0 0 0 0.172549 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.121" dy="0.061"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.364706 0 0 0 0 0.0470588 0 0 0 0.3 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
        </filter>
        <filter
          id="filter7_f_159_8929"
          width="30.461"
          height="20.015"
          x="1.5"
          y="13.184"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.303"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter8_iiiiiiii_159_8929"
          width="34.586"
          height="25.718"
          x="0.59"
          y="9.969"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.971"></feOffset>
          <feGaussianBlur stdDeviation="1.031"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.552941 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="7.827" dy="-4.854"></feOffset>
          <feGaussianBlur stdDeviation="1.911"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.552941 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2.184" dy="3.094"></feOffset>
          <feGaussianBlur stdDeviation="1.881"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.892484 0 0 0 0 0.216667 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.485" dy="1.031"></feOffset>
          <feGaussianBlur stdDeviation="1.881"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-1.517" dy="0.121"></feOffset>
          <feGaussianBlur stdDeviation="2.852"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.87451 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect4_innerShadow_159_8929"
            result="effect5_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.335" dy="0.607"></feOffset>
          <feGaussianBlur stdDeviation="0.971"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.929412 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect5_innerShadow_159_8929"
            result="effect6_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-1.335"></feOffset>
          <feGaussianBlur stdDeviation="0.516"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.741176 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="effect6_innerShadow_159_8929"
            result="effect7_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.937"></feOffset>
          <feGaussianBlur stdDeviation="0.593"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.87451 0 0 0 0 0.0941176 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect7_innerShadow_159_8929"
            result="effect8_innerShadow_159_8929"
          ></feBlend>
        </filter>
        <filter
          id="filter9_f_159_8929"
          width="5.474"
          height="4.951"
          x="18.91"
          y="0.898"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.061"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter10_iii_159_8929"
          width="5.717"
          height="5.072"
          x="18.728"
          y="1.019"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.303" dy="0.364"></feOffset>
          <feGaussianBlur stdDeviation="0.607"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.956863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.303" dy="0.121"></feOffset>
          <feGaussianBlur stdDeviation="0.091"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.061" dy="0.303"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
        </filter>
        <filter
          id="filter11_dif_159_8929"
          width="28.002"
          height="8.481"
          x="1.973"
          y="12.505"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-1.929" dy="0.622"></feOffset>
          <feGaussianBlur stdDeviation="0.622"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.498039 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_8929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_8929"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.249" dy="0.56"></feOffset>
          <feGaussianBlur stdDeviation="0.124"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0 0 0 0 0.6 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_159_8929"></feBlend>
          <feGaussianBlur
            result="effect3_foregroundBlur_159_8929"
            stdDeviation="0.622"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter12_f_159_8929"
          width="23.962"
          height="8.505"
          x="4.791"
          y="27.526"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="1.517"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter13_iiif_159_8929"
          width="22.409"
          height="21.483"
          x="7.136"
          y="-1.406"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.971"></feOffset>
          <feGaussianBlur stdDeviation="1.031"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.552941 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="7.827" dy="-4.854"></feOffset>
          <feGaussianBlur stdDeviation="1.911"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.552941 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2.184" dy="1.274"></feOffset>
          <feGaussianBlur stdDeviation="1.881"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
          <feGaussianBlur
            result="effect4_foregroundBlur_159_8929"
            stdDeviation="0.091"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter14_ddf_159_8929"
          width="7.163"
          height="5.442"
          x="2.301"
          y="7.726"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.243" dy="0.485"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.243"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_159_8929"
            result="effect2_dropShadow_159_8929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_159_8929"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect3_foregroundBlur_159_8929"
            stdDeviation="0.121"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter15_iiii_159_8929"
          width="19.129"
          height="20.355"
          x="7.106"
          y="1.385"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.971"></feOffset>
          <feGaussianBlur stdDeviation="1.031"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.552941 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.485" dy="4.369"></feOffset>
          <feGaussianBlur stdDeviation="1.881"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.425" dy="-0.546"></feOffset>
          <feGaussianBlur stdDeviation="0.334"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.509804 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.243" dy="1.517"></feOffset>
          <feGaussianBlur stdDeviation="0.88"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.952941 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
        </filter>
        <filter
          id="filter16_diiif_159_8929"
          width="18.851"
          height="11.491"
          x="6.931"
          y="20.305"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.436"></feOffset>
          <feGaussianBlur stdDeviation="0.591"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.661333 0 0 0 0 0.00392157 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_8929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_8929"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2.241" dy="1.307"></feOffset>
          <feGaussianBlur stdDeviation="1.929"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.369" dy="1.058"></feOffset>
          <feGaussianBlur stdDeviation="0.778"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.929412 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_159_8929"
            result="effect3_innerShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.311" dy="-1.12"></feOffset>
          <feGaussianBlur stdDeviation="0.436"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.8 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
          <feGaussianBlur
            result="effect5_foregroundBlur_159_8929"
            stdDeviation="0.124"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter17_f_159_8929"
          width="3.35"
          height="2.488"
          x="20.234"
          y="23.619"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.061"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter18_ddiif_159_8929"
          width="2.942"
          height="3.626"
          x="8.116"
          y="6.077"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.061"></feOffset>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.091" dy="-0.03"></feOffset>
          <feGaussianBlur stdDeviation="0.015"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_159_8929"
            result="effect2_dropShadow_159_8929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_159_8929"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.121"></feOffset>
          <feGaussianBlur stdDeviation="0.061"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="shape" result="effect3_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.121" dy="0.061"></feOffset>
          <feGaussianBlur stdDeviation="0.061"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
          <feGaussianBlur
            result="effect5_foregroundBlur_159_8929"
            stdDeviation="0.015"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter19_ddif_159_8929"
          width="1.628"
          height="2.082"
          x="8.896"
          y="6.281"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.364"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.061" dy="-0.061"></feOffset>
          <feGaussianBlur stdDeviation="0.061"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_159_8929"
            result="effect2_dropShadow_159_8929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_159_8929"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.061" dy="-0.364"></feOffset>
          <feGaussianBlur stdDeviation="0.091"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
          <feBlend in2="shape" result="effect3_innerShadow_159_8929"></feBlend>
          <feGaussianBlur
            result="effect4_foregroundBlur_159_8929"
            stdDeviation="0.046"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter20_if_159_8929"
          width="0.785"
          height="0.995"
          x="8.418"
          y="7.686"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.061" dy="-0.364"></feOffset>
          <feGaussianBlur stdDeviation="0.091"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feGaussianBlur
            result="effect2_foregroundBlur_159_8929"
            stdDeviation="0.046"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter21_f_159_8929"
          width="3.953"
          height="4.859"
          x="6.649"
          y="10.8"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.03"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter22_ddiif_159_8929"
          width="4.56"
          height="5.345"
          x="6.649"
          y="10.496"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.182" dy="-0.061"></feOffset>
          <feGaussianBlur stdDeviation="0.03"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_8929"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.364" dy="-0.061"></feOffset>
          <feGaussianBlur stdDeviation="0.182"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.584314 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_159_8929"
            result="effect2_dropShadow_159_8929"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_159_8929"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.243" dy="0.243"></feOffset>
          <feGaussianBlur stdDeviation="0.273"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.596078 0 0 0 0 0.368627 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect3_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.061" dy="0.182"></feOffset>
          <feGaussianBlur stdDeviation="0.091"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.929167 0 0 0 0 0.22398 0 0 0 0 0.0271007 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_159_8929"
            result="effect4_innerShadow_159_8929"
          ></feBlend>
          <feGaussianBlur
            result="effect5_foregroundBlur_159_8929"
            stdDeviation="0.03"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter23_iif_159_8929"
          width="3.829"
          height="4.585"
          x="6.59"
          y="10.952"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-0.182" dy="0.061"></feOffset>
          <feGaussianBlur stdDeviation="0.121"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.294118 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_159_8929"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.425" dy="0.667"></feOffset>
          <feGaussianBlur stdDeviation="0.364"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.635294 0 0 0 0 0.407843 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="effect1_innerShadow_159_8929"
            result="effect2_innerShadow_159_8929"
          ></feBlend>
          <feGaussianBlur
            result="effect3_foregroundBlur_159_8929"
            stdDeviation="0.303"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter24_f_159_8929"
          width="3.416"
          height="1.982"
          x="18.202"
          y="13.878"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.121"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter25_f_159_8929"
          width="2.535"
          height="3.263"
          x="20.541"
          y="0.784"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.243"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter26_f_159_8929"
          width="14.286"
          height="11.112"
          x="9.939"
          y="4.544"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_159_8929"
            stdDeviation="0.91"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_159_8929"
          x1="4.277"
          x2="7.349"
          y1="14.809"
          y2="14.93"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB4E12" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#FB4E12"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_159_8929"
          x1="5.758"
          x2="27.645"
          y1="30.552"
          y2="30.552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA6C00" stopOpacity="0.33"></stop>
          <stop offset="0.512" stopColor="#F45800"></stop>
          <stop offset="1" stopColor="#F25900" stopOpacity="0.4"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_159_8929"
          x1="6.198"
          x2="27.581"
          y1="31.217"
          y2="31.217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF980B" stopOpacity="0.44"></stop>
          <stop offset="0.493" stopColor="#F55700"></stop>
          <stop offset="1" stopColor="#FEA11C" stopOpacity="0.28"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_159_8929"
          x1="4.676"
          x2="4.676"
          y1="7.944"
          y2="13.32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF995C"></stop>
          <stop offset="1" stopColor="#FF590F"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_159_8929"
          x1="4.676"
          x2="4.676"
          y1="7.944"
          y2="13.32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF995C"></stop>
          <stop offset="1" stopColor="#FF590F"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_159_8929"
          x1="4.779"
          x2="4.779"
          y1="7.701"
          y2="13.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF641C" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#FF641C"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_159_8929"
          x1="16.73"
          x2="16.73"
          y1="13.806"
          y2="32.577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC00"></stop>
          <stop offset="0.851" stopColor="#EDB001"></stop>
          <stop offset="1" stopColor="#FD870B"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_159_8929"
          x1="16.73"
          x2="16.73"
          y1="13.806"
          y2="32.577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC00"></stop>
          <stop offset="0.851" stopColor="#EDB001"></stop>
          <stop offset="1" stopColor="#FD870B"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_159_8929"
          x1="16.756"
          x2="16.756"
          y1="13.776"
          y2="32.547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC00"></stop>
          <stop offset="0.851" stopColor="#EDB001"></stop>
          <stop offset="1" stopColor="#FD870B"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_159_8929"
          x1="16.756"
          x2="16.756"
          y1="13.776"
          y2="32.547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#FF5D16"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_159_8929"
          x1="28.73"
          x2="6.395"
          y1="17.633"
          y2="19.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB0" stopOpacity="0.3"></stop>
          <stop offset="0.243" stopColor="#FFA000"></stop>
          <stop offset="0.552" stopColor="#FF7D00"></stop>
          <stop offset="1" stopColor="#FF9C00"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_159_8929"
          x1="4.615"
          x2="4.615"
          y1="8.005"
          y2="13.381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF995C"></stop>
          <stop offset="1" stopColor="#FF590F"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_159_8929"
          x1="4.718"
          x2="4.718"
          y1="7.762"
          y2="13.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF641C" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#FF641C"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_159_8929"
          x1="3.425"
          x2="7.101"
          y1="8.582"
          y2="11.624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9853"></stop>
          <stop offset="1" stopColor="#FF501C"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_159_8929"
          x1="12.945"
          x2="31.588"
          y1="24.103"
          y2="21.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB400"></stop>
          <stop offset="1" stopColor="#FFD300"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_159_8929"
          x1="23.833"
          x2="20.491"
          y1="23.799"
          y2="26.811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.498" stopColor="#FFEB01"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_159_8929"
          x1="9.578"
          x2="9.578"
          y1="6.192"
          y2="9.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B545A"></stop>
          <stop offset="1" stopColor="#262C34"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_159_8929"
          x1="10.025"
          x2="9.594"
          y1="5.909"
          y2="9.552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A4A54"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_159_8929"
          x1="8.595"
          x2="10.846"
          y1="11.042"
          y2="15.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3500"></stop>
          <stop offset="0.305" stopColor="#FF914F"></stop>
          <stop offset="1" stopColor="#FF4D0A"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_159_8929"
          x1="9.082"
          x2="7.374"
          y1="11.043"
          y2="15.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3500"></stop>
          <stop offset="0.305" stopColor="#FF914F"></stop>
          <stop offset="1" stopColor="#FF4D0A"></stop>
        </linearGradient>
        <radialGradient
          id="paint20_radial_159_8929"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(3.06403 1.30606 -1.30485 3.0612 8.504 13.563)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3601"></stop>
          <stop offset="1" stopColor="#FF6011" stopOpacity="0"></stop>
        </radialGradient>
        <linearGradient
          id="paint21_linear_159_8929"
          x1="21.374"
          x2="19.068"
          y1="14.121"
          y2="15.638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB801"></stop>
          <stop offset="1" stopColor="#FFB801" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint22_radial_159_8929"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(38.649 7.336 32.228) scale(1.71356 .79805)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7A00"></stop>
          <stop offset="1" stopColor="#FFEF00"></stop>
        </radialGradient>
        <radialGradient
          id="paint23_radial_159_8929"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(125.618 7.284 10.125) scale(4.59574 8.28547)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB200"></stop>
          <stop offset="1" stopColor="#FFB400" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Logo;