import React from "react";
import IIcon from "interfaces/icon";

function Template({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask
        id="mask0_1162_22103"
        style={{ maskType: "alpha" }}
        width="24"
        height="25"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0.17H24V24.17H0z"></path>
      </mask>
      <g mask="url(#mask0_1162_22103)">
        <path
          fill="#6D6777"
          d="M18.692 18.67H8.308c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V3.478c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.194c.241 0 .473.047.695.14.223.094.416.223.58.387l4.196 4.196c.164.164.293.357.387.58.093.222.14.454.14.695v9.194c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525zm.308-11h-3.289a1.17 1.17 0 01-.86-.351 1.17 1.17 0 01-.351-.86v-3.29H8.308a.294.294 0 00-.212.097.294.294 0 00-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 00.212.096h10.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V7.67zm-14.692 15c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V8.42c0-.213.072-.391.216-.535a.725.725 0 01.534-.215c.213 0 .391.072.534.215A.725.725 0 014 8.42v12.442c0 .077.032.148.096.212a.294.294 0 00.212.096h9.442c.213 0 .391.072.534.215a.725.725 0 01.216.535.725.725 0 01-.216.534.725.725 0 01-.534.216H4.308z"
        ></path>
      </g>
    </svg>
  );
}

export default Template;
