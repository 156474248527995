import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";
import { useState } from "react";
import IconUploader from "components/ProfileAvatarUpdater";
import { useAuthStore } from "store/authStore";

const ProfilePage = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [image, setImage] = useState<string | File | null>(null);
  return (
    <Box sx={{ maxWidth: "360px" }}>
      <Typography variant="h5">{t("profile")}</Typography>
      <Typography sx={{ color: "#6D6777", fontSize: "10px" }}>ID: {user?.userId}</Typography>
      <Box sx={{ padding: "24px 0", display: "flex", alignItems: "center" }}>
        <IconUploader image={image} onImageChange={setImage} userIcon={user?.icon || ""} />
        {!image && !user?.icon && (
          <Typography sx={{ color: "#6D6777", fontSize: "12px", width: "150px" }}>
            Allowed .jpeg, .jpg, .png, .gif max size of 3 Mb
          </Typography>
        )}
      </Box>
      {/* <Box sx={{ marginBottom: "16px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="name"
          height="40px"
          label={t("nameofuser")}
          name="name"
          value={user?.name || ""}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          customBgColor="#F6F7F9"
        />
      </Box> */}
      <Box sx={{ marginBottom: "16px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="email"
          height="40px"
          name="email"
          label={t("email")}
          value={user?.email || ""}
          onChange={() => {}}
          customBgColor="#F6F7F9"
        />
      </Box>
      <Box sx={{ marginBottom: "16px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="telegram"
          height="40px"
          name="telegram"
          label="Telegram"
          value={user?.telegram || ""}
          onChange={() => {}}
          customBgColor="#F6F7F9"
        />
      </Box>
    </Box>
  );
};

export default ProfilePage;
