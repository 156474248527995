import React from "react";
import IIcon from "interfaces/icon";

function Eye({ color = "#6D6777", width = 11, height = 15 }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="17" fill="none" viewBox="0 0 26 17">
      <path
        fill={color}
        d="M13.002 13.292c1.36 0 2.515-.476 3.465-1.427.95-.952 1.425-2.108 1.425-3.468 0-1.36-.476-2.514-1.427-3.464-.952-.95-2.108-1.425-3.468-1.425-1.36 0-2.515.475-3.465 1.427-.95.952-1.425 2.108-1.425 3.468 0 1.36.476 2.514 1.428 3.464.952.95 2.108 1.425 3.467 1.425zM13 11.64c-.9 0-1.665-.315-2.295-.945-.63-.63-.945-1.395-.945-2.295 0-.9.315-1.665.945-2.295.63-.63 1.395-.945 2.295-.945.9 0 1.665.315 2.295.945.63.63.945 1.395.945 2.295 0 .9-.315 1.665-.945 2.295-.63.63-1.395.945-2.295.945zm0 5.16c-2.526 0-4.837-.674-6.931-2.022-2.095-1.347-3.767-3.12-5.016-5.319a2.114 2.114 0 01-.22-.52 2.11 2.11 0 01.22-1.598c1.25-2.199 2.921-3.972 5.016-5.32C8.163.675 10.474 0 12.999 0c2.527 0 4.837.674 6.932 2.022 2.095 1.347 3.767 3.12 5.016 5.319.1.172.173.346.22.52a2.11 2.11 0 01-.22 1.598c-1.25 2.199-2.921 3.972-5.016 5.32C17.836 16.125 15.526 16.8 13 16.8zm0-1.8c2.26 0 4.335-.595 6.225-1.785A11.727 11.727 0 0023.56 8.4c-1-2.02-2.445-3.625-4.335-4.815C17.335 2.395 15.26 1.8 13 1.8s-4.335.595-6.225 1.785A11.727 11.727 0 002.44 8.4c1 2.02 2.445 3.625 4.335 4.815C8.665 14.405 10.74 15 13 15z"
      ></path>
    </svg>
  );
}

export default Eye;
