import { Navigate, Outlet } from "react-router";
import { FC, ReactNode } from "react";

interface iProtectedRouteHOCProps {
  redirectPath?: string;
  children?: ReactNode;
}

export const UnProtectedRouteHOC: FC<iProtectedRouteHOCProps> = ({ children }) => {
  const sessionToken = localStorage.getItem("authToken") || null;
  if (sessionToken) {
    return <Navigate to={"/dashboard"} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
