import IIcon from "interfaces/icon";
import React from "react";

function Person({ color }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <path
        fill={color}
        d="M7 6.743a2.81 2.81 0 01-2.062-.855 2.81 2.81 0 01-.855-2.061c0-.805.285-1.492.855-2.062A2.81 2.81 0 017 .91a2.81 2.81 0 012.062.855c.57.57.855 1.257.855 2.062a2.81 2.81 0 01-.855 2.061A2.81 2.81 0 017 6.743zm-6.25 5.08v-.586c0-.408.11-.786.333-1.134a2.22 2.22 0 01.888-.802 12.09 12.09 0 012.493-.908 10.79 10.79 0 015.072 0c.838.202 1.67.504 2.493.908.37.187.667.454.889.802.221.348.332.726.332 1.134v.587c0 .351-.123.65-.37.896a1.22 1.22 0 01-.896.37H2.016c-.351 0-.65-.123-.897-.37a1.221 1.221 0 01-.369-.896z"
      ></path>
    </svg>
  );
}

export default Person;
