import React, { useState } from "react";
import { Modal, Fade, Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import CustomButton from "./Button";
import { ModalContent, style, StyledBackdrop } from "./CautionModal";
import { useTranslation } from "react-i18next";
import { getToken } from "./Pushes";
import useFetchFormData from "hooks/useFetchFormData";
import ToastMessage from "components/ToastMessage";

interface MetaTagsModalProps {
  openModal: boolean;
  closeModal: () => void;
  id: string;
  pwaMeta?: string;
}

export const MetaTagsModal: React.FC<MetaTagsModalProps> = ({ id, openModal, closeModal, pwaMeta }) => {
  const [metaTagsInput, setMetaTagsInput] = useState(pwaMeta?.replace(/^"(.*)"$/, "$1").replace(/\\"/g, '"') || "");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const processMetaTags = () => {
    // Split the input by line breaks or other delimiters if needed
    const metaTags = DOMPurify.sanitize(metaTagsInput).trim().split(/\n/);
    // Regular expression to validate general <meta> tag structure
    const metaTagRegex = /^<meta\s+([^>]+)\s*\/?>$/i;

    for (let tag of metaTags) {
      tag = tag.trim(); // Remove extra spaces
      if (tag && !metaTagRegex.test(tag)) {
        setError(t("metaTagsError"));
        return false; // Invalid meta tag found
      }
    }

    setError("");
    submitMetaTags(metaTagsInput);
  };
  const token = getToken();
  const { triggerFetch: updatePWA } = useFetchFormData({
    url: `pwa/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const submitMetaTags = async (metaTags: string) => {
    const data = new FormData();
    data.append("metaTags", JSON.stringify(metaTags));
    await updatePWA(data);
    closeModal();
    ToastMessage({ mode: "info", message: t("successPwaUpdate") });
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-meta-tags-title"
      aria-describedby="modal-meta-tags-description"
      slots={{ backdrop: StyledBackdrop }}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <ModalContent
          sx={{
            ...style,
            width: "350px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              padding: "15px",
            }}
          >
            <Typography
              sx={{
                color: "#2E263D",
                fontFamily: "var(--cygreRegular), sans-serif",
                fontSize: "18px",
                lineHeight: "20px",
                padding: "16px 0",
                textAlign: "center",
              }}
            >
              {t("nametatags")}
            </Typography>
            <textarea
              id="metaTagsInput"
              value={metaTagsInput}
              onChange={(e) => setMetaTagsInput(e.target.value)}
              style={{
                width: "100%",
                minHeight: "200px",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "8px",
                borderColor: error ? "red" : "#ccc",
                resize: "vertical",
              }}
            />
            {error && (
              <Typography sx={{ color: "red", fontSize: "14px", marginBottom: "20px", textAlign: "center" }}>
                {error}
              </Typography>
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <CustomButton
                textButton={t("cancel")}
                height={40}
                width="48%"
                customBgColor="#F4701B"
                customHoverColor="#F4701B"
                customBorderRadius="8px"
                customFontSize="14px"
                onClick={closeModal}
              />
              <CustomButton
                textButton={t("send")}
                height={40}
                width="48%"
                customBgColor="#F4701B"
                customHoverColor="#F4701B"
                customBorderRadius="8px"
                customFontSize="14px"
                onClick={processMetaTags}
              />
            </Box>
          </Box>
        </ModalContent>
      </Fade>
    </Modal>
  );
};

export default MetaTagsModal;
