import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styled from "@emotion/styled";

const useSharePopup = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [shareLink, setShareLink] = useState<string>("");
  const [copied, setCopied] = useState<boolean>(false);

  const handleOpenPopup = (link: string) => {
    setShareLink(link);
    setOpenPopup(true);
  };

  const closePopup = () => {
    setOpenPopup(false);
    setCopied(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
  };

  return {
    // eslint-disable-next-line no-restricted-globals
    open,
    shareLink,
    copied,
    setCopied,
    openPopup,
    closePopup,
    handleCopy,
    handleOpenPopup,
  };
};

interface SharePopupProps {
  open: boolean;
  onClose: () => void;
  shareLink: string;
  copied: boolean;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  handleCopy: () => void;
}

const SharePopup: React.FC<SharePopupProps> = ({ open, onClose, shareLink, copied, setCopied, handleCopy }) => {
  const { t } = useTranslation();
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose();
    setCopied(false);
  };

  const TextFieldStyled = styled(TextField)(() => ({
    color: "#6D6777",
    margin: "5px",
    width: "374px",
    height: "40px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    "& input": {
      padding: "0px 10px !important",
      height: "40px",
      color: "black",
      fontSize: "14px",
    },
  }));

  const ButtonStyled = styled(Button)(() => ({
    backgroundColor: "#F4701B",
    width: "140px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#F0670B",
    },
  }));

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid
        item
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "20px",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <DialogTitle>{t("sharepwa")}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>
            <CloseOutlinedIcon sx={{ color: "black" }} />
          </Button>
        </DialogActions>
      </Grid>
      <DialogContent
        sx={{
          color: "#6D6777",
          margin: "5px",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              height: "88px",
              width: "575px",
              padding: "0px !important",
              borderRadius: "8px",
            }}
          >
            <TextFieldStyled value={shareLink} fullWidth disabled />
            <ButtonStyled
              className="create_btn"
              variant="contained"
              aria-label="opy pwa"
              size="medium"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                return handleCopy();
              }}
            >
              {t("copy")}
            </ButtonStyled>
          </Grid>
          {copied && <Typography color="primary">Скопировано в буфер обмена!</Typography>}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export { useSharePopup, SharePopup };
