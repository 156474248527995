import { useTranslation } from "react-i18next";

export default function ProfileNotificationsPage() {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t("pageindevelopment")}</h1>
    </div>
  );
}
