import * as React from "react";
import PhoneIcon from "assets/icons/Phone";
import CreativeIcon from "assets/icons/Creatives";
import Domain from "assets/icons/Domain";
import Shuffle from "assets/icons/Shuffle";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomTabs from "./CustomTabs";

interface IPwaTabs {
  value: number;
  setValue: (value: number) => void;
  renderTabContent: () => React.ReactNode;
}

export default function PwaTabs({ value, setValue, renderTabContent }: IPwaTabs) {
  const { t } = useTranslation();

  const tabs = [
    {
      icon: <PhoneIcon color={value === 0 ? "#F4701B" : "#6D6777"} />,
      label: "main",
    },
    {
      icon: <CreativeIcon color={value === 1 ? "#F4701B" : "#6D6777"} />,
      label: "creatives",
    },
    {
      icon: <Domain color={value === 2 ? "#F4701B" : "#6D6777"} />,
      label: "domain",
    },
    {
      icon: <Shuffle color={value === 3 ? "#F4701B" : "#6D6777"} />,
      label: "linktracking",
    },
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            maxWidth: "fit-content",
            borderRadius: "8px",
            background: "#F6F7FB",
          }}
        >
          <CustomTabs width={"auto"} value={value} setValue={setValue} tabs={tabs} t={t} />
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          marginRight: { xs: "0", md: "0" },
          maxWidth: "100%",
          paddingTop: "24px",
        }}
      >
        {renderTabContent()}
      </Box>
    </>
  );
}
