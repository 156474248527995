import HeaderSection from "components/HeaderSection";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import AdvantageCard from "./AdvantageCard";
import Rocket from "assets/icons/Rocket";
import FLightning from "assets/icons/FLightning";
import FGraphUp from "assets/icons/FGraphUp";
import FNotification from "assets/icons/FNotification";
import Wifi from "assets/icons/WiFi";

export default function Advantages() {
  const { t } = useTranslation();

  const advantages = [
    {
      title: t("easyaccessandinstallation"),
      text: t("easyaccessandinstallationdescription"),
      icon: <Rocket />,
    },
    {
      title: t("fastloadingandresponsiveness"),
      text: t("fastloadingandresponsivenessdescription"),
      icon: <FLightning />,
    },
    {
      title: t("offlinefunctionality"),
      text: t("offlinefunctionalitydescription"),
      icon: <Wifi />,
    },
    {
      title: t("customerreengagement"),
      text: t("customerreengagementdescription"),
      icon: <FNotification />,
    },
    {
      title: t("lowerdevelopmentandmaintenancecosts"),
      text: t("lowerdevelopmentandmaintenancecostsdescription"),
      icon: <FGraphUp />,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        marginTop: "23px",
        padding: "120px 0",
      }}
    >
      <Box
        id="advantages-and-benefits"
        sx={{
          marginBottom: "80px",
          position: "sticky",
          top: `15vh`,
        }}
      >
        <HeaderSection title={t("advantagesandbenefits")} />
      </Box>
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        <Box sx={{ maxWidth: { xs: "350px", sm: "100%" } }}>
          {advantages.map((advantage, index) => (
            <Box
              key={index}
              sx={{
                position: "sticky",
                top: `calc(30vh + ${index * 5}vh)`,
                left: `${20 + Math.random() * 10}vw`,
                transform: `rotate(${Math.random() * 6 - 3}deg)`,
                marginBottom: "10vh",
                border: "1px solid #ccc",
                boxShadow: "5px -5px 5px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
                color: "#333",
                borderRadius: "10px",
                zIndex: 1,
              }}
            >
              <AdvantageCard title={advantage.title} text={advantage.text} icon={advantage.icon} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
