import { Box, styled, Switch } from "@mui/material";
import { usePushStore } from "store/pushStore";

const CustomTimezoneSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#F4701B",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#bdbdbd",
    opacity: 1,
  },
}));

interface ICheckBox {
  checked?: boolean;
  onChange?: any;
}

const TimezoneSwitch: React.FC<ICheckBox> = () => {
  const { savePushData } = usePushStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    savePushData({
      useUserTimezone: checked,
    });
  };
  return (
    <Box>
      <CustomTimezoneSwitch onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
    </Box>
  );
};

export default TimezoneSwitch;
