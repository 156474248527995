import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "0 20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

interface IRegionModal {
  region: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  countries: string[];
}

export default function RegionModal({ open, setOpen, region, countries }: IRegionModal) {
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const halfLength = Math.ceil(countries.length / 2);
  const leftColumn = countries.slice(0, halfLength);
  const rightColumn = countries.slice(halfLength);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ borderRadius: "20px", padding: "0" }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              padding: "10px",
              position: "sticky",
              top: 0,
              bgcolor: "background.paper",
              zIndex: 1,
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#000",
              }}
            >
              {t("region")} {region}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "relative",
                right: 0,
                top: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "calc(80vh - 120px)",
              padding: "20px 0",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                {leftColumn.map((country, index) => (
                  <Typography key={index} p="8px 0" color="text.primary" fontSize="18px">
                    {country}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ flex: 1 }}>
                {rightColumn.map((country, index) => (
                  <Typography key={index} p="8px 0" color="text.primary" fontSize="18px">
                    {country}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="caption"></Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
