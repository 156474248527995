import React from "react";

function ShowTrue() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_897_179)">
        <path
          fill="#1C1C1C"
          d="M2 12c.869-4.551 5.015-8 10-8 4.984 0 9.13 3.449 10 8-.869 4.551-5.016 8-10 8-4.985 0-9.131-3.449-10-8zm10 4.444a4.717 4.717 0 003.268-1.301A4.36 4.36 0 0016.62 12a4.36 4.36 0 00-1.353-3.143 4.717 4.717 0 00-3.269-1.301 4.717 4.717 0 00-3.268 1.301A4.36 4.36 0 007.378 12a4.36 4.36 0 001.353 3.143A4.717 4.717 0 0012 16.444zm0-1.777a2.83 2.83 0 01-1.961-.781A2.616 2.616 0 019.227 12c0-.707.292-1.386.812-1.886a2.83 2.83 0 011.96-.78c.736 0 1.441.28 1.961.78s.813 1.179.813 1.886c0 .707-.293 1.386-.813 1.886a2.83 2.83 0 01-1.96.78z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_897_179">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShowTrue;
