import { useTranslation } from "react-i18next";

export default function useCategories() {
  const { t } = useTranslation();

  const categories = [
    { value: t("automobilestransport"), label: t("automobilestransport") },
    { value: t("librariesdemo"), label: t("librariesdemo") },
    { value: t("business"), label: t("business") },
    { value: t("videoplayerseditors"), label: t("videoplayerseditors") },
    { value: t("artdesign"), label: t("artdesign") },
    { value: t("fooddrink"), label: t("fooddrink") },
    { value: t("games"), label: t("games") },
    { value: t("tools"), label: t("tools") },
    { value: t("househome"), label: t("househome") },
    { value: t("healthfitness"), label: t("healthfitness") },
    { value: t("dating"), label: t("dating") },
    { value: t("mapsnavigation"), label: t("mapsnavigation") },
    { value: t("books"), label: t("books") },
    { value: t("comics"), label: t("comics") },
    { value: t("beauty"), label: t("beauty") },
    { value: t("parenting"), label: t("parenting") },
    { value: t("medical"), label: t("medical") },
    { value: t("events"), label: t("events") },
    { value: t("music"), label: t("music") },
    { value: t("newsmagazines"), label: t("newsmagazines") },
    { value: t("education"), label: t("education") },
    { value: t("lifestyle"), label: t("lifestyle") },
    { value: t("communication"), label: t("communication") },
    { value: t("personalization"), label: t("personalization") },
    { value: t("weather"), label: t("weather") },
    { value: t("shopping"), label: t("shopping") },
    { value: t("travel & Local"), label: t("travel & Local") },
    { value: t("work"), label: t("work") },
    { value: t("entertainment"), label: t("entertainment") },
    { value: t("social"), label: t("social") },
    { value: t("sports"), label: t("sports") },
    { value: t("finance"), label: t("finance") },
    { value: t("photography"), label: t("photography") },
  ];

  return categories;
}
