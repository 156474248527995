import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import useFetch from "hooks/useFetch";
import * as React from "react";
import { SharePopup, useSharePopup } from "components/SharePopup";
// import useIdStore from "@/store/pwaId";
// import { useAppStore } from "@/store/appStore";
import { useTranslation } from "react-i18next";
import Trash from "assets/icons/Trash";
import Share from "assets/icons/Share";
import Copy from "assets/icons/Copy";
import MetaTags from "assets/icons/MetaTags";
import { Typography } from "@mui/material";
import ImportPwa from "assets/icons/ImportPwa";
import { getToken } from "utils/getToken";
import MetaTagsModal from "components/MetaTagsModal";

const StyledMenu = styled((props: MenuProps) => {
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  );
})(({ theme }) => {
  return {
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  };
});

interface IMenuTableButton {
  id: string;
  getPwas: () => void;
  pwaMeta: any;
}

export default function MenuTableButton({ id, getPwas, pwaMeta }: IMenuTableButton) {
  const token = getToken();
  const [openMetaTagsModal, setOpenMetaTagsModal] = React.useState(false);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { loading, triggerFetch } = useFetch({
    url: `pwa/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  async function handleDeletePwa() {
    handleClose();
    await triggerFetch();
    getPwas();
  }

  const { triggerFetch: triggerShare } = useFetch({
    url: `pwa/${id}/share-pwa`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { openPopup, closePopup, shareLink, copied, setCopied, handleCopy, handleOpenPopup } = useSharePopup();

  const handleShare = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    try {
      const shareLink = await triggerShare();
      handleOpenPopup(window.location.host + shareLink?.data?.shareLink);
    } catch (error) {
      console.error("Ошибка при получении ссылки для шаринга:", error);
    }
  };

  async function handleCopyPwa() {
    handleClose();
    await triggerCopyPwa();
    await getPwas();
  }

  const { triggerFetch: triggerCopyPwa } = useFetch({
    url: `pwa/copy/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    callback?: (event: React.MouseEvent<HTMLElement>) => void
  ) => {
    event.stopPropagation();
    if (callback) callback(event);
  };

  return (
    <div>
      <SharePopup
        open={openPopup}
        onClose={closePopup}
        shareLink={shareLink}
        copied={copied}
        setCopied={setCopied}
        handleCopy={handleCopy}
      />
      <Button
        id="demo-customized-button"
        color="inherit"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        style={{ padding: "0", display: "flex", justifyContent: "flex-end" }}
      >
        <MoreHorizIcon sx={{ color: "#6D6777" }} />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        sx={{ padding: "12px 12px 6px", display: "flex", justifyContent: "flex-start" }}
        anchorEl={anchorEl}
        open={open}
        onClose={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleClose();
        }}
      >
        <MenuItem
          sx={{
            paddingBottom: "8px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(event) => handleMenuItemClick(event, handleShare)}
          disableRipple
        >
          <Share />
          <Typography
            sx={{
              fontFamily: "var(--cygreMedium), sans-serif",
              paddingLeft: "12px",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#2E263D",
            }}
          >
            {t("share")}
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            paddingBottom: "8px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            handleClose();
            setOpenMetaTagsModal(true);
          }}
          disableRipple
        >
          <MetaTags />
          <Typography
            sx={{
              fontFamily: "var(--cygreRegular), sans-serif",
              paddingLeft: "12px",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#2E263D",
            }}
          >
            {t("entermetatags")}
          </Typography>
        </MenuItem>
        {/*<MenuItem*/}
        {/*  sx={{*/}
        {/*    paddingBottom: "8px",*/}
        {/*    display: "flex",*/}
        {/*    alignItems: "center",*/}
        {/*  }}*/}
        {/*  onClick={handleMenuItemClick}*/}
        {/*  disableRipple*/}
        {/*>*/}
        {/*  <ImportPwa color={"#2E263D"} />*/}
        {/*  <Typography*/}
        {/*    sx={{*/}
        {/*      fontFamily: "var(--cygreRegular), sans-serif",*/}
        {/*      paddingLeft: "12px",*/}
        {/*      fontSize: "16px",*/}
        {/*      lineHeight: "19px",*/}
        {/*      color: "#2E263D",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t("enterjsscript")}*/}
        {/*  </Typography>*/}
        {/*</MenuItem>*/}
        <MenuItem
          sx={{
            paddingBottom: "8px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleCopyPwa}
          disableRipple
        >
          <Copy />
          <Typography
            sx={{
              fontFamily: "var(--cygreRegular), sans-serif",
              paddingLeft: "12px",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#2E263D",
            }}
          >
            {t("сopy")}
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            paddingBottom: "8px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(event) => handleMenuItemClick(event, handleDeletePwa)}
          disableRipple
        >
          <Trash color="#2E263D" width={20} height={22} />
          <Typography
            sx={{
              fontFamily: "var(--cygreRegular), sans-serif",
              paddingLeft: "12px",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#2E263D",
            }}
          >
            {t("delete")}
          </Typography>
        </MenuItem>
      </StyledMenu>
      <MetaTagsModal
        id={id}
        pwaMeta={pwaMeta}
        openModal={openMetaTagsModal}
        closeModal={() => setOpenMetaTagsModal(false)}
      />
    </div>
  );
}
