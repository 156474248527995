import React from "react";
import { Accordion, Box, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import CustomSelectWithCheckboxes from "components/CustomSelect";
import GeoMultiSelect from "components/GeoMultiSelect";
import { usePushStore } from "store/pushStore";

export default function NewsletterSegmentation() {
  const { t } = useTranslation();
  const savePushData = usePushStore((state) => state.savePushData);
  const pushData = usePushStore((state) => state.pushData);

  const options = [
    { name: "Total Subscriptions", value: "Total Subscriptions", label: "Total Subscriptions" },
    { name: "Inactive Subscriptions", value: "Inactive Subscriptions", label: "Inactive Subscriptions" },
    { name: "Active Subscriptions", value: "Active Subscriptions", label: "Active Subscriptions" },
  ];

  const handleSelectChange = (selectedValues: string[]) => {
    const cleanedValues = selectedValues.filter((val) => val !== t("selectUserSegments"));

    savePushData({
      usersSegment: cleanedValues,
    });
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary sx={{ height: "48px" }} expandIcon={<ExpandMoreIcon />}>
        <Typography
          sx={{ lineHeight: "32px", fontSize: "16px", color: "#2E263D", fontFamily: "var(--cygreMedium), sans-serif" }}
        >
          {t("segmentation")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ paddingBottom: "24px" }}>
          <GeoMultiSelect />
        </Box>
        <CustomSelectWithCheckboxes
          options={options}
          title={t("userSegment")}
          selectedValues={pushData.usersSegment || []}
          onChange={handleSelectChange}
          placeholder={t("selectUserSegments")}
        />
      </AccordionDetails>
    </Accordion>
  );
}
