import { create } from "zustand";

export type StepStatus = "notconfigured" | "inprogress" | "ready";

interface Steps {
  main: StepStatus;
  creatives: StepStatus;
  domain: StepStatus;
  tracking: StepStatus;
  // comments: StepStatus;
}

interface SetupState {
  steps: Steps;
  isSettingProcess: boolean;
  setStepStatus: (step: keyof Steps, status: StepStatus) => void;
  checkAndSetStepStatus: (step: keyof Steps, values: string[]) => void;
  setSettingProcess: (isSettingProcess: boolean) => void;
  resetAllSteps: () => void; // Новый метод
}

const useSetupStore = create<SetupState>((set) => ({
  steps: {
    main: "notconfigured",
    creatives: "notconfigured",
    domain: "notconfigured",
    tracking: "notconfigured",
    // comments: "notconfigured",
  },
  isSettingProcess: false,
  setStepStatus: (step, status) =>
    set((state) => ({
      steps: {
        ...state.steps,
        [step]: status,
      },
    })),
  checkAndSetStepStatus: (step, values) => {
    let status: StepStatus = "notconfigured";
    if (values.every((value) => value !== "")) {
      status = "ready";
    } else if (values.some((value) => value !== "")) {
      status = "inprogress";
    }
    set((state) => ({
      steps: {
        ...state.steps,
        [step]: status,
      },
    }));
  },
  setSettingProcess: (isSettingProcess) => set({ isSettingProcess }),
  resetAllSteps: () =>
    set({
      steps: {
        main: "notconfigured",
        creatives: "notconfigured",
        domain: "notconfigured",
        tracking: "notconfigured",
        // comments: "notconfigured",
      },
    }),
}));

export default useSetupStore;
