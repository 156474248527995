import { Avatar, Box, Card, CardContent, Rating, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IFeedbackCard {
  name: string;
  shortDesc: string;
  longDesc: string;
  avatar: string;
  rating: number;
}

export default function FeedbackCard({ name, shortDesc, longDesc, avatar, rating }: IFeedbackCard) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        maxWidth: { xs: 350, xl: 380 },
        borderRadius: "11px",
        boxShadow: 0,
        background: "#F6F7F9",
      }}
    >
      <CardContent
        sx={{
          padding: "36px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <Rating
            name="read-only"
            value={rating}
            readOnly
            sx={{
              paddingBottom: "32px",
              fontSize: "14px",
              color: "#FFC608",
              "& .MuiRating-iconFilled": {
                color: "#FFC608",
              },
              "& .MuiRating-iconHover": {
                color: "#FFC608",
              },
            }}
          />
          <Typography variant="h5" color="text.primary">
            {t(shortDesc)}
          </Typography>
          <Typography sx={{ paddingTop: "20px" }} variant="body1" color="text.secondary">
            {t(longDesc)}
          </Typography>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "auto",
            paddingTop: "32px",
          }}
        >
          <Avatar alt={t(name)} src={avatar} />
          <Typography variant="h6" sx={{ paddingLeft: "16px" }}>
            {t(name)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
