import { Typography, Box, Link } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useTranslation } from "react-i18next";

export default function Telegram() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        padding: "70px 16px 120px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "48px 10px", sm: "90px 39px" },
          background: "#000",
          borderRadius: "24px",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: "16px", sm: "18px" },
            lineHeight: "24px",
            paddingBottom: "20px",
            textAlign: "center",
          }}
        >
          TELEGRAM
        </Typography>
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#fff",
            fontSize: { xs: "35px", sm: "40px" },
            lineHeight: "56px",
            paddingBottom: "30px",
            textAlign: "center",
          }}
        >
          Join our Telegram Channel
        </Typography>
        <Typography sx={{ textAlign: "center" }} variant="body1">
          Lorem imp sum lorem imp sum imp sum lorem imp sum imp sum lorem imp sum{" "}
        </Typography>
        <Box
          sx={{
            marginTop: "50px",
            padding: "16px 24px 16px 24px",
            display: "flex",
            alignItems: "center",
            borderRadius: "100px",
            background: "#fff",
          }}
        >
          <Link
            sx={{
              fontFamily: "var(--cygreMedium), sans-serif",
              color: "#000",
              fontSize: "20px",
            }}
            href="#"
            underline="none"
          >
            {t("join")}
          </Link>
          <Box
            sx={{
              marginLeft: "15px",
              width: "26.67px",
              height: "26.67px",
              background: "#000",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            component="span"
          >
            <TelegramIcon sx={{ color: "#fff", width: "19px", height: "19px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
