"use client";
import { Grid, Box, Button } from "@mui/material";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { IPwa } from "interfaces/pwas";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StatsTable from "components/StatsTable";
import StatsSelectComponent from "components/StatsSelect";
import { IPwaStatisticsItem } from "pages/DashboardPage";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SelectPeriod from "components/Charts/SelectPeriod";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

export default function Stats() {
  const { t } = useTranslation();
  const location = useLocation();
  const [pwas, setPwas] = useState<Array<IPwa>>([]);
  const [pwasArray, setPwasArray] = useState<Array<string>>([]);
  const [clicks, setClicks] = useState<Array<IPwa>>([]);
  const [allClicks, setAllClicks] = useState<any>(null);
  const [totalInstallCount, setTotalInstallCount] = useState<number | undefined>(undefined);
  const [report, setReport] = useState<any>(null);
  const [selectedRange, setSelectedRange] = useState<string>("today");

  const [dateRange, setDateRange] = useState<[string | null, string | null]>([null, null]);

  const token = getToken();

  const fromDate = dateRange[0];
  const toDate = dateRange[1];

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const formatMMDDYYYY = (date: Date | null) => {
      if (!date) return null;
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = String(date.getFullYear());
      return `${year}-${month}-${day}`;
    };

    setDateRange([formatMMDDYYYY(dates[0]), formatMMDDYYYY(dates[1])]);
  };

  const { triggerFetch } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: loadingAllStats, triggerFetch: getAllStats } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: fromDate || "",
          to: toDate || "",
          timezone: "Europe/Madrid",
          interval: selectedRange,
        },
        filters: [
          {
            name: "campaign",
            operator: "IN_LIST",
            expression: pwasArray,
          },
        ],
      },
    },
  });

  const { loading: loadingReport, triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: fromDate || "",
          to: toDate || "",
          timezone: "Europe/Madrid",
          interval: selectedRange,
        },
        filters: [
          {
            name: "campaign",
            operator: "EQUALS",
            expression: report,
          },
        ],
      },
    },
  });

  useEffect(() => {
    void getPwas();
  }, []);

  const { triggerFetch: getStats } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const locationState = location.state;

    if (locationState) {
      setReport(location.state);
    } else {
      getPwas();
    }
  }, [location]);

  useEffect(() => {
    if (report) {
      void buildReport();
    }
  }, [report, selectedRange, fromDate, toDate]);

  useEffect(() => {
    if (Array.isArray(pwas) && pwas.length > 0) {
      const campaignNames = pwas.map((pwa) => pwa.appName).filter(Boolean);
      setPwasArray(campaignNames);
    }
  }, [pwas]);

  useEffect(() => {
    if (pwasArray.length > 0) {
      buildAllStats();
    }
  }, [pwasArray, selectedRange, fromDate, toDate]);

  async function getPwas() {
    const result = await triggerFetch();
    setPwas(result?.data?.items || []);
  }

  async function buildAllStats() {
    const res = await getAllStats();
    if (res?.data?.rows) {
      setAllClicks(res.data.rows);
    }

    const statsRes = await getStats();
    const totalInstallCount = statsRes?.data.items.reduce(
      (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
      0
    );
    setTotalInstallCount(totalInstallCount);
  }

  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    setReport(value);
  };

  const buildReport = async () => {
    const res = await getReport();
    setClicks(res?.data?.rows || []);

    const statsRes = await getStats();
    const totalInstallCount = statsRes?.data.items.reduce(
      (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
      0
    );
    setTotalInstallCount(totalInstallCount);
  };

  const handleResetRange = () => {
    setDateRange([null, null]);
    setSelectedRange("today");
    buildAllStats();
  };

  const isLoading = loadingAllStats || loadingReport;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            maxWidth: "1180px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontSize: "24px", fontWeight: 500, display: "flex" }}>
            <StatsSelectComponent
              title={t("selectPwa")}
              options={pwas}
              backgroundColor="#F6F7F9"
              noBorder={true}
              value={pwas}
              onChange={handleSelectChange}
            />

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "end", marginLeft: "15px" }}>
              <SelectPeriod
                selectedPeriod={selectedRange}
                onChange={setSelectedRange}
                onChangeDateRange={handleDateChange}
              />
              <Box sx={{ cursor: "pointer", marginLeft: "15px", marginBottom: "15px" }} onClick={handleResetRange}>
                <CachedOutlinedIcon />
              </Box>
            </Box>
          </Box>

          <Box>
            <Button
              sx={{
                backgroundColor: "#F4701B",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#F0670B",
                },
                color: "white",
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="medium"
              onClick={buildReport}
            >
              {t("generateReport")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <StatsTable
            getPwas={getPwas}
            loading={isLoading}
            pwas={pwas}
            clicks={clicks}
            totalInstallCount={totalInstallCount}
            allClicks={allClicks}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
