import IIcon from "interfaces/icon";
import React from "react";

function Calendar({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask
        id="mask0_1625_12714"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0.4 0.4H19.599999999999998V19.599999999999998H0.4z"></path>
      </mask>
      <g mask="url(#mask0_1625_12714)">
        <path
          fill={color}
          d="M3.708 19.9c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.282V4.708c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V1.554c0-.219.074-.402.22-.549a.746.746 0 01.55-.22c.219 0 .402.073.549.22.146.147.22.33.22.55V2.9h7.577V1.535c0-.213.072-.39.215-.535a.727.727 0 01.535-.215c.213 0 .39.072.534.215a.726.726 0 01.216.535V2.9h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H3.708zm0-1.5h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.211V8.708h-14v9.385c0 .077.032.147.096.211a.294.294 0 00.212.096zM3.4 7.208h14v-2.5a.294.294 0 00-.096-.211.294.294 0 00-.212-.097H3.708a.294.294 0 00-.212.097.294.294 0 00-.096.211v2.5zm7 5.27a.853.853 0 01-.626-.26.853.853 0 01-.258-.625c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.258.626.852.852 0 01-.258.626.853.853 0 01-.626.258zm-4 0a.853.853 0 01-.626-.26.853.853 0 01-.258-.625c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.259.626.853.853 0 01-.259.626.853.853 0 01-.626.258zm8 0a.853.853 0 01-.626-.26.853.853 0 01-.258-.625c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.258.626.852.852 0 01-.258.626.853.853 0 01-.626.258zm-4 3.922a.853.853 0 01-.626-.258.853.853 0 01-.258-.626c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.852.852 0 01.258.626.852.852 0 01-.258.626.853.853 0 01-.626.258zm-4 0a.853.853 0 01-.626-.258.853.853 0 01-.258-.626c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.259.626.853.853 0 01-.259.626.853.853 0 01-.626.258zm8 0a.853.853 0 01-.626-.258.853.853 0 01-.258-.626c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.852.852 0 01.258.626.852.852 0 01-.258.626.853.853 0 01-.626.258z"
        ></path>
      </g>
    </svg>
  );
}

export default Calendar;
