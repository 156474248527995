import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";

const useRouteChange = (onRouteChange: (location: Location) => void) => {
  const location = useLocation();

  useEffect(() => {
    onRouteChange(location);
  }, [location.pathname]);
};

export default useRouteChange;
