import React from "react";
import IIcon from "interfaces/icon";

function PlayIcon({ width = 19, height = 19, color = "#16B364" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 20">
      <path
        fill={color}
        d="M8.794 13.535l4.356-2.798a.637.637 0 00.321-.566.642.642 0 00-.321-.568L8.794 6.805a.616.616 0 00-.69-.037.643.643 0 00-.354.604v5.596c0 .274.118.475.354.604.236.13.466.117.69-.037zm1.208 6.135a9.254 9.254 0 01-3.706-.748 9.595 9.595 0 01-3.017-2.03 9.595 9.595 0 01-2.03-3.016A9.246 9.246 0 01.5 10.172c0-1.314.25-2.55.748-3.706a9.596 9.596 0 012.03-3.017 9.594 9.594 0 013.016-2.03 9.246 9.246 0 013.704-.75c1.314 0 2.55.25 3.706.749a9.596 9.596 0 013.017 2.03 9.594 9.594 0 012.03 3.016 9.247 9.247 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.595 9.595 0 01-2.03 3.017 9.595 9.595 0 01-3.016 2.032 9.247 9.247 0 01-3.704.748zM10 18.17c2.233 0 4.125-.775 5.675-2.325C17.225 14.295 18 12.403 18 10.17c0-2.233-.775-4.125-2.325-5.675C14.125 2.945 12.233 2.17 10 2.17c-2.233 0-4.125.775-5.675 2.325C2.775 6.045 2 7.937 2 10.17c0 2.233.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325z"
      ></path>
    </svg>
  );
}

export default PlayIcon;
