import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";

interface ITab {
  icon: React.ReactNode;
  label: string;
}

interface ICustomTabs {
  value: number;
  setValue: (value: number) => void;
  tabs: ITab[];
  t: (key: string) => string;
  width: string;
}

const CustomTabs: React.FC<ICustomTabs> = ({ value, setValue, tabs, t, width }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    localStorage.setItem("lastTabValue", newValue.toString());
    setValue(newValue);
  };

  const styleText = {
    fontSize: "14px",
    fontFamily: "var(--cygreRegular), sans-serif",
    textTransform: "none",
    paddingLeft: "9px",
  };

  const tabStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 20px",
    borderRadius: "8px",
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="icon position tabs example"
      variant="scrollable"
      scrollButtons={false}
      sx={{
        "& .MuiTabs-flexContainer": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          sx={{ padding: "8px 4px", maxHeight: "48px" }}
          icon={
            <Box
              sx={{
                ...tabStyles,
                width: width,
                background: value === index ? "#fff" : "none",
              }}
            >
              {tab.icon}
              <Box
                sx={{
                  ...styleText,
                  color: value === index ? "#F4701B" : "#000",
                }}
              >
                {t(tab.label)}
              </Box>
            </Box>
          }
          iconPosition="start"
          label=""
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
