import React from "react";
import TrackSwitch from "./TrackSwitch";
import { AppDataMockup } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
import { useTranslation } from "react-i18next";

interface IModal {
  handleChangeSwith?: (name: keyof AppDataMockup) => void;
}

const ModalAdditional = ({ handleChangeSwith }: IModal) => {
  const { t } = useTranslation();
  const { appData } = useAppStore();
  const handleChange = (name: keyof AppDataMockup) => {
    if (handleChangeSwith) {
      handleChangeSwith(name);
    }
  };
  return (
    <div
      style={{
        paddingTop: "26px",
        border: "1px solid #EEEEEE",
        borderRadius: "10px",
        marginTop: "20px",
        fontFamily: "var(--cygreMedium), sans-serif",
      }}
    >
      <h2
        style={{
          fontFamily: "var(--cygreMedium), sans-serif",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "32px",
          marginLeft: "15px",
          marginBottom: "38px",
        }}
      >
        {t("additionalsettings")}
      </h2>
      <TrackSwitch
        description={t("showcookiesconsent")}
        label={t("cookies")}
        isSelected={appData.filesCookies}
        handleChange={() => {
          handleChange("filesCookies");
        }}
      />
      <TrackSwitch
        description={t("displayPWA")}
        label="Fullscreen"
        isSelected={appData.fullscreen}
        handleChange={() => {
          handleChange("fullscreen");
        }}
      />
      <TrackSwitch
        description={t("ignorecloak")}
        label={t("cloakignoring")}
        isSelected={appData.ignoringCloaca}
        handleChange={() => {
          handleChange("ignoringCloaca");
        }}
      />
    </div>
  );
};

export default ModalAdditional;
