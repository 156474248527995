import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { Link } from "@mui/material";
import useLinks from "./links";

export interface ILink {
  title: string;
  href: string;
  id: string;
}

interface DesktopNavigationProps {
  variant: "mobile" | "desktop";
  setMobileOpen?: (value: boolean) => void;
}

export default function DesktopNavigation({ variant, setMobileOpen }: DesktopNavigationProps) {
  const links = useLinks();
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const sectionsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeSection = links.find((link) => link.id === entry.target.id);
            if (activeSection) {
              setActiveLink(activeSection.id);
            }
          }
        });
      },
      { threshold: 0.7 }
    );

    links.forEach((link) => {
      const section: any = document.getElementById(link.id);
      if (section) {
        observer.observe(section);
        sectionsRef.current.push(section);
      }
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, [links]);

  const handleLinkClick = (id: string) => {
    setActiveLink(id);
    document.querySelector(`#${id}`)?.scrollIntoView({ behavior: "smooth" });
    setMobileOpen && setMobileOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: variant === "mobile" ? "column" : "row",
        gap: variant === "mobile" ? 6 : 0,
        paddingTop: variant === "mobile" ? "65px" : "0",
        alignItems: variant === "mobile" ? "center" : "flex-start",
        flex: 1,
        marginLeft: variant === "mobile" ? "0" : "6vw",
      }}
    >
      {links.map((item: ILink) => (
        <Box
          key={item.id}
          sx={{
            paddingRight: variant === "mobile" ? "0" : "32px",
          }}
        >
          <Link
            sx={{
              color: activeLink === item.id ? "#F4701B" : "#000",
              textTransform: variant === "mobile" ? "uppercase" : "none",
              fontSize: variant === "mobile" ? "23px" : "16px",
              lineHeight: variant === "mobile" ? "28px" : "24px",
              transition: "color 0.3s ease",
              "&:hover": {
                color: "#F4701B",
              },
            }}
            href={`#${item.id}`}
            underline="none"
            onClick={() => handleLinkClick(item.id)}
          >
            {item.title}
          </Link>
        </Box>
      ))}
    </Box>
  );
}
