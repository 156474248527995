import React from "react";

export default function Salability() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="60"
      fill="none"
      viewBox="0 0 76 60"
    >
      <g filter="url(#filter0_d_355_3784)">
        <path
          fill="url(#paint0_linear_355_3784)"
          d="M5.002 19.652c.501-1.082 1.329-1.503 2.526-1.494 5.9.035 11.799.014 17.698.016 1.815 0 2.599.803 2.599 2.661V50.46c0 1.848-.813 2.692-2.67 2.707-2.745.023-5.492.006-8.239.006-3.066 0-6.136-.01-9.203.004-1.214.006-2.195-.349-2.713-1.549V19.652h.002zm20.845 15.999V23.55c0-1.016-.014-1.033-.99-1.033H7.861c-.917 0-1.01.09-1.01.987V47.9c0 .834.133.963.985.963h16.997c.937 0 1.014-.08 1.014-1.049V35.65v.002zm-9.444-15.194c-.705 0-1.41-.005-2.115.002-.193 0-.424.017-.44.267-.021.305.236.322.449.322 1.386.006 2.776.004 4.162 0 .196 0 .425-.019.441-.27.019-.3-.233-.321-.449-.323-.684-.007-1.366-.002-2.05 0l.002.002zm.921 30.074c.006-.526-.447-1.005-.963-1.02-.507-.014-.99.454-1.006.976-.017.544.407.976.965.987.563.01 1-.4 1.004-.943z"
        ></path>
        <path
          fill="url(#paint1_linear_355_3784)"
          d="M43.322 52.94H31.734c-1.042 0-1.146-.108-1.15-1.142 0-.39.017-.778-.004-1.165-.031-.614.26-.854.85-.85 1.524.01 3.046.006 4.57 0 .822-.002.907-.08.905-.876-.006-1.925-.125-1.843 1.88-1.839 2.703.006 5.408 0 8.111.002 1.036 0 1.16.121 1.16 1.14 0 1.696-.118 1.575 1.566 1.575 1.846 0 3.69-.002 5.537 0 .874 0 1.01.144 1.02 1.012.003.43-.013.863.001 1.293.019.577-.274.836-.817.842-.88.009-1.76.007-2.639.007h-9.398l-.004.002z"
        ></path>
        <path
          fill="url(#paint2_linear_355_3784)"
          fillRule="evenodd"
          d="M42.089 4.807H17.102c-1.233 0-1.381.146-1.381 1.363v8.155c0 .961-.106 1.066-1.075 1.074-1.535.013-1.553-.006-1.553-1.532 0-2.61-.002-5.22 0-7.833.002-2.362 1.172-3.64 3.514-3.837.3-.025.6-.017.9-.017 16.358.002 32.716.002 49.074.006.45 0 .9.03 1.35.07 1.831.166 3.062 1.498 3.064 3.352.007 11.91.007 23.82 0 35.73 0 1.908-1.397 3.335-3.285 3.337-11.83.01-23.656.008-35.486.004-1.029 0-1.598-.55-1.62-1.594a143.382 143.382 0 01-.009-3.802l.001-.211c.009-1.323.53-1.843 1.843-1.843h26.79c2.834 0 5.668-.013 8.5.01.555.004.742-.15.74-.73-.015-10.14-.01-20.282-.012-30.423 0-1.03-.262-1.277-1.317-1.277H42.09v-.002zm-.038 36.925c-.73-.006-1.333-.633-1.333-1.381a1.352 1.352 0 011.335-1.367c.747-.008 1.406.658 1.383 1.405-.02.727-.663 1.35-1.385 1.343z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_355_3784"
          width="74.5"
          height="59.5"
          x="0.75"
          y="0.429"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.5"></feOffset>
          <feGaussianBlur stdDeviation="2.125"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.110987 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_355_3784"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_355_3784"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_355_3784"
          x1="5"
          x2="5"
          y1="-14.5"
          y2="84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_355_3784"
          x1="5"
          x2="5"
          y1="-14.5"
          y2="84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_355_3784"
          x1="5"
          x2="5"
          y1="-14.5"
          y2="84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
