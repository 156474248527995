import { useTranslation } from "react-i18next";

export default function useVerticals() {
  const { t } = useTranslation();

  const vericals = [
    { value: "nutra", label: t("nutra"), id: "nutra" },
    { value: "betting", label: t("betting"), id: "betting" },
    { value: "dating", label: t("dating"), id: "dating" },
    { value: "gambling", label: t("gambling"), id: "gambling" },
    { value: "trading", label: t("trading"), id: "trading" },
  ];

  return vericals;
}
