import React from "react";
import IIcon from "interfaces/icon";

function TelegramIcon({ color }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
      <path
        fill={color}
        d="M1.284 13.923a.9.9 0 01-.872-.08C.137 13.668 0 13.41 0 13.07V8.723L7.02 7 0 5.277V.93C0 .591.137.333.412.156a.9.9 0 01.872-.08l14.15 6.06c.378.172.566.46.566.866 0 .405-.188.692-.566.861l-14.15 6.06z"
      ></path>
    </svg>
  );
}

export default TelegramIcon;
