import { Box, Modal } from "@mui/material";
import PhoneMockup from "components/PhoneMockup";
import CloseIcon from "@mui/icons-material/Close";
import PhoneContentPwa from "components/PWATemplate/PhoneContentPwa";

interface IPhoneMockupModal {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  deferredPrompt?: any;
  setDeferredPrompt?: (value: any) => void;
}

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  outline: "none",
};

export default function PhoneMockupModal({ openModal, setOpenModal }: IPhoneMockupModal) {
  const handleClose = () => setOpenModal(false);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
      style={{ backdropFilter: "blur(5px)" }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              cursor: "pointer",
              top: { xs: "-50px", sm: 0 },
              right: { xs: 0, sm: "-50px" },
            }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: "#fff", fontSize: "36px" }} />
          </Box>
          <Box sx={{ maxWidth: "401px", margin: "auto" }}>
            <PhoneMockup>
              <PhoneContentPwa />
            </PhoneMockup>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
