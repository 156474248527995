import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import StopIcon from "assets/icons/StopIcon";
import PlayIcon from "assets/icons/PlayIcon";

interface RenderPWAStatusProps {
  pwaStatus: string;
}

export const RenderPWAStatus = ({ pwaStatus }: RenderPWAStatusProps) => {
  const { t } = useTranslation();

  if (pwaStatus === "active") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <PlayIcon />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#16B364",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("working")}
        </Typography>
      </Box>
    );
  }
  if (pwaStatus === "pause") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StopIcon />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#F4701B",
            display: "flex",
            paddingLeft: "5px",
            alignItems: "center",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("paused")}
        </Typography>
      </Box>
    );
  }
  return null;
};
