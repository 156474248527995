import { create } from "zustand";

export interface ICreative {
  icon: File | null;
  previews: File[] | null;
}

interface CreativesUploadState {
  creativesUpload: ICreative[];
  addCreativeUpload: (creative: ICreative) => void;
}

export const useCreativesUplodStore = create<CreativesUploadState>((set) => ({
  creativesUpload: [],
  addCreativeUpload: (creative) =>
    set((state) => ({ creativesUpload: [...state.creativesUpload, creative] })),
}));
