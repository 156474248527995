import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IToastMessage {
  message: string;
  mode: "error" | "info";
}

const showToast = ({ mode, message }: IToastMessage) => {
  if (mode === "error") {
    // console.log("error");
    toast.error(message, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      transition: Bounce,
    });
  }
  if (mode === "info") {
    // console.log("info");
    toast.info(message, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      transition: Bounce,
    });
  }
};

export default showToast;
