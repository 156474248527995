import { Box } from "@mui/material";
import { ProfileSidebar } from "components/ProfileSidebar";
import { Outlet } from "react-router";
import { AccountMobileFabMenu } from "components/AccountMobileFabMenu";

export const ProfileLayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <ProfileSidebar />
      <Box
        flex="1"
        sx={{ border: { xs: "1px solid #A5A3AE4D", md: "none" }, borderRadius: "8px", padding: { xs: "20px", md: 0 } }}
      >
        <Outlet />
      </Box>
      {/*<AccountMobileFabMenu />*/}
    </Box>
  );
};
