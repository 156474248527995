type DescriptionsType = {
  [key: string]: string;
};

export const descriptions: DescriptionsType = {
  automobilestransport:
    "Откройте для себя новейшие модели автомобилей и транспортных средств, включая электрические и гибридные автомобили. Узнайте о передовых технологиях и получите советы по уходу за автомобилем.",

  librariesdemo:
    "Обширная коллекция библиотек и примеров кода для вашего проекта. Найдите готовые компоненты для быстрого старта и ускорьте процесс разработки.",

  business:
    "Полезные советы и ресурсы для успешного ведения бизнеса. Откройте для себя инструменты для анализа, маркетинга и оптимизации процессов, которые помогут в развитии вашего бизнеса.",

  videoplayerseditors:
    "Научитесь создавать и редактировать видео с помощью лучших инструментов. Откройте для себя возможности современных программ для монтажа и воспроизведения.",

  artdesign:
    "Ресурсы для художников и дизайнеров. Узнайте о методах работы с цветом, композицией, анимацией и новейших трендах в мире искусства и дизайна.",

  fooddrink:
    "Вкусные рецепты и советы по приготовлению блюд. Исследуйте кухни мира, изучайте полезные ингредиенты и находите вдохновение для здорового питания.",

  games:
    "Наслаждайтесь популярными играми для всех возрастов и предпочтений. Откройте для себя новые жанры и стратегии, которые позволят вам полностью погрузиться в игровой мир.",

  tools:
    "Найдите качественные инструменты для ремонта дома и рабочих задач. Рекомендации по выбору и использованию помогут в работе любого уровня сложности.",

  househome:
    "Создайте уютный и комфортный дом с помощью идей по дизайну интерьера, выбора мебели и организации пространства.",

  healthfitness:
    "Поддерживайте здоровье и хорошую физическую форму с помощью наших рекомендаций по фитнесу и правильному питанию.",

  dating:
    "Знакомьтесь с новыми людьми и начинайте успешные отношения. Советы по созданию привлекательного профиля и интересному общению.",

  mapsnavigation: "Планируйте поездки и находите нужные места с помощью современных карт и навигационных приложений.",

  books:
    "Лучшие книги для саморазвития и отдыха. Читайте классику, современную литературу и находите новые идеи и знания.",

  comics:
    "Популярные комиксы и графические романы для всех возрастов. Захватывающие истории с любимыми героями ждут вас.",

  beauty: "Советы по уходу за кожей, макияжу и выбору косметики. Узнайте, как поддерживать красоту и здоровье кожи.",

  parenting:
    "Полезные рекомендации по воспитанию детей и поддержке семьи. Советы для родителей помогут вам создать гармоничную обстановку.",

  medical:
    "Медицинские советы по поддержанию здоровья и профилактике. Узнайте о методах лечения и укрепления иммунитета.",

  events:
    "Анонсы событий и мероприятий поблизости. Узнайте о концертах, выставках и других мероприятиях, которые будут интересны.",

  music:
    "Откройте для себя новые музыкальные жанры и исполнителей. Слушайте подборки музыки для любого настроения и события.",

  newsmagazines: "Последние новости и аналитика. Читайте о ключевых событиях, мнениях экспертов и актуальных темах.",

  education:
    "Ресурсы для обучения и саморазвития. Откройте для себя курсы и учебные материалы для повышения квалификации.",

  lifestyle:
    "Советы по улучшению образа жизни, включая финансовое планирование, здоровые привычки и баланс между работой и личной жизнью.",

  communication:
    "Приложения и советы для общения и поддержки связи с близкими. Узнайте, как эффективно взаимодействовать на расстоянии.",

  personalization:
    "Настройте свои устройства и приложения под свой стиль. Уникальные темы, обои и настройки помогут сделать устройство своим.",

  weather: "Получайте точный прогноз погоды для вашего города и планируйте свои мероприятия в зависимости от условий.",

  shopping: "Ищите выгодные предложения и новинки на рынке. Рекомендации по разумным покупкам помогут вам сэкономить.",

  travelLocal:
    "Руководства по путешествиям и местным достопримечательностям. Узнайте о лучших направлениях и интересных местах.",

  work: "Инструменты и советы для повышения продуктивности и достижения профессиональных целей.",

  entertainment: "Подборки развлечений для свободного времени. Идеи для веселого досуга с друзьями и семьей.",

  social:
    "Оставайтесь на связи с друзьями и близкими через социальные сети. Узнайте, как поддерживать контакты и делиться новостями.",

  sports:
    "Новости и результаты для любителей спорта. Следите за успехами любимых команд и получайте советы по тренировкам.",

  finance:
    "Советы по управлению личными финансами и инвестированию. Научитесь планировать и достигать финансовых целей.",

  photography:
    "Лучшие практики и техники для создания великолепных снимков. Узнайте, как улучшить свои навыки фотографии.",
};

export const categoryKeys = {
  en: {
    "Automobiles & Transport": "automobilestransport",
    "Libraries & Demo": "librariesdemo",
    Business: "business",
    "Video Players & Editors": "videoplayerseditors",
    "Art & Design": "artdesign",
    "Food & Drink": "fooddrink",
    Games: "games",
    Tools: "tools",
    "House & Home": "househome",
    "Health & Fitness": "healthfitness",
    Dating: "dating",
    "Maps & Navigation": "mapsnavigation",
    Books: "books",
    Comics: "comics",
    Beauty: "beauty",
    Parenting: "parenting",
    Medical: "medical",
    Events: "events",
    Music: "music",
    "News & Magazines": "newsmagazines",
    Education: "education",
    Lifestyle: "lifestyle",
    Communication: "communication",
    Personalization: "personalization",
    Weather: "weather",
    Shopping: "shopping",
    "Travel & Local": "travellocal",
    Work: "work",
    Entertainment: "entertainment",
    Social: "social",
    Sports: "sports",
    Finance: "finance",
    Photography: "photography",
  },
  ru: {
    "Автомобили и транспорт": "automobilestransport",
    "Библиотеки и демонстрации": "librariesdemo",
    Бизнес: "business",
    "Видеоплееры и редакторы": "videoplayerseditors",
    "Искусство и дизайн": "artdesign",
    "Еда и напитки": "fooddrink",
    Игры: "games",
    Инструменты: "tools",
    "Дом и быт": "househome",
    "Здоровье и фитнес": "healthfitness",
    Знакомства: "dating",
    "Карты и навигация": "mapsnavigation",
    Книги: "books",
    Комиксы: "comics",
    Красота: "beauty",
    Родительство: "parenting",
    Медицина: "medical",
    События: "events",
    Музыка: "music",
    "Новости и журналы": "newsmagazines",
    Образование: "education",
    "Образ жизни": "lifestyle",
    Коммуникация: "communication",
    Персонализация: "personalization",
    Погода: "weather",
    Покупки: "shopping",
    "Путешествия и местные события": "travellocal",
    Работа: "work",
    Развлечения: "entertainment",
    "Социальные сети": "social",
    Спорт: "sports",
    Финансы: "finance",
    Фотография: "photography",
  },
  uk: {
    "Автомобілі та транспорт": "automobilestransport",
    "Бібліотеки та демонстрації": "librariesdemo",
    Бізнес: "business",
    "Відеоплеєри та редактори": "videoplayerseditors",
    "Мистецтво та дизайн": "artdesign",
    "Їжа та напої": "fooddrink",
    Ігри: "games",
    Інструменти: "tools",
    "Дім та побут": "househome",
    "Здоров'я та фітнес": "healthfitness",
    Знайомства: "dating",
    "Карти та навігація": "mapsnavigation",
    Книги: "books",
    Комікси: "comics",
    Краса: "beauty",
    Батьківство: "parenting",
    Медицина: "medical",
    Події: "events",
    Музика: "music",
    "Новини та журнали": "newsmagazines",
    Освіта: "education",
    "Стиль життя": "lifestyle",
    Комунікація: "communication",
    Персоналізація: "personalization",
    Погода: "weather",
    Покупки: "shopping",
    "Подорожі та місцеві події": "travellocal",
    Робота: "work",
    Розваги: "entertainment",
    "Соціальні мережі": "social",
    Спорт: "sports",
    Фінанси: "finance",
    Фотографія: "photography",
  },
};
