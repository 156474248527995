import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import StopIcon from "assets/icons/StopIcon";
import PlayIcon from "assets/icons/PlayIcon";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import useIsTablet from "hooks/useIsTablet";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "components/SearchBar";
import Filter, { IFilterState } from "components/Filter";
import { IPwaComponent } from "interfaces/pwas";
import "./menu.css";
import { PwaListTable } from "components/PwaList/PWATableView";
import { PWACardsView } from "components/PwaList/PWACardsView";

export interface IKeyVal {
  [key: string]: string;
}

const PwaPerformance = ({ pwas, getPwas }: IPwaComponent) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const lastView = localStorage.getItem("lastPWAPageView") as "table" | "grid";
  const [view, setView] = useState<"table" | "grid">(lastView || "table");
  const [filter, setFilter] = useState<IFilterState>({ status: [], country: [], categoryVertical: [] });
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredItems = pwas?.filter((item: any) => {
    const matchesSearch = item.appName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilters = Object.keys(filter).every((filterType) => {
      return filter[filterType].length === 0 || filter[filterType].includes(item[filterType]);
    });
    return matchesSearch && matchesFilters; // Учитываем и фильтры, и поиск
  });

  interface SwitchButtonProps {
    active: boolean;
  }

  const SwitchButton = styled(Button)<SwitchButtonProps>(({ theme, active }) => {
    return {
      borderRadius: 10,
      backgroundColor: active ? "#ffffff" : "transparent",
      padding: "0px 5px",
      minWidth: "auto",
      color: "#F4701B",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    };
  });

  const ViewSwitch = ({ view, toggleView }: { view: "table" | "grid"; toggleView: () => void }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#F6F7FB",
          padding: "5px",
          borderRadius: "10px",
          width: "74px",
          height: "40px",
        }}
      >
        <SwitchButton
          onClick={() => {
            return view !== "grid" && toggleView();
          }}
          active={view === "grid"}
        >
          {view === "grid" ? <ViewModuleIcon /> : <ViewModuleOutlinedIcon sx={{ color: "#6D6777" }} />}
        </SwitchButton>
        <SwitchButton
          onClick={() => {
            return view !== "table" && toggleView();
          }}
          active={view === "table"}
        >
          {view === "table" ? <MenuIcon /> : <MenuIcon sx={{ color: "#6D6777" }} />}
        </SwitchButton>
      </div>
    );
  };

  const toggleView = () => {
    setView(view === "table" ? "grid" : "table");
    localStorage.setItem("lastPWAPageView", view === "table" ? "grid" : "table");
  };

  return (
    <Box>
      <Box sx={{ width: { sm: "auto" } }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            alignItems: "normal",
            marginBottom: isMobile ? "15px" : "0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "normal",
              width: "100%",
              marginBottom: "16px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <SearchBar onSearch={handleSearch} placeholder={t("searchname")} />
            <Filter filter={filter} setFilter={setFilter} />
          </Box>
          {isTablet ? null : <ViewSwitch view={view} toggleView={toggleView} />}
        </Grid>
        {view === "table" ? (
          <PwaListTable filteredItems={filteredItems} getPwas={getPwas} />
        ) : (
          <PWACardsView filteredItems={filteredItems} getPwas={getPwas} />
        )}
      </Box>
    </Box>
  );
};

export default PwaPerformance;
