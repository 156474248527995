import React from "react";

function MetaTags() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none" viewBox="0 0 20 12">
      <path
        fill="#2E263D"
        d="M2.47 6.015l4.057 4.058c.138.138.21.313.212.522a.708.708 0 01-.212.532.718.718 0 01-.527.217.718.718 0 01-.527-.217L.979 6.633A.832.832 0 01.723 6a.832.832 0 01.256-.633L5.473.873a.742.742 0 011.07 0 .735.735 0 01.232.535c0 .2-.078.38-.233.534L2.47 6.015zm15.06-.03l-4.057-4.058a.726.726 0 01-.213-.522.708.708 0 01.213-.532A.718.718 0 0114 .656c.206 0 .382.072.527.217l4.494 4.494a.832.832 0 01.256.633.832.832 0 01-.256.633l-4.494 4.494a.705.705 0 01-.522.22.742.742 0 01-.532-.236.735.735 0 01-.232-.534c0-.201.077-.38.232-.535l4.058-4.058z"
      ></path>
    </svg>
  );
}

export default MetaTags;
