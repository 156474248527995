import { useTranslation } from "react-i18next";

export default function useCountriesForSecondRegion() {
  const { t } = useTranslation();
  return [
    t("andorra"),
    t("bahamas"),
    t("belarus"),
    t("bulgaria"),
    t("bolivia"),
    t("bosnia_and_herzegovina"),
    t("brazil"),
    t("brunei_darussalam"),
    t("vanuatu"),
    t("hungary"),
    t("guyana"),
    t("hong_kong"),
    t("greece"),
    t("dominican_republic"),
    t("egypt"),
    t("israel"),
    t("indonesia"),
    t("iceland"),
    t("colombia"),
    t("south_korea"),
    t("costa_rica"),
    t("latvia"),
    t("lithuania"),
    t("macau"),
    t("malaysia"),
    t("malta"),
    t("morocco"),
    t("mexico"),
    t("nepal"),
    t("united_arab_emirates"),
    t("oman"),
    t("panama"),
    t("paraguay"),
    t("peru"),
    t("poland"),
    t("portugal"),
    t("puerto_rico"),
    t("russia"),
    t("romania"),
    t("saudi_arabia"),
    t("serbia"),
    t("singapore"),
    t("slovakia"),
    t("slovenia"),
    t("thailand"),
    t("turkey"),
    t("ukraine"),
    t("uruguay"),
    t("fiji"),
    t("philippines"),
    t("croatia"),
    t("montenegro"),
    t("czech_republic"),
    t("chile"),
    t("ecuador"),
    t("estonia"),
    t("japan"),
    t("south_georgia_and_the_south_sandwich_islands"),
  ];
}
