import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import CustomButton from "components/Button";
import Description from "./Description";
import MoreInfo from "./MoreInfo";
import ForDesktop from "./DesktopInfo";
import Rating from "./Rating";
import Comments from "./Comments";
import useSetupStore from "store/setupSettingsStore";

interface IMainTab {
  appData: AppDataMockup;
  languages: any;
  setValue: (value: number) => void;
}

export default function MainTab({ languages, setValue }: IMainTab) {
  const { t } = useTranslation();
  const setStepStatus = useSetupStore((state) => state.setStepStatus);

  useEffect(() => {
    setStepStatus("main", "inprogress");
    return () => {
      setStepStatus("main", "ready");
    };
  }, [setStepStatus]);

  function handleNext() {
    setValue(1);
  }

  return (
    <Box>
      <Box>
        <Description languages={languages} />
      </Box>
      <Box sx={{ paddingTop: "16px" }}>
        <Rating />
      </Box>
      <Box sx={{ paddingTop: "16px" }}>
        <Comments languages={languages} />
      </Box>
      <Box sx={{ paddingTop: "16px" }}>
        <MoreInfo />
      </Box>
      <Box sx={{ paddingTop: "16px" }}>
        <ForDesktop />
      </Box>
      <Box sx={{ display: "flex", pt: 2 }}>
        <Box sx={{ width: "160px" }}>
          <CustomButton
            textButton={t("next")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleNext}
          />
        </Box>
      </Box>
    </Box>
  );
}
