import React from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";

export interface CountryOption {
  value: string;
  label: string;
  flag?: string;
}

interface CustomSelectProps {
  options: CountryOption[];
  placeholder: string;
  title: string;
  value: MultiValue<CountryOption>;
  onChange: (value: MultiValue<CountryOption>, actionMeta: ActionMeta<CountryOption>) => void;
  noBorder?: boolean;
  backgroundColor?: string;
}

const MultiSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder,
  title,
  value,
  onChange,
  noBorder = false,
  backgroundColor = "#F6F7FB",
}) => {
  const { t } = useTranslation();

  const customSingleValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.data.flag && (
        <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      )}
      {props.data.label}
    </div>
  );

  const customMultiValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center", backgroundColor, padding: "3px" }}>
      {props.data.flag && (
        <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      )}
      {props.data.label}
      <span onClick={() => props.removeProps.onClick()} style={{ marginLeft: "10px", cursor: "pointer" }}>
        ×
      </span>
    </div>
  );

  return (
    <div style={{ width: "100%", marginRight: "10px" }}>
      <label
        htmlFor="custom-select"
        style={{ display: "block", marginTop: "-6px", fontSize: "12px", paddingBottom: "4px" }}
      >
        {title}
      </label>
      <Select
        options={options}
        isMulti
        id="custom-select"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        components={{ SingleValue: customSingleValue, MultiValue: customMultiValue }}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
            backgroundColor,
            borderRadius: "8px",
          }),
          control: (base) => ({
            ...base,
            backgroundColor,
            border: noBorder ? "none" : "1px solid #ccc",
            fontSize: "14px",
            fontFamily: "var(--cygreRegular), sans-serif",
            borderRadius: "8px",
          }),
          multiValue: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            padding: "5px",
            backgroundColor,
            border: "none",
            borderRadius: "4px",
            margin: "2px",
            flexShrink: 0,
          }),
          multiValueLabel: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            backgroundColor,
          }),
          multiValueRemove: (base) => ({
            ...base,
            cursor: "pointer",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            color: "#6D6777",
          }),
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }),
        }}
      />
    </div>
  );
};

export default MultiSelect;
