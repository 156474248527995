import framesBg from "./frames/frames-bg.png";
// Step 1
import s1f1 from "./frames/1/1.jpg";
import s1f2 from "./frames/1/2.jpg";
import s1f3 from "./frames/1/3.jpg";
import s1f4 from "./frames/1/4.jpg";
import s1f5 from "./frames/1/5.jpg";
import s1f6 from "./frames/1/6.jpg";
import s1f7 from "./frames/1/7.jpg";
import s1f8 from "./frames/1/8.jpg";
import s1f9 from "./frames/1/9.jpg";
import s1f10 from "./frames/1/10.jpg";
// Step 2
import s2f1 from "./frames/2/1.jpg";
import s2f2 from "./frames/2/2.jpg";
import s2f3 from "./frames/2/3.jpg";
import s2f4 from "./frames/2/4.jpg";
import s2f5 from "./frames/2/5.jpg";
import s2f6 from "./frames/2/6.jpg";
// Step 3
import s3f1 from "./frames/3/1.jpg";
import s3f2 from "./frames/3/2.jpg";
import s3f3 from "./frames/3/3.jpg";
import s3f4 from "./frames/3/4.jpg";
import s3f5 from "./frames/3/5.jpg";
// Step 4
import s4f1 from "./frames/4/1.jpg";
import s4f2 from "./frames/4/2.jpg";
import s4f3 from "./frames/4/3.jpg";
import s4f4 from "./frames/4/4.jpg";
import s4f5 from "./frames/4/5.jpg";
// Step 5
import s5f1 from "assets/duckHeroShekel.png";

import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

const frames1 = [s1f1, s1f2, s1f3, s1f4, s1f5, s1f6, s1f7, s1f8, s1f9, s1f10];
const frames2 = [s2f1, s2f2, s2f3, s2f4, s2f5, s2f6];
const frames3 = [s3f1, s3f2, s3f3, s3f4, s3f5];
const frames4 = [s4f1, s4f2, s4f3, s4f4, s4f5];

const framesLib: { [key: number]: any[] } = {
  0: frames1,
  1: frames2,
  2: frames3,
  3: frames4,
};

interface ICreationStepsAnimation {
  step: number;
}

const CreationStepsAnimation = ({ step }: ICreationStepsAnimation) => {
  const [currentFrame, setCurrentFrame] = useState(0);

  const lastInterval = useRef<NodeJS.Timeout | null>(null);
  const frames = framesLib[step];

  useEffect(() => {
    if (step === 4) {
      return;
    }
    if (lastInterval.current) {
      clearInterval(lastInterval.current as NodeJS.Timeout);
      setCurrentFrame(0);
    }

    const framesLengthMin = frames.length <= 5 ? frames.length + 1 : frames.length;
    const frameInterval = 3100 / framesLengthMin;

    lastInterval.current = setInterval(() => {
      setCurrentFrame((prevFrame) => {
        const nextFrame = (prevFrame + 1) % frames.length;
        return nextFrame ? (prevFrame + 1) % frames.length : prevFrame;
      });
    }, frameInterval);

    return () => clearInterval(lastInterval.current as NodeJS.Timeout);
  }, [step, frames]);

  return (
    <>
      <Box
        component="img"
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
        src={framesBg}
        alt={`framesBg`}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          maxHeight: "80%",
          maxWidth: "70%",
          display: step === 0 ? "flex" : "none",
          // display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {frames1.map((frame, index) => (
          <Box
            key={frame}
            component="img"
            width="302px"
            height="640px"
            sx={{
              width: "auto",
              height: "100%",
              position: "absolute",
              objectFit: "contain",
              opacity: currentFrame === index ? 1 : 0,
            }}
            src={frame}
            alt={`step1-${index}`}
            loading="lazy"
          />
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          maxHeight: "80%",
          maxWidth: "70%",
          display: step === 1 ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {frames2.map((frame, index) => (
          <Box
            key={frame}
            component="img"
            width="302px"
            height="640px"
            sx={{
              width: "auto",
              height: "100%",
              position: "absolute",
              objectFit: "contain",
              opacity: currentFrame === index ? 1 : 0,
            }}
            src={frame}
            alt={`step1-${index}`}
            loading="lazy"
          />
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          // opacity: step === 2 ? 1 : 0,
          position: "relative",
          maxHeight: "80%",
          maxWidth: "70%",
          display: step === 2 ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {frames3.map((frame, index) => (
          <Box
            key={frame}
            component="img"
            width="520px"
            height="265px"
            sx={{
              width: "100%",
              height: "auto",
              position: "absolute",
              objectFit: "contain",
              opacity: currentFrame === index ? 1 : 0,
            }}
            src={frame}
            alt={`step1-${index}`}
            loading="lazy"
          />
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          maxHeight: "80%",
          maxWidth: "70%",
          display: step === 3 ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {frames4.map((frame, index) => (
          <Box
            key={frame}
            component="img"
            width="520px"
            height="265px"
            sx={{
              width: "100%",
              height: "auto%",
              position: "absolute",
              objectFit: "contain",
              opacity: currentFrame === index ? 1 : 0,
            }}
            src={frame}
            alt={`step1-${index}`}
            loading="lazy"
          />
        ))}
      </Box>

      <Box
        component="img"
        sx={{
          width: "60%",
          position: "relative",
          display: step === 4 ? "block" : "none",
        }}
        src={s5f1}
        alt={`frame ${currentFrame + 1}`}
        loading="lazy"
      />
    </>
  );
};

export default CreationStepsAnimation;
