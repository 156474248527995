import React from "react";
import IIcon from "interfaces/icon";

function Upload({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={color}
        d="M2.308 15.17c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 01.5 13.362v-1.961c0-.213.072-.391.216-.535a.726.726 0 01.534-.215c.213 0 .391.072.534.215a.725.725 0 01.216.535v1.961c0 .077.032.148.096.212a.293.293 0 00.212.096h11.384a.294.294 0 00.212-.096.294.294 0 00.096-.212v-1.961c0-.213.072-.391.216-.535a.726.726 0 01.534-.215c.213 0 .391.072.534.215a.725.725 0 01.216.535v1.961c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H2.308zM7.25 3.058L5.327 4.981a.715.715 0 01-.53.22.751.751 0 01-.54-.235.783.783 0 01-.224-.527.7.7 0 01.225-.527l3.11-3.11A.827.827 0 018 .548a.827.827 0 01.633.256l3.11 3.11a.706.706 0 01.22.521.769.769 0 01-.22.532.765.765 0 01-.535.24.708.708 0 01-.535-.225L8.75 3.058v7.65a.725.725 0 01-.216.535.726.726 0 01-.534.215.726.726 0 01-.535-.215.725.725 0 01-.215-.535v-7.65z"
      ></path>
    </svg>
  );
}

export default Upload;
