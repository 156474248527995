import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import StatisticWidget from "components/StatisticWidget";
import AccountsTable from "components/AccountsTable";
import MyBarChart from "components/Charts";
import StatsMap from "components/StatsMap";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { fetchCountries } from "utils/fetchCountries";

export interface IPwaStatisticsItem {
  pwaId: string;
  installCount: number;
  totalAmount: number;
  icon: string;
  name: string;
  country: string;
  updatedAt: string;
}

const Dashboard = () => {
  const token = getToken();
  const [statistics, setStatistics] = useState<IPwaStatisticsItem[]>([]);
  const [groupedStatistics, setGroupedStatistics] = useState<any[]>([]);
  const [totalInstallCount, setTotalInstallCount] = useState<number | undefined>(undefined);
  const [countryFlags, setCountryFlags] = useState<{ name: string; flag: string }[]>([]);
  const { triggerFetch } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const loadCountryFlags = async () => {
      const countries = await fetchCountries();
      setCountryFlags(countries);
    };

    loadCountryFlags();
  }, []);

  const groupStatisticsByCountry = (data: IPwaStatisticsItem[]) => {
    const groupedData = data.reduce((acc: any, item: IPwaStatisticsItem) => {
      const countryFlag = countryFlags.find((country) => country.name === item.country);

      let countryGroup = acc.find((group: any) => group.country === item.country);

      if (countryGroup) {
        countryGroup.countries.push({
          name: item.name,
          flag: countryFlag ? countryFlag.flag : "",
          icon: item.icon,
          installCount: item.installCount,
        });

        countryGroup.pwaCount += 1;
      } else {
        acc.push({
          flag: countryFlag ? countryFlag.flag : "",
          country: item.country,
          pwaCount: 1,
          countries: [
            {
              name: item.name,
              flag: countryFlag ? countryFlag.flag : "",
              icon: item.icon,
              installCount: item.installCount,
            },
          ],
        });
      }

      return acc;
    }, []);

    return groupedData;
  };

  useEffect(() => {
    async function getAccountsData() {
      const res = await triggerFetch();

      const totalInstallCount = res?.data.items.reduce(
        (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
        0
      );
      setTotalInstallCount(totalInstallCount);
      setStatistics(res?.data.items || []);

      if (countryFlags.length > 0) {
        const groupedData = groupStatisticsByCountry(res?.data.items || []);
        setGroupedStatistics(groupedData);
      }
    }

    void getAccountsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryFlags]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          overflow: "auto",
          padding: "2px 2px",
        }}
      >
        <StatisticWidget totalInstallCount={totalInstallCount} isPush={false} />
      </Box>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: { xs: "10px 0px", lg: "20px 27px" },
        }}
      >
        <StatsMap groupedStatistics={groupedStatistics} statistics={statistics} />
      </Box>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          padding: { xs: "10px 0px", lg: "20px 27px" },
          width: "100%",
          margin: "24px 0",
        }}
      >
        <MyBarChart />
      </Box>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          maxWidth: "750px",
          padding: { xs: "10px 0px", lg: "20px 27px" },
        }}
      >
        <AccountsTable statistics={statistics} />
      </Box>
    </div>
  );
};

export default Dashboard;
