import { Navigate, Outlet } from "react-router";
import { FC, ReactNode } from "react";

interface iProtectedRouteHOCProps {
  redirectPath?: string;
  children?: ReactNode;
}

export const ProtectedRouteHOC: FC<iProtectedRouteHOCProps> = ({ redirectPath = "/landing", children }) => {
  const sessionToken = localStorage.getItem("authToken") || null;

  if (!sessionToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
