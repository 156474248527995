import CustomTable, { IColumn } from "components/CustomTable";
import { Box } from "@mui/material";

export interface IAccount {
  pwaId: string;
  installCount: number;
  totalAmount: number;
  icon: string;
  name: string;
  country: string;
  updatedAt: string;
}

export interface IMapCountryTable {
  rows: IAccount[];
}

export default function MapCountryTable({ rows }: IMapCountryTable) {
  const filteredRows = rows.filter((row) => row.country.trim() !== "");

const columns: IColumn[] = [
  {
    id: "country",
    label: "country",
    align: "center",
  },
  {
    id: "clicks",
    label: "clicks",
    align: "center",
  },
  {
    id: "uniques",
    label: "uniques",
    align: "center",
  },
  {
    id: "installCount",
    label: "installs",
    align: "center",
  },
];

return <CustomTable columns={columns} rows={filteredRows} />;
}
