import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import { ChangeEvent } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";

export default function ForDesktop() {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const { errors, clearError } = useErrorAppStore();

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontFamily: "var(--cygreRegular), sans-serif" }}>{t("forpc")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <CustomInput
            margin="normal"
            required
            fullWidth
            id="descriptionPC"
            placeholder={t("description")}
            multiline={true}
            rows={4}
            name="descriptionPC"
            label={t("description")}
            value={appData.descriptionPC}
            onChange={(e) => handleInputChange(e, "descriptionPC")}
            customBgColor="#F6F7F9"
            error={!!errors.descriptionPC}
            helperText={errors.descriptionPC}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
