import * as React from "react";
import Box from "@mui/material/Box";
import Logo from "components/Logo";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import useLinks from "components/Navigation/links";
import { ILink } from "components/Navigation";

const textStyles = {
  fontSize: { xs: "14px", md: "18px" },
  fontWeight: "22px",
  color: "#000",
};

function Copyright() {
  return (
    <Typography sx={{ ...textStyles }}>Copyright {new Date().getFullYear()} – Ducksay All Rights Reserved</Typography>
  );
}

export default function Footer() {
  const links = useLinks();
  return (
    <Box
      sx={{
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          paddingBottom: "80px",
          position: "relative",
          maxWidth: "1240px",
          margin: "0 auto",
          padding: "0 10px",
        }}
      >
        <Logo />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: { xs: "45px", sm: "85px" },
          }}
        >
          {links.map((item: ILink) => (
            <Box
              sx={{
                marginBottom: "32px",
              }}
              key={item.id}
            >
              <Link
                sx={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "#F4701B",
                  },
                }}
                href={item.href}
                underline="none"
              >
                {item.title}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F6F7F9",
          padding: "30px 0",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            maxWidth: "1200px",
            margin: "0 auto",
            padding: { xs: "0 15px", md: "0" },
            width: "100%",
          }}
        >
          <Copyright />
          {/* TODO: Uncomment when pages ready */}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: "flex",*/}
          {/*    alignItems: "center",*/}
          {/*    flexDirection: "row",*/}
          {/*    marginTop: { xs: "20px", md: "0" },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Link*/}
          {/*    sx={{*/}
          {/*      ...textStyles,*/}
          {/*    }}*/}
          {/*    underline="none"*/}
          {/*    href="#"*/}
          {/*  >*/}
          {/*    Privacy Policy*/}
          {/*  </Link>*/}
          {/*  <Divider sx={{ margin: "0 8px", borderColor: "#000" }} orientation="vertical" flexItem />*/}
          {/*  <Link*/}
          {/*    sx={{*/}
          {/*      ...textStyles,*/}
          {/*      color: "text.secondary",*/}
          {/*    }}*/}
          {/*    underline="none"*/}
          {/*    href="#"*/}
          {/*  >*/}
          {/*    Terms of Service*/}
          {/*  </Link>*/}
          {/*</Box>*/}
        </Box>
      </Box>
    </Box>
  );
}
