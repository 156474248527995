import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IAppData, INotification } from "interfaces/push";

import { convertTimestampToDate } from "utils/formateDate";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import Success from "assets/icons/Success";
import Stopped from "assets/icons/Stopped";
import CalendarClock from "assets/icons/CalendarClock";
import PushButtons from "./PushButtons";

const styleText = {
  fontSize: "14px",
  fontFamily: "var(--cygreMedium), sans-serif",
};

const NotificationCard = ({
  notification,
  appData,
  icon,
  countryFlags,
  lang,
  onCardClick,
  onDeletePush,
}: {
  notification: INotification;
  appData: IAppData;
  icon: any;
  onDeletePush: (appData: IAppData, notification: INotification) => void;
  lang: string;
  countryFlags: string[];

  onCardClick: (notification: INotification) => void;
}) => {
  const { t } = useTranslation();
  const now = Math.floor(Date.now() / 1000);

  let status;

  if (notification.canceled) {
    status = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Stopped />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#F4701B",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("stopped")}
        </Typography>
      </Box>
    );
  } else if (notification.completed_at && notification.completed_at <= now) {
    status = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Success width={20} height={20} color={"#16B364"} />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#16B364",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("completed")}
        </Typography>
      </Box>
    );
  } else if (!notification.completed_at && notification.send_after > now) {
    status = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CalendarClock />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#1B84FF",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("scheduled")}
        </Typography>
      </Box>
    );
  }

  return (
    <Card
      tabIndex={-1}
      sx={{
        // margin: "16px 16px 0 0",
        borderRadius: "11px",
        boxShadow: 0,
        padding: 0,
        // minWidth: "350px",
        border: "1px solid #A5A3AE4D",
      }}
    >
      <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
        <Box sx={{ borderBottom: "1px solid #A5A3AE4D" }}>
          <Box sx={{ padding: "16px", display: "flex", alignItems: "flex-start" }}>
            <img style={{ width: "56px", height: "56px", borderRadius: "50%" }} src={icon} alt={appData.name} />
            <Box sx={{ paddingLeft: "12px" }}>
              <Typography sx={{ ...styleText }}>{notification.name || "-"}</Typography>
              <Typography sx={{ ...styleText, color: "#6D6777" }}>{status}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ borderBottom: "1px solid #A5A3AE4D" }}>
          <Box sx={{ padding: "16px", display: "flex", alignItems: "flex-start" }}>
            <Typography sx={{ ...styleText }}>PWA: {appData.name}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            borderBottom: "1px solid #A5A3AE4D",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingRight: "8px" }}>
            <BootstrapTooltip title={t("description_successful")} placement="top">
              <Box
                sx={{
                  display: "flex",
                  paddingBottom: "16px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("successful")}:</Typography>
                <Typography sx={{ ...styleText }}>{notification.successful || 0}</Typography>
              </Box>
            </BootstrapTooltip>
            <BootstrapTooltip title={t("description_failed")} placement="top">
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("failed")}:</Typography>
                <Typography sx={{ ...styleText }}>{notification.failed || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingRight: "8px" }}>
            <BootstrapTooltip title={t("description_remaining")} placement="top">
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("remaining")}:</Typography>
                <Typography sx={{ ...styleText }}>{notification.remaining || 0}</Typography>
              </Box>
            </BootstrapTooltip>
            <BootstrapTooltip title={t("description_errored")} placement="top">
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("errored")}:</Typography>
                <Typography sx={{ ...styleText }}>{notification.errored || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <BootstrapTooltip title={t("description_converted")} placement="top">
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("converted")}:</Typography>
                <Typography sx={{ ...styleText }}>{notification.converted || 0}</Typography>
              </Box>
            </BootstrapTooltip>
            <BootstrapTooltip title={t("description_received")} placement="top">
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("received")}:</Typography>
                <Typography sx={{ ...styleText }}>{notification.received || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            padding: "16px  16px 0 16px",
            borderBottom: "1px solid #A5A3AE4D",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ...styleText }}>{t("datecreatepush")}:</Typography>
            <Typography sx={{ ...styleText, paddingLeft: "4px" }}>
              {convertTimestampToDate(notification.queued_at)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ...styleText }}>{t("countries")}:</Typography>
            <Box sx={{ display: "flex", gap: "8px", padding: "16px" }}>
              {countryFlags.map((flag, index) => (
                <img key={index} src={flag} alt="flag" style={{ width: "24px", height: "24px" }} />
              ))}
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "16px" }}>
          <PushButtons
            notification={notification}
            appData={appData}
            onDeletePush={() => onDeletePush(appData, notification)}
            onCardClick={() => onCardClick(notification)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
