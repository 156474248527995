import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTranslation } from "react-i18next";
import FilterCharts from "./FilterCharts";
import SelectPeriod from "./SelectPeriod";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import Preloader from "components/GlobalPreloader/Preloader";

interface DataPoint {
  label: string;
  color: string;
  values: any;
  id: string;
}

interface TransactionItem {
  action: string;
  amount: number;
  createdAt: string;
  pwaId: string;
  type: string;
  updatedAt: string;
  userId: string;
  __v: number;
  _id: string;
}

interface ResultItem {
  value: number;
  createdAt: string;
}

const generateDaysOfMonth = (year: number, month: number) => {
  const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
  const monthName = new Date(year, month).toLocaleString("en-US", { month: "short" });
  return Array.from({ length: totalDaysInMonth }, (_, i) => `${i + 1} \n ${monthName}`);
};

const generateHoursOfDay = () => Array.from({ length: 24 }, (_, i) => `${i}:00`);

const generateMonthsOfYear = () => Array.from({ length: 12 }, (_, i) => `${i + 1}`);

function MyBarChart() {
  const token = getToken();
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  const [selectedPeriod, setSelectedPeriod] = useState<string>("1_month_ago");
  const [customPeriod, setCustomPeriod] = useState<any>("");
  const [transaction, setTransaction] = useState<ResultItem[]>([]);
  const [reportData, setReportData] = useState([]);
  const [chartData, setChartData] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const generateCustomPeriod = () => {
    if (!customPeriod || customPeriod.length === 0) return [];

    const startDate = new Date(customPeriod[0]);

    if (customPeriod[1] == null) {
      return generateHoursOfDay();
    }

    const endDate = new Date(customPeriod[1]);
    const result = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const formattedDate = currentDate.getDate().toString().padStart(2, "0");
      result.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  };

  const { triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          timezone: "Europe/Madrid",
        },
      },
    },
  });

  const { triggerFetch } = useFetch({
    url: "transaction",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const formatChartData = (rows: any[], totalDaysInMonth: number) => {
    let uniques: number[] = [];
    let installs: number[] = [];
    let opens: number[] = [];
    let regs: number[] = [];
    let deps: number[] = [];

    if (customPeriod[0] && !customPeriod[1]) {
      uniques = new Array(24).fill(0);
      installs = new Array(24).fill(0);
      opens = new Array(24).fill(0);
      regs = new Array(24).fill(0);
      deps = new Array(24).fill(0);

      const selectedDate = new Date(customPeriod[0]);
      selectedDate.setHours(0, 0, 0, 0);

      rows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();
          uniques[hour] += row.campaign_unique_clicks || 0;
          opens[hour] += row.clicks || 0;
          regs[hour] += row.regs || 0;
          deps[hour] += row.deposits || 0;
        }
      });

      transaction.forEach((row) => {
        const rowDate = new Date(row.createdAt);
        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();
          installs[hour] += row.value || 0;
        }
      });
    }

    if (customPeriod[0] && customPeriod[1]) {
      const startDate = new Date(customPeriod[0]);
      const endDate = customPeriod[1] ? new Date(customPeriod[1]) : new Date();

      const startDateForComparison = new Date(startDate);
      startDateForComparison.setHours(0, 0, 0, 0);

      const endDateForComparison = new Date(endDate);
      endDateForComparison.setHours(23, 59, 59, 999);

      const totalDays =
        Math.ceil((endDateForComparison.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24)) + 1;

      uniques = new Array(totalDays).fill(0);
      opens = new Array(totalDays).fill(0);
      regs = new Array(totalDays).fill(0);
      deps = new Array(totalDays).fill(0);

      rows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        if (rowDate >= startDateForComparison && rowDate <= endDateForComparison) {
          const dayIndex = Math.floor((rowDate.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24));
          uniques[dayIndex] += row.campaign_unique_clicks || 0;
          opens[dayIndex] += row.clicks || 0;
          regs[dayIndex] += row.regs || 0;
          deps[dayIndex] += row.deposits || 0;
        }
      });
      if (uniques.every((value) => value === 0)) {
        return [];
      }
    }

    if (selectedPeriod === "1_month_ago") {
      uniques = new Array(totalDaysInMonth).fill(0);
      opens = new Array(totalDaysInMonth).fill(0);
      regs = new Array(totalDaysInMonth).fill(0);
      deps = new Array(totalDaysInMonth).fill(0);
      rows.forEach((row) => {
        const date = new Date(row.datetime);

        const day = date.getDate();
        uniques[day - 1] += row.campaign_unique_clicks || 0;
        opens[day - 1] += row.clicks || 0;
        regs[day - 1] += row.regs || 0;
        deps[day - 1] += row.deposits || 0;
      });
    }
    if (selectedPeriod === "1_year_ago") {
      uniques = new Array(12).fill(0);
      opens = new Array(12).fill(0);
      regs = new Array(12).fill(0);
      deps = new Array(12).fill(0);

      rows.forEach((row) => {
        const date = new Date(row.datetime);
        const month = date.getMonth();
        const year = date.getFullYear();
        const currentYear = new Date().getFullYear();

        if (year === currentYear) {
          uniques[month] += row.campaign_unique_clicks || 0;
          opens[month] += row.clicks || 0;
          regs[month] += row.regs || 0;
          deps[month] += row.deposits || 0;
        }
      });
    }
    if (selectedPeriod === "today") {
      uniques = new Array(24).fill(0);
      opens = new Array(24).fill(0);
      regs = new Array(24).fill(0);
      deps = new Array(24).fill(0);

      const today = new Date();
      const selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      rows.forEach((row) => {
        const rowDate = new Date(row.datetime);

        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();

          uniques[hour] += row.campaign_unique_clicks || 0;
          opens[hour] += row.clicks || 0;
          regs[hour] += row.regs || 0;
          deps[hour] += row.deposits || 0;
        }
      });

      // if (uniques.every((value) => value === 0)) {
      //   uniques.fill(0);
      //   opens.fill(0);
      //   regs.fill(0);
      //   deps.fill(0);
      // }
    }

    if (selectedPeriod === "1_month_ago") {
      installs = new Array(totalDaysInMonth).fill(0);
      transaction.forEach((row) => {
        const date = new Date(row.createdAt);
        const day = date.getDate();
        const transactionMonth = date.getMonth();
        const transactionYear = date.getFullYear();
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        if (transactionMonth === currentMonth && transactionYear === currentYear) {
          installs[day - 1] += row.value || 0;
        }
      });
    }
    if (selectedPeriod === "1_year_ago") {
      installs = new Array(12).fill(0);
      transaction.forEach((row) => {
        const date = new Date(row.createdAt);
        const month = date.getMonth();
        installs[month] += row.value || 0;
      });
    }
    if (selectedPeriod === "today") {
      installs = new Array(24).fill(0);
      const today = new Date();
      const selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      transaction.forEach((row) => {
        const rowDate = new Date(row.createdAt);

        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();
          installs[hour] += row.value || 0;
        }
      });

      if (installs.every((value) => value === 0)) {
        installs.fill(0);
      }
    }

    if (customPeriod[0] && customPeriod[1]) {
      const startDate = new Date(customPeriod[0]);
      const endDate = new Date(customPeriod[1]);

      const startDateForComparison = new Date(startDate);
      startDateForComparison.setHours(0, 0, 0, 0);

      const endDateForComparison = new Date(endDate);
      endDateForComparison.setHours(23, 59, 59, 999);

      const totalDays =
        Math.ceil((endDateForComparison.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24)) + 1;

      installs = new Array(totalDays).fill(0);
      transaction.forEach((row) => {
        const rowDate = new Date(row.createdAt);

        if (rowDate >= startDateForComparison && rowDate <= endDateForComparison) {
          const dayIndex = Math.floor((rowDate.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24));
          installs[dayIndex] += row.value || 0;
        }
      });
    }

    return [
      {
        id: "uniques",
        label: t("uniques"),
        color: "#8C57FF",
        values: uniques,
      },
      {
        id: "installs",
        label: t("installs"),
        color: "#16B1FF",
        values: installs,
      },
      {
        id: "opens",
        label: t("opens"),
        color: "#56CA00",
        values: opens,
      },
      {
        id: "regs",
        label: t("regs"),
        color: "#F4701B",
        values: regs,
      },
      {
        id: "deps",
        label: t("deps"),
        color: "#ECEDEE",
        values: deps,
      },
    ];
  };

  const checkFormatedData = (data: DataPoint[]): DataPoint[] => {
    const hasNonZeroValue = data.some((item) => item.values.some((value: number) => value !== 0));

    if (hasNonZeroValue) {
      return data;
    }
    return data.map((item) => ({
      ...item,
      values: [],
    }));
  };

  async function getDataReport(signal: AbortSignal) {
    const res = await getReport(null, undefined, { signal });
    const rows = res?.data?.rows || [];
    setReportData(rows);
  }

  async function getTransactions(signal: AbortSignal) {
    const response = (await triggerFetch(null, undefined, { signal })) as { data: { items: TransactionItem[] } };

    const res = response?.data?.items;

    const filteredTransactions = res?.filter((item: TransactionItem) => item.type === "pwa-install");

    const resultArray: ResultItem[] = filteredTransactions?.map(({ createdAt }) => ({
      value: 1,
      createdAt,
    }));

    setTransaction(resultArray);
  }

  useEffect(() => {
    if (selectedPeriod !== "custom") {
      setCustomPeriod("");
    }

    let isRequestCancelled = false;
    setIsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        setIsLoading(true);
        await getDataReport(signal);
        await getTransactions(signal);
        if (!isRequestCancelled) {
          setIsLoading(false);
        }
      } catch (error) {
        if (!isRequestCancelled) {
          setIsLoading(false);
        }
      }
    })();

    return () => {
      isRequestCancelled = true;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod, customPeriod]);

  useEffect(() => {
    if (!transaction) {
      return;
    }
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate();

    const formattedData = checkFormatedData(formatChartData(reportData, totalDaysInMonth));
    setChartData(formattedData);
  }, [transaction, reportData, selectedPeriod]);

  const getXAxisData = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    switch (selectedPeriod) {
      case "today":
        return generateHoursOfDay();
      case "1_month_ago":
        return generateDaysOfMonth(year, month);
      case "1_year_ago":
        return generateMonthsOfYear();
      case "custom":
        return generateCustomPeriod();
      default:
        return [];
    }
  };

  const filteredData = selectedFilter === "all" ? chartData : chartData.filter((item) => item.id === selectedFilter);

  const onChangeDateRange = (dates: any) => {
    setCustomPeriod(dates);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          padding: { xs: "0 27px", lg: "0" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "16px",
            lineHeight: "32px",
          }}
        >
          {t("analytics")}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", padding: { xs: "15px 0", lg: "0" } }}>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#8C57FF",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("uniques")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#16B1FF",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("installs")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#56CA00",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("clicks")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#F4701B",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("regs")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#ECEDEE",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("deps")}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
          <Box sx={{ paddingRight: "12px" }}>
            <FilterCharts selectedFilter={selectedFilter} onChange={setSelectedFilter} />
          </Box>
          <SelectPeriod
            setError={setError}
            error={error}
            selectedPeriod={selectedPeriod}
            onChange={setSelectedPeriod}
            onChangeDateRange={onChangeDateRange}
          />
        </Box>
      </Box>

      <Box
        sx={{
          overflow: "auto",
          "& .MuiChartsAxis-line": {
            stroke: "#F0F0F0",
          },
          "& .MuiChartsGrid-line": {
            stroke: "#F0F0F0",
            strokeDasharray: "4 4",
          },
          "& .MuiChartsAxis-tick": {
            display: "none",
          },
          "& .MuiChartsSurface-root": {
            position: "relative",
            zIndex: 1,
          },
          "& .MuiBarElement-root": {
            width: "12px !important",
          },
        }}
      >
        <Box
          position="relative"
          sx={{
            minWidth: { xs: "900px", md: "100%" },
          }}
        >
          {isLoading && (
            <Preloader
              customStyles={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                zIndex: 20,
              }}
            />
          )}
          <BarChart
            grid={{ vertical: true, horizontal: true }}
            xAxis={[
              {
                id: "time",
                data: getXAxisData(),
                scaleType: "band",
              },
            ]}
            yAxis={[
              {
                id: "events",
                min: 0,
                max: 180,
                tickSize: 20,
              },
            ]}
            series={filteredData.map(({ label, color, values }) => ({
              stack: "A",
              label,
              data: values,
              color,
              barSpacing: 8,
            }))}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            height={400}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default MyBarChart;
