import React, { useState, useEffect, ChangeEvent } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Slider } from "@mui/material";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";

export default function Rating() {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();

  const [ratings, setRatings] = useState<[number, number, number, number, number]>(appData.ratings);
  const [totalReviews, setTotalReviews] = useState<number>(Number(appData.amountReviews) || 0);
  // console.log("raiting", appData.rating);
  const { errors, clearError } = useErrorAppStore();

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });

    if (field === "amountReviews") {
      setTotalReviews(Number(value));
      setRatings([0, 0, 0, 0, 0]);
    }

    if (value) {
      clearError(field);
    }
  };

  const handleRatingChange = (index: number, newValue: number) => {
    const newRatings: [number, number, number, number, number] = [...ratings];
    const normalizedValue = (newValue * totalReviews) / 100;
    newRatings[index] = normalizedValue;

    const sumOtherRatings = newRatings.reduce((acc, rating, idx) => (idx !== index ? acc + rating : acc), 0);
    const remainingReviews = totalReviews - normalizedValue;

    if (remainingReviews >= 0) {
      const remainingRatings = newRatings.map((rating, idx) => {
        if (idx !== index) {
          return sumOtherRatings === 0 ? 0 : Math.round((remainingReviews * rating) / sumOtherRatings);
        }
        return normalizedValue;
      }) as [number, number, number, number, number];
      setRatings(remainingRatings);
      saveAppData({ ratings: remainingRatings });
    } else {
      newRatings[index] = totalReviews;
      newRatings.fill(0, 0, index);
      newRatings.fill(0, index + 1);
      setRatings(newRatings);
      saveAppData({ ratings: newRatings });
    }
  };

  useEffect(() => {
    const sumRatings = ratings.reduce((acc, rating) => acc + rating, 0);
    if (sumRatings > totalReviews) {
      const adjustedRatings = ratings.map((rating) => Math.round((totalReviews * rating) / sumRatings)) as [
        number,
        number,
        number,
        number,
        number,
      ];
      setRatings(adjustedRatings);
      saveAppData({ ratings: adjustedRatings });
    }
  }, [totalReviews, ratings]);

  useEffect(() => {
    const calculateRating = () => {
      const totalWeightedRatings = ratings.reduce((acc, rating, idx) => acc + rating * (5 - idx), 0);
      const calculatedRating = totalReviews ? totalWeightedRatings / totalReviews : 0;
      // saveAppData({ rating: calculatedRating.toFixed(2) });
    };

    calculateRating();
  }, [ratings, totalReviews]);

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontFamily: "var(--cygreRegular), sans-serif" }}>{t("rating")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%" }}>
            {ratings.map((value, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    width: "5%",
                  }}
                  component="span"
                >
                  {5 - index}
                </Typography>
                <Box
                  sx={{
                    width: "80%",
                    paddingLeft: "10px",
                  }}
                >
                  <StarsSlider
                    value={(value / totalReviews) * 100}
                    onChange={(event, newValue) => handleRatingChange(index, newValue as number)}
                    max={100}
                  />
                </Box>
                <Typography
                  sx={{
                    width: "15%",
                    textAlign: "right",
                    fontSize: "14px",
                    fontFamily: "var(--cygreRegular), sans-serif",
                  }}
                  component="span"
                >
                  {Math.round(value)}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ width: "50%", paddingLeft: "24px" }}>
            <Box>
              <CustomInput
                margin="normal"
                required
                fullWidth
                id="rating"
                height="40px"
                placeholder={t("rating")}
                name="rating"
                label={t("rating")}
                value={appData.rating}
                onChange={(e: any) => handleInputChange(e, "rating")}
                customBgColor="#F6F7F9"
                error={!!errors.rating}
                helperText={errors.rating}
              />
            </Box>
            <Box>
              <CustomInput
                margin="normal"
                required
                fullWidth
                id="amountReviews"
                height="40px"
                placeholder={t("enterthenumberofreviews")}
                name="amountReviews"
                label={t("amountReviews")}
                value={appData.amountReviews}
                onChange={(e: any) => handleInputChange(e, "amountReviews")}
                customBgColor="#F6F7F9"
                error={!!errors.amountReviews}
                helperText={errors.amountReviews}
              />
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const StarsSlider = styled(Slider)(() => ({
  color: "#F4701B",
  height: 5,
  padding: "10px 0",
  "& .MuiSlider-thumb": {
    width: 15,
    height: 15,
    backgroundColor: "#F4701B",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
      },
    },
    "&:before": {
      boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: "#F4701B",
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: "#fff",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "#d0d0d0",
  },
}));
