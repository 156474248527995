import CustomTable, { IColumn } from "components/CustomTable";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatTime } from "utils/formateDate";
import showPreviewIcon from "utils/showPreviewImage";
import ImageIcon from "assets/icons/ImageIcon";
import { IPwaStatisticsItem } from "pages/DashboardPage";

interface IAccountsTable {
  statistics: IPwaStatisticsItem[];
}

export default function AccountsTable({ statistics }: IAccountsTable) {
  const { t } = useTranslation();
  const columns: IColumn[] = [
    {
      id: "name",
      label: "PWA",
      align: "left",
      render: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          {row.icon ? (
            <img
              src={showPreviewIcon(row.icon)}
              alt="icon"
              style={{
                width: 56,
                height: 56,
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 56,
                height: 56,
                background: "#F6F7FB",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            >
              <ImageIcon />
            </Box>
          )}
          <Typography sx={{ fontSize: 14, fontFamily: "var(--cygreMedium), sans-serif", paddingLeft: "12px" }}>
            {row.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: "updatedAt",
      label: t("dateofwriteOff"),
      align: "left",
      format: formatTime,
    },
    {
      id: "installCount",
      label: t("installs"),
      align: "left",
    },
    {
      id: "totalAmount",
      label: t("ammount"),
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "red", fontFamily: "var(--cygreMedium), sans-serif", fontSize: "14px" }}>
          -{row.totalAmount.toFixed(3)}
        </Typography>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "16px",
          lineHeight: "32px",
          paddingLeft: "16px",
        }}
      >
        {t("accounts")}
      </Typography>

      {statistics.length > 0 ? (
        <CustomTable columns={columns} rows={statistics} />
      ) : (
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "16px",
            lineHeight: "32px",
            paddingLeft: "16px",
          }}
        >
          {t("noitems")}
        </Typography>
      )}
    </Box>
  );
}
