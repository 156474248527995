// Header.tsx
"use client";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import LanguageSelector from "components/LanguageSelector";
import Logo from "components/Logo";
import CustomButton from "components/Button";
import Navigation from "components/Navigation";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useModalStore from "store/modalStore";
import AuthModal from "components/AuthModal";

interface Props {
  window?: () => Window;
}

const drawerWidth = "100%";

export default function Header(props: Props) {
  const { window } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isOpen, mode, openModal, closeModal } = useModalStore();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1,
        }}
      >
        <Logo />
        <IconButton edge="end" color="inherit" onClick={handleDrawerToggle} aria-label="close drawer">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Navigation variant="mobile" setMobileOpen={setMobileOpen} />
          <CustomButton
            onClick={() => openModal("signup")}
            textButton={t("signup")}
            customFontSize={"23px"}
            customBgColor="#F4701B"
            height={50}
            width="100%"
            customHoverColor="#F4701B"
            lineHeight={"1.3"}
          />
          <Box
            sx={{
              color: "#000",
            }}
          >
            <Button
              color="inherit"
              variant="text"
              sx={{
                fontFamily: "var(--cygreRegular), sans-serif",
                fontSize: "23px",
              }}
              onClick={() => openModal("login")}
            >
              {t("login")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar color="secondary" component="nav">
          <Toolbar
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "1",
            }}
          >
            {isLargeScreen && (
              <>
                <Logo />
                <Navigation variant="desktop" />

                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <LanguageSelector />
                  <Button color="inherit" variant="text" onClick={() => openModal("login")}>
                    {t("login")}
                  </Button>
                  <CustomButton
                    onClick={() => openModal("signup")}
                    textButton={t("signup")}
                    height={50}
                    width="100%"
                    customBgColor="#F4701B"
                    customHoverColor="#F4701B"
                    lineHeight={"2.2"}
                  />
                </Box>
              </>
            )}
            {!isLargeScreen && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Logo />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <LanguageSelector />
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ paddingLeft: "24px" }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Toolbar>
        </AppBar>

        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "block", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
      {mode && <AuthModal open={isOpen} onClose={closeModal} mode={mode} />}
    </>
  );
}
