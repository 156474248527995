import IIcon from "interfaces/icon";
import React from "react";

function Phone({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" fill="none" viewBox="0 0 12 19">
      <path
        fill={color}
        d="M2.09 18.42c-.421 0-.777-.146-1.069-.438a1.454 1.454 0 01-.438-1.069V2.426c0-.42.146-.777.438-1.069A1.454 1.454 0 012.09.92h7.82c.421 0 .778.146 1.07.437.291.292.437.648.437 1.07v14.486c0 .421-.146.778-.438 1.07a1.454 1.454 0 01-1.069.437H2.09zM6 16.56a.71.71 0 00.522-.216.71.71 0 00.215-.521.71.71 0 00-.215-.522.71.71 0 00-.522-.215.71.71 0 00-.521.215.71.71 0 00-.216.522.71.71 0 00.216.521.71.71 0 00.521.216zm-4.167-3.333h8.334V4.462H1.834v8.766z"
      ></path>
    </svg>
  );
}

export default Phone;
