import { Container } from "@mui/material";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DuckSayPWA } from "@ducksay/ducksay-pwa";
import Preloader from "components/GlobalPreloader/Preloader";

const PWAPreview = () => {
  const session = localStorage.getItem("authToken") || "";
  const { id } = useParams();

  const [pwaPreview, setPwaPreview] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { triggerFetch: getPwa } = useFetch({
    url: `pwa/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const GetPwa = async () => {
    const res = await getPwa();
    if (res) {
      setPwaPreview(res.data || null);
      setIsDataLoaded(true);
    }
  };

  useEffect(() => {
    GetPwa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isDataLoaded) {
    return <Preloader />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        paddingBottom: "60px",
        flexDirection: "column",
        zIndex: 1,
        backgroundColor: "transparent",
        overflow: "scroll",
        height: "100vh",
      }}
    >
      <Container
        sx={{
          paddingTop: "20px",
          maxWidth: "1296px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <DuckSayPWA pwaData={pwaPreview} isPreview={true} />
      </Container>
    </div>
  );
};

export default PWAPreview;
