import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DuckSayPWA } from "@ducksay/ducksay-pwa";
import { useAppStore } from "store/appStore";
import Preloader from "components/GlobalPreloader/Preloader";

const PhoneContentPwa: React.FC = () => {
  const { appData } = useAppStore();
  const [pwaPreview, setPwaPreview] = useState<any>();

  useEffect(() => {
    setPwaPreview(appData);
  }, [appData, pwaPreview]);

  return (
    <Box
      sx={{
        position: "relative",
        // maxWidth: isModal ? "100%" : "401px",
        overflow: "hidden",
        height: "100%",
        padding: "0",
        zIndex: "3",
      }}
    >
      <Box
        sx={{
          position: "relative",
          // top: "22px",
          width: "100%",
          // left: isModal ? "3px" : "8px",
          background: "white",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {pwaPreview ? <DuckSayPWA pwaData={appData as any} isPreview={true} /> : <Preloader />}
      </Box>
    </Box>
  );
};

export default PhoneContentPwa;
