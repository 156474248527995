"use client";

import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useAppStore } from "store/appStore";
import { textsToTranslate } from "utils/en";
import { translateText } from "utils/translationService";
import Preloader from "components/GlobalPreloader/Preloader";

export default function PWACopytPage() {
  const session: string = localStorage.getItem("authToken") || "";
  const { id, token } = useParams();
  const { appData, saveAppData } = useAppStore();
  const [translations, setTranslations] = useState({});

  const { triggerFetch: getNewId } = useFetch({
    url: `pwa/${id}/copy/${token}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  async function getPwa() {
    const result = await getNewId();
    if (result?.data) {
      console.log("result?.data", result?.data);
      saveAppData(result?.data);
      window.location.href = `/pwa/edit-pwa/${result?.data?._id}`;
    }
  }

  useEffect(() => {
    getPwa();
  }, []);

  useEffect(() => {
    const translate = async () => {
      if (appData?.language === "en" || appData?.language === "en-GB" || appData?.language === "EN") {
        setTranslations(textsToTranslate);
      } else {
        const translatedValues = await translateText(textsToTranslate, appData?.language || "en");
        setTranslations(translatedValues);
        // i18n.changeLanguage(appData?.language);
      }
    };

    translate();
    console.log("appData?.language", appData?.language);
  }, [appData?.language]);

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        height: "100vh",
      }}
    >
      <Preloader />
    </div>
  );
}
