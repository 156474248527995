import { Box, Typography } from "@mui/material";

interface IAdvantageCard {
  title: string;
  text: string;
  icon: React.ReactNode;
}

export default function AdvantageCard({ title, text, icon }: IAdvantageCard) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#fff",
        padding: { xl: "60px 80px", lg: "40px 50px", xs: "16px" },
        borderRadius: "24px",
        flexDirection: { xs: "column-reverse", md: "row" },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#000",
            fontSize: { xl: "32px", lg: "28px", md: "24px", xs: "18px" },
            lineHeight: { xl: "40px", lg: "38px", xs: "22.5px" },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "CygreThin",
            color: "#000",
            textRendering: "geometricPrecision",
            fontSize: { lg: "18px", xs: "16px" },
            lineHeight: { xl: "28px", lg: "26px", xs: "24px" },
            paddingTop: "24px",
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box>{icon}</Box>
    </Box>
  );
}
