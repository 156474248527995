// src/store/modalStore.ts
import { create } from "zustand";

interface ModalState {
  isOpen: boolean;
  mode: "login" | "signup" | "forgotPassword" | "" | "passwordRecovery";
  openModal: (mode: "login" | "signup" | "forgotPassword" | "" | "passwordRecovery") => void;
  closeModal: () => void;
}

const useModalStore = create<ModalState>((set) => ({
  isOpen: false,
  mode: "login",
  openModal: (mode: "login" | "signup" | "forgotPassword" | "passwordRecovery" | "") => set({ isOpen: true, mode }),
  closeModal: () => set({ isOpen: false, mode: "" }),
}));

export default useModalStore;
