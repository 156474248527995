import { useTranslation } from "react-i18next";

export default function useCountriesForFirstRegion() {
  const { t } = useTranslation();
  return [
    t("australia"),
    t("austria"),
    t("belgium"),
    t("germany"),
    t("denmark"),
    t("ireland"),
    t("spain"),
    t("italy"),
    t("canada"),
    t("luxembourg"),
    t("netherlands"),
    t("new_zealand"),
    t("norway"),
    t("united_kingdom"),
    t("united_states"),
    t("finland"),
    t("france"),
    t("switzerland"),
    t("sweden"),
  ];
}
