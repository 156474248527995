import { Box } from "@mui/material";
import { useState } from "react";
import Eye from "assets/icons/Eye";
import StopIcon from "assets/icons/StopIcon";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { useTranslation } from "react-i18next";
import PushMockupModal from "components/PushMockupModal";
import { IAppData, INotification } from "interfaces/push";

interface IPushButtons {
  onCardClick: () => void;
  onDeletePush: () => void;
  appData: IAppData;
  notification: INotification;
}

export default function PushButtons({ onDeletePush, notification, onCardClick, appData }: IPushButtons) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handlePreview = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenModal(true);
    onCardClick();
  };

  const handleStop = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onDeletePush();
  };
  const now = Math.floor(Date.now() / 1000);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!notification.canceled && notification.send_after > now && (
          <BootstrapTooltip title={t("stopPush")} placement="top">
            <Box
              sx={{
                paddingRight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleStop}
            >
              <StopIcon width={19} height={19} color={"#6D6777"} />
            </Box>
          </BootstrapTooltip>
        )}

        <BootstrapTooltip title={t("gotopreview")} placement="top">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handlePreview}
          >
            <Eye color={"#6D6777"} />
          </Box>
        </BootstrapTooltip>
      </Box>
      <PushMockupModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
