import React from "react";

function Opens() {
  return (
    <div
      style={{
        background: "rgba(86, 202, 0, 0.16)",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          fill="#56CA00"
          d="M2.552 24a2.464 2.464 0 01-1.81-.741A2.463 2.463 0 010 21.448V2.552C0 1.84.247 1.235.741.742A2.464 2.464 0 012.552 0h7.846c.3 0 .552.101.755.304.202.203.304.454.304.755 0 .3-.102.552-.304.754a1.025 1.025 0 01-.755.305H2.552a.414.414 0 00-.298.136.414.414 0 00-.136.298v18.896c0 .109.045.208.136.299.09.09.19.135.298.135h18.896a.414.414 0 00.299-.136c.09-.09.135-.19.135-.298v-7.846c0-.3.102-.552.305-.755.202-.202.454-.304.754-.304s.552.102.755.304c.203.203.304.455.304.755v7.846c0 .713-.247 1.317-.741 1.81a2.463 2.463 0 01-1.811.742H2.552zm19.33-20.395L9.524 15.964a1.025 1.025 0 01-.737.3.999.999 0 01-.75-.3 1.014 1.014 0 01-.308-.744c0-.291.103-.54.307-.744L20.395 2.118h-4.513c-.3 0-.552-.102-.754-.305a1.024 1.024 0 01-.305-.754c0-.3.102-.552.305-.755.202-.203.454-.304.754-.304h6.842c.364 0 .667.122.91.365.244.244.366.547.366.911v6.842c0 .3-.101.552-.304.754a1.024 1.024 0 01-.755.304c-.3 0-.552-.1-.754-.304a1.024 1.024 0 01-.305-.754V3.605z"
        ></path>
      </svg>
    </div>
  );
}

export default Opens;
