import React from "react";
import IIcon from "interfaces/icon";

function SaveTab({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" fill="none" viewBox="0 0 11 15">
      <path
        fill={color}
        d="M5.5 12.551l-3.308 1.42c-.502.215-.979.174-1.43-.123-.453-.297-.679-.715-.679-1.254V2.423c0-.421.146-.777.438-1.069A1.454 1.454 0 011.59.917h7.82c.421 0 .778.145 1.07.437.291.292.437.648.437 1.069v10.171c0 .539-.226.957-.678 1.254-.452.296-.929.338-1.431.123L5.5 12.55zm0-1.385l3.806 1.64c.086.037.167.03.245-.024a.25.25 0 00.116-.216V2.422a.245.245 0 00-.08-.176.245.245 0 00-.177-.08H1.59a.245.245 0 00-.176.08.245.245 0 00-.08.176v10.143c0 .09.038.162.116.216a.238.238 0 00.244.024l3.806-1.64z"
      ></path>
    </svg>
  );
}

export default SaveTab;
