import React from "react";

function Share() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none" viewBox="0 0 24 24">
      <path
        fill="#2E263D"
        d="M17.751 21.5a2.654 2.654 0 01-1.948-.802A2.651 2.651 0 0115 18.75c0-.125.01-.254.03-.388.02-.134.05-.257.09-.37l-7.397-4.33a2.948 2.948 0 01-.902.616 2.635 2.635 0 01-1.071.222 2.653 2.653 0 01-1.948-.802A2.65 2.65 0 013 11.751c0-.763.267-1.413.802-1.948A2.65 2.65 0 015.75 9c.377 0 .734.074 1.071.222.337.148.638.354.902.617l7.396-4.331a2.016 2.016 0 01-.09-.37A2.646 2.646 0 0115 4.75c0-.764.267-1.413.802-1.948A2.65 2.65 0 0117.749 2c.763 0 1.413.267 1.948.802.535.534.803 1.183.803 1.947 0 .763-.267 1.413-.802 1.948a2.65 2.65 0 01-1.948.803c-.377 0-.734-.074-1.071-.222a2.947 2.947 0 01-.902-.616l-7.396 4.33c.04.113.07.236.09.37a2.628 2.628 0 010 .774 2.01 2.01 0 01-.09.372l7.396 4.33c.264-.262.565-.468.902-.616A2.633 2.633 0 0117.75 16c.764 0 1.413.267 1.948.802a2.65 2.65 0 01.802 1.947c0 .763-.267 1.413-.802 1.948a2.649 2.649 0 01-1.947.803zM17.75 6c.348 0 .643-.122.886-.364.242-.243.364-.538.364-.886s-.122-.643-.364-.885a1.205 1.205 0 00-.886-.365c-.348 0-.643.122-.886.365a1.205 1.205 0 00-.364.885c0 .348.122.643.364.886.243.242.538.364.886.364zm-12 7c.348 0 .643-.121.886-.364.242-.243.364-.538.364-.886s-.122-.643-.364-.886a1.205 1.205 0 00-.886-.364c-.348 0-.643.121-.886.364a1.205 1.205 0 00-.364.886c0 .348.122.643.364.886.243.243.538.364.886.364zm12 7c.348 0 .643-.122.886-.364.242-.243.364-.538.364-.886s-.122-.643-.364-.886a1.205 1.205 0 00-.886-.364c-.348 0-.643.122-.886.364a1.205 1.205 0 00-.364.886c0 .348.122.643.364.886.243.242.538.364.886.364z"
      ></path>
    </svg>
  );
}

export default Share;
