type CallbackFunction = (...args: any[]) => void;

export const debounce = (callback: CallbackFunction, wait: number): CallbackFunction => {
  let timeoutId: number | null = null;

  return (...args: any[]) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    timeoutId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

// export const throttle = (
//   callback: CallbackFunction,
//   delay: number = 5000,
//   isPausedRef: React.MutableRefObject<boolean>
// ): CallbackFunction => {
//   return (...args: any[]) => {
//     if (isPausedRef.current) return;

//     callback(...args);
//     isPausedRef.current = true;

//     setTimeout(() => {
//       isPausedRef.current = false;
//     }, delay);
//   };
// };
