import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "pages/LandingPage";
import { ToastContainer } from "react-toastify";
import { DashboardLayout } from "components/DashboardLayout";
import DashboardPage from "pages/DashboardPage";
import { ProtectedRouteHOC } from "components/ProtectedRouteHOC";
import { UnProtectedRouteHOC } from "components/UnProtectedRouteHOC";
import ProfilePage from "pages/ProfilePage";
import PaymentPage from "pages/PaymentPage";
import PWAPage from "pages/PWAPage";
import PWACreatePage from "pages/PWACreatePage";
import PWAEditPage from "pages/PWAEditPage";
import PWAPreview from "pages/PWAPreview";
import { ProfileLayout } from "components/ProfileLayout";
import ProfileNotificationsPage from "pages/ProfileNotificationsPage";
import ProfileTariffsPage from "pages/ProfileTariffsPage";
import FourOf4Page from "pages/404Page";
import ProfilePasswordChangePage from "pages/ProfilePasswordChangePage";
import ProfileFAQPage from "pages/ProfileFAQPage";
import PushListPage from "pages/PushListPage";
import PushCreatePage from "pages/PushCreatePage";
import PushUpdatePage from "pages/PushUpdatePage";
import StatisticsPage from "pages/StatisticsPage";
import SupportPage from "pages/SupportPage";
import PWACopytPage from "pages/PWACopyPage";
import AuthPage from "pages/AuthPage";
import ResetPassword from "pages/ResetPassword";
import { TranslatedMeta } from "components/TranslatedMeta";

const App: React.FC = () => {
  return (
    <>
      <TranslatedMeta />
      <Routes>
        <Route
          index
          element={
            <UnProtectedRouteHOC>
              <LandingPage />
              <ResetPassword />
            </UnProtectedRouteHOC>
          }
        />
        <Route
          path="landing"
          element={
            <UnProtectedRouteHOC>
              <LandingPage />
              <ResetPassword />
            </UnProtectedRouteHOC>
          }
        />
        <Route path="telegram/:token" element={<AuthPage />} />
        <Route path="google/:token" element={<AuthPage />} />

        <Route path="pwa-preview/:id" element={<PWAPreview />} />
        <Route
          element={
            <ProtectedRouteHOC>
              <DashboardLayout />
            </ProtectedRouteHOC>
          }
        >
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="pwa" element={<PWAPage />} />
          <Route path="pwa/create" element={<PWACreatePage />} />
          <Route path="pwa/edit-pwa/:id" element={<PWAEditPage />} />
          <Route path="pwa/:id/copy/:token" element={<PWACopytPage />} />
          <Route path="push" element={<PushListPage />} />
          <Route path="push/create" element={<PushCreatePage />} />
          <Route path="push/edit/:id" element={<PushUpdatePage />} />
          <Route path="stats" element={<StatisticsPage />} />
          <Route path="support" element={<SupportPage />} />

          <Route element={<ProfileLayout />}>
            <Route path="profile" element={<ProfilePage />} />
            <Route path="profile/notifications" element={<ProfileNotificationsPage />} />
            <Route path="profile/tariffs" element={<ProfileTariffsPage />} />
            <Route path="/profile/changepsw" element={<ProfilePasswordChangePage />} />
            <Route path="/profile/faq" element={<ProfileFAQPage />} />
          </Route>

          <Route path={"*"} element={<FourOf4Page />} />
        </Route>
        <Route path={"*"} element={<FourOf4Page />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
