import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ModalTrack from "./ModalTrack";
import ModalAdditional from "./ModalAdditional";
import { Box } from "@mui/material";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import { getToken } from "components/Stats";
import useFetchFormData from "hooks/useFetchFormData";
import useIdStore from "store/pwaId";
import { useNavigate } from "react-router-dom";
import useFetch from "hooks/useFetch";
import useSetupStore from "store/setupSettingsStore";
import { useAppStore } from "store/appStore";
// import { getUpdatedFields } from "utils/pwaHelpers";
import { AppDataMockup } from "interfaces/mockupApp";
import { toast } from "react-toastify";
import { debounce } from "utils/debounce";
import usePwaSave from "hooks/usePwaSave";

export function generateId() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

const TrackingTab = () => {
  const { appData, saveAppData, clearAppData } = useAppStore();
  const { t } = useTranslation();
  const [session, setSession] = useState("");
  // const [icon, setIcon] = useState("");
  // const [oneSignalId, setOneSignalId] = useState("");
  const { id, clearId, setId } = useIdStore();
  // const API_KEY = process.env.NEXT_PUBLIC_ONESIGNAL;
  const navigate = useNavigate();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);
  const setStepStatus = useSetupStore((state) => state.setStepStatus);
  const resetSteps = useSetupStore((state) => state.resetAllSteps);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isLoading, handlePwa } = usePwaSave();

  useEffect(() => {
    setStepStatus("tracking", "inprogress");
    return () => {
      setStepStatus("tracking", "ready");
    };
  }, [setStepStatus]);

  useEffect(() => {
    const token = getToken();
    setSession(token!);
  }, []);

  const handleSaveLink = () => {
    saveAppData({
      offerLink: appData.offerLink,
    });
  };

  const handleChangeSwith = async (name: keyof AppDataMockup) => {
    saveAppData({
      [name]: !appData[name],
    });
  };

  // const { triggerFetch: createCampaign } = useFetch({
  //   url: "tracking/keitaro-proxy",
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //     "Content-Type": "application/json",
  //   },
  //   body: {
  //     method: "POST",
  //     uri: "/campaigns",
  //     requestBody: {},
  //   },
  // });

  // const { triggerFetch: getDomains } = useFetch({
  //   url: "tracking/keitaro-proxy",
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //   },
  //   body: {
  //     method: "GET",
  //     uri: "/domains",
  //   },
  // });

  // const { triggerFetch: createFlow } = useFetch({
  //   url: "tracking/keitaro-proxy",
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //     "Content-Type": "application/json",
  //   },
  //   body: {
  //     method: "POST",
  //     uri: "/streams",
  //   },
  // });

  // const postCampaign = async (domainId: number, id: string, icon: string) => {
  //   try {
  //     const campaignRes = await createCampaign({
  //       requestBody: {
  //         alias: generateId(),
  //         name: appData.appName,
  //         domain_id: domainId,
  //       },
  //     });
  //     const campaignId = campaignRes.data.id;

  //     if (campaignId) {
  //       try {
  //         await createFlow({
  //           requestBody: {
  //             type: "regular",
  //             name: "Black",
  //             position: 1,
  //             weight: 100,
  //             state: "active",
  //             action_type: "http",
  //             schema: "redirect",
  //             collect_clicks: true,
  //             filter_or: false,
  //             action_payload: appData.offerLink,
  //             campaign_id: campaignId,
  //           },
  //         });
  //       } catch (error) {
  //         console.error("Error creating flow:", error);
  //       }
  //     }

  //     saveAppData({
  //       blackLink: `${campaignRes.data.domain}${campaignRes.data.alias}`,
  //     });

  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}pwa/${id}`, {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${session}`,
  //         },
  //         body: JSON.stringify({
  //           blackLink: `${campaignRes.data.domain}${campaignRes.data.alias}`,
  //         }),
  //       });
  //       if (!response.ok) {
  //         throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
  //       }
  //       const result = await response.json();
  //       return result;
  //     } catch (err: unknown) {
  //       if (err instanceof Error) {
  //         console.log("Ошибка:", err.message);
  //       } else {
  //         console.log("Неизвестная ошибка:", err);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error creating campaign:", error);
  //   }
  // };

  // const { triggerFetch: createPwa } = useFetchFormData({
  //   url: "pwa",
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //   },
  // });

  // const { triggerFetch: updatePWA } = useFetchFormData({
  //   url: `pwa/${id}`,
  //   method: "PUT",
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //   },
  // });

  // const { triggerFetch: attachDomain } = useFetch({
  //   url: "",
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //   },
  // });

  // async function changePwaDomain(domainID: string, pwaid: string) {
  //   try {
  //     const result = await attachDomain(
  //       {
  //         domain: domainID,
  //       },
  //       `pwa/${pwaid}/domain`
  //     );

  //     if (!result.success) {
  //       toast.error(result?.message || "Attach domain failed");
  //       return;
  //     }
  //     toast.success("Domain attached successfully");
  //     await getDomains();
  //     return result.data;
  //   } catch (error: any) {
  //     toast.error("Error", error?.message || "Attach domain failed");
  //   }
  // }

  // const handlePwa = debounce(async () => {
  //   const data = new FormData();

  //   const appendField = (field: string, value: any) => {
  //     if (Array.isArray(value) || typeof value === "object") {
  //       data.append(field, JSON.stringify(value));
  //     } else {
  //       data.append(field, value as string | Blob);
  //     }
  //   };

  //   if (!id) {
  //     setIsLoading(true);
  //     Object.entries(appData).forEach(([field, value]) => {
  //       if (field === "creatives") {
  //         const creatives = value as typeof appData.creatives;
  //         if (creatives?.icon) {
  //           data.append("icon", creatives.icon);
  //         }
  //         if (creatives?.previews?.length) {
  //           creatives.previews.forEach((preview) => {
  //             data.append("previews", preview);
  //           });
  //         }
  //       } else {
  //         appendField(field, value);
  //       }
  //     });

  //     const res = await createPwa(data);
  //     if (res?.data) {
  //       localStorage.setItem("pwaData", JSON.stringify(res.data));
  //       saveAppData(res.data);
  //       setId(res.data._id);

  //       const storageDomain = localStorage.getItem("tmpDomainID");
  //       if (storageDomain) {
  //         await changePwaDomain(storageDomain, res.data._id);
  //         localStorage.removeItem("tmpDomainID");
  //       }

  //       const domainsResponse = await getDomains();
  //       const domainsData = domainsResponse.data;

  //       const domainToAdd = domainsData[domainsData.length - 1];

  //       await postCampaign(domainToAdd.id, res.data._id, res.data.creatives.icon);
  //       clearAppData();
  //       setSettingProcess(false);
  //       resetSteps();
  //       clearId();
  //       localStorage.removeItem("pwaData");
  //       toast.success(t("successPwaCreate"));
  //     }
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       navigate("/pwa");
  //     }, 1000);
  //   } else {
  //     setIsLoading(true);
  //     const updatedFields = getUpdatedFields();

  //     if (updatedFields) {
  //       Object.entries(updatedFields).forEach(([field, value]) => {
  //         if (field === "creatives") {
  //           const creatives = value as typeof appData.creatives;
  //           if (creatives?.icon) {
  //             data.append("icon", creatives.icon);
  //           }
  //           if (creatives?.previews?.length) {
  //             creatives.previews.forEach((preview) => {
  //               data.append("previews", preview);
  //             });
  //           }
  //         } else if (field === "ratings" || field === "comments") {
  //           appendField(field, value);
  //         } else {
  //           appendField(field, value);
  //         }
  //       });
  //     }

  //     if (id && updatedFields) {
  //       const res = await updatePWA(data);
  //       if (res?.data) {
  //         saveAppData(res.data);
  //         clearAppData();
  //         setSettingProcess(false);
  //         resetSteps();
  //         clearId();
  //         localStorage.removeItem("pwaData");
  //         toast.success(t("successPwaUpdate"));
  //       }
  //     } else {
  //       clearAppData();
  //       setSettingProcess(false);
  //       resetSteps();
  //       clearId();
  //       localStorage.removeItem("pwaData");
  //     }
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       navigate("/pwa");
  //     }, 1000);
  //   }
  // }, 1000);

  const { triggerFetch: deletePwa } = useFetch({
    url: `pwa/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  async function handleDeletePwa() {
    if (id) {
      const res = await deletePwa();
      if (res) {
        navigate("/pwa");
        clearAppData();
        setSettingProcess(false);
        resetSteps();
        clearId();
        // localStorage.removeItem("pwaData");
      }
    } else {
      navigate("/pwa");
      clearAppData();
      setSettingProcess(false);
      resetSteps();
      clearId();
      // localStorage.removeItem("pwaData");
    }
  }

  return (
    <Box>
      <ModalTrack />
      <ModalAdditional handleChangeSwith={handleChangeSwith} />
      <Box sx={{ display: "flex", pt: 2 }}>
        <Box sx={{ display: "flex" }}>
          <CustomButton
            textButton={t("cancel")}
            height={40}
            width={184}
            customBgColor="#FFFFFF"
            customHoverColor="#FFFFFF"
            customBorderRadius="8px"
            customFontSize="14px"
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customMargin="0px 10px 0px 0px"
            onClick={handleDeletePwa}
          />
          <CustomButton
            textButton={t("save")}
            height={40}
            width={184}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            loading={isLoading}
            onClick={handlePwa}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TrackingTab;
