import { Outlet } from "react-router";
import { Container, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "components/DashboardSidebar/Sidebar";
import DashboardHeader from "components/DashboardHeader";
import useIsTablet from "hooks/useIsTablet";
import { useAuthStore } from "store/authStore";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
// import { getToken } from "./pwa/components/Pwas";

export const DashboardLayout = () => {
  const { setUser } = useAuthStore();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const isTablet = useIsTablet();
  const token = getToken();

  useEffect(() => {
    if (token) {
      void userInfo();
    }
  }, []);

  const { triggerFetch } = useFetch({
    url: "profile/user-info",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const userInfo = async () => {
    const res = await triggerFetch();
    if (res.data) {
      setUser(res?.data);
      localStorage.setItem("userData", JSON.stringify(res.data));
    }
  };

  const handleSidebarClose = (event: React.MouseEvent<HTMLElement>) => {
    setSidebarOpen(false);
    setMobileSidebarOpen(false);
  };

  return (
    <div style={{ minHeight: "100vh", width: "100%", display: "flex", flexDirection: "column" }}>
      {/*@ts-ignore*/}
      <DashboardHeader setMobileSidebarOpen={setMobileSidebarOpen} />
      <div
        style={{
          display: "flex",
          width: "100%",
          flex: 1,
          maxWidth: "100vw",
        }}
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          isMobileSidebarOpen={isMobileSidebarOpen}
          onSidebarClose={handleSidebarClose}
        />
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            paddingBottom: "60px",
            flexDirection: "column",
            maxWidth: "auto",
            // maxWidth: "1240px",
            backgroundColor: "transparent",
            height: "100vh",

            overflowY: "auto",
            // marginLeft: isTablet ? "0" : "256px",
          }}
        >
          <Container
            sx={{
              paddingTop: "20px",
              paddingBottom: "60px",
              // maxWidth: "900px",
            }}
            style={{
              maxWidth: "100%",
            }}
          >
            <Box sx={{ minHeight: "calc(100vh - 170px)" }}>
              <Outlet />
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};
