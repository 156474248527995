import Header from "components/Header";
import { Box } from "@mui/material";
import Hero from "components/Hero";
import InfiniteLooper from "components/InfinitySlider";
import InfinityIcon from "assets/icons/infinityIcon.png";
import Telegram from "components/Telegram";
import Footer from "components/Footer";
import Feedback from "components/Feedback/Feedback";
import Price from "components/Price/Price";
import MainFeatures from "components/MainFeatures/MainFeatures";
import CreateSteps from "components/CreateSteps";
import Advantages from "components/Advantages/Advantages";
import LandingStats from "components/LandingStats";

const items = ["Betting", "Dating", "Nutra", "Crypto", "Gambling"];

const containerStyles = {
  maxWidth: "1240px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  padding: { xs: "0 15px" },
};

export default function LandingPage() {
  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "scroll",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          ...containerStyles,
        }}
      >
        <Header />
        <Hero />
      </Box>

      <InfiniteLooper speed={20} direction="left">
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "9px 0px",
              whiteSpace: "nowrap",
              alignItems: "center",
            }}
          >
            {item}
            <img style={{ height: 45, width: 45, margin: "0 40px" }} src={InfinityIcon} alt="infinity" />
          </Box>
        ))}
      </InfiniteLooper>

      <Box
        sx={{
          ...containerStyles,
        }}
      >
        <CreateSteps />
        <MainFeatures />
        <Advantages />
        <Price />
      </Box>

      <LandingStats />

      <Box
        sx={{
          ...containerStyles,
        }}
      >
        <Feedback />
        <Telegram />
        <Footer />
      </Box>
    </Box>
  );
}
