import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled, css } from "@mui/system";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import { IAuthModal } from "interfaces/auth";
import { Signup } from "components/Signup";
import { Login } from "components/Login";
import { ForgotPassword } from "components/ForgotPassword";
import { PasswordRecovery } from "components/PasswordRecovery";

export default function AuthModal({ open, onClose, mode }: IAuthModal) {
  const [authMode, setAuthMode] = useState(mode);
  const navigate = useNavigate();
  useEffect(() => {
    setAuthMode(mode);
  }, [mode]);

  const toggleAuthMode = (newMode: string) => {
    setAuthMode(newMode);
  };

  const closeModal = () => {
    if (authMode === "passwordRecovery") {
      navigate("/", { replace: true });
      onClose(false);
      setAuthMode("");
      return;
    }
    onClose(false);
  };

  const renderContent = () => {
    switch (authMode) {
      case "login":
        return <Login onClose={onClose} toggleMode={toggleAuthMode} />;
      case "signup":
        return <Signup onClose={onClose} toggleMode={toggleAuthMode} />;
      case "forgotPassword":
        return <ForgotPassword toggleMode={toggleAuthMode} />;
      case "passwordRecovery":
        return <PasswordRecovery onClose={onClose} setAuthMode={setAuthMode} />;
      default:
        return null;
    }
  };

  const getModalWidth = () => {
    return authMode === "login" || authMode === "signup" ? { xs: "100%", md: "720px" } : { xs: "90%", md: "560px" };
  };

  return (
    <BaseModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <ModalContent sx={{ ...style, width: getModalWidth() }}>
          <IconButton
            sx={{ position: "absolute", color: "#000", top: { xs: 0, md: 24 }, right: 10 }}
            onClick={closeModal}
          >
            <CloseIcon fontSize="large" color="inherit" />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              width: "100%",
              padding: { xs: "20px", md: "60px 40px" },
            }}
          >
            {renderContent()}
          </Box>
        </ModalContent>
      </Fade>
    </BaseModal>
  );
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

const BaseModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    background-color: ${theme.palette.mode === "dark" ? "#1C2025" : "#fff"};
    border-radius: 20px;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434D5B" : "#DAE2ED"};
    box-shadow: 0 4px 12px ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    color: ${theme.palette.mode === "dark" ? "#F3F6F9" : "#1C2025"};

    @media (max-width: 600px) {
      max-height: 85%;
      max-width: 95%;
    }

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? "#B0B8C4" : "#303740"};
      margin-bottom: 4px;
    }
  `
);
