import moment from "moment";

export function formatDate(dateString: moment.MomentInput) {
  return moment(dateString).format("DD/MM/YYYY");
}




export function formatTime(dateString: moment.MomentInput): string {
  return moment(dateString).format("DD.MM.YYYY HH:mm:ss");
}


export const convertTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};
