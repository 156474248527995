import React from "react";
import { Select, MenuItem, Box, Typography, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";

interface FilterComponentProps {
  selectedFilter: string;
  onChange: (value: string) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ selectedFilter, onChange }) => {
  const { t } = useTranslation();
  const hoverColor = "#F4701B";
  const hoverBackgroundColor = alpha(hoverColor, 0.1);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Select
        sx={{
          position: "relative",
          backgroundColor: "#F6F7FB",
          color: "#6D6777",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "14px",
          boxShadow: "none",
          textTransform: "none",
          height: 40,
          marginLeft: { xs: 0, sm: "10px" },
          width: { xs: "100%", sm: "140px" },
          borderRadius: "8px",
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover": {
            backgroundColor: hoverBackgroundColor,
            color: hoverColor,
            "& .MuiSvgIcon-root": {
              color: hoverColor,
            },
          },
        }}
        value={selectedFilter}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        renderValue={() => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TuneIcon sx={{ marginRight: "8px", color: "#6D6777" }} />
            <Typography sx={{ color: "#6D6777", fontFamily: "var(--cygreRegular), sans-serif", fontSize: "14px" }}>
              {t("filter")}
            </Typography>
          </Box>
        )}
      >
        <MenuItem value="all">{t("all")}</MenuItem>
        <MenuItem value="uniques">{t("uniques")}</MenuItem>
        <MenuItem value="installs">{t("installs")}</MenuItem>
        <MenuItem value="opens">{t("opens")}</MenuItem>
        <MenuItem value="regs">{t("regs")}</MenuItem>
        <MenuItem value="deps">{t("deps")}</MenuItem>
      </Select>
    </Box>
  );
};

export default FilterComponent;
