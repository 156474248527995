import IIcon from "interfaces/icon";
import React from "react";

function Shuffle({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" fill="none" viewBox="0 0 14 13">
      <path
        fill={color}
        d="M9.388 12.92a.604.604 0 01-.445-.18.605.605 0 01-.18-.445c0-.178.06-.326.18-.445.12-.12.268-.18.445-.18h1.75L8.787 9.319a.595.595 0 01-.184-.442.627.627 0 01.197-.45.613.613 0 01.445-.193c.168 0 .316.065.446.194L12 10.75V9.082c0-.177.06-.326.18-.446s.268-.18.445-.18c.178 0 .326.06.445.18.12.12.18.269.18.446v3.085a.729.729 0 01-.217.536.729.729 0 01-.536.217h-3.11zm-8.452-.186a.598.598 0 01-.181-.44c0-.171.06-.318.18-.438L11.123 1.67H9.388a.605.605 0 01-.445-.18.605.605 0 01-.18-.445c0-.178.06-.326.18-.445.12-.12.268-.18.445-.18h3.109c.213 0 .392.072.536.217a.729.729 0 01.217.536v3.085c0 .177-.06.326-.18.445a.605.605 0 01-.445.18.604.604 0 01-.445-.18.605.605 0 01-.18-.445v-1.71L1.814 12.734a.605.605 0 01-.435.177.59.59 0 01-.443-.177zM.923 1.476a.576.576 0 01-.169-.435A.608.608 0 01.93.606a.579.579 0 01.428-.173c.172 0 .32.057.443.173l3.372 3.367a.6.6 0 01.18.424.58.58 0 01-.18.44.585.585 0 01-.434.171.592.592 0 01-.436-.173L.923 1.476z"
      ></path>
    </svg>
  );
}

export default Shuffle;
