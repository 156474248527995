import { useState } from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
// import Image from "next/image";
import imgDuck from "assets/duckHero.png";
import imgDuckShekel from "assets/duckHeroShekel.png";

const commonBoxStyle = {
  textAlign: "center",
  marginLeft: { lg: "-220px", xs: "-175px", sm: "-215px" },
  marginTop: { lg: "-260px", md: "-170px", xs: "-110px" },
  width: { sm: "220px", xs: "140px" },
  transition: "opacity 0.5s",
  position: "absolute",
  border: "4px solid #000",
  borderRadius: "26px",
  padding: { xl: "45px 24px 30px", lg: "20px 10px", xs: "10px" },
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  backgroundColor: "#000",
  "&::before": {
    content: "''",
    position: "absolute",
    bottom: "0",
    left: "66%",
    borderStyle: "solid",
    borderWidth: "18px 0 0 28px",
    borderColor: "#000000 transparent transparent",
    transform: "translate(-50%, 100%) skew(20deg)",
    transformOrigin: "top",
  },
  "&::after": {
    content: "''",
    position: "absolute",
    bottom: "0",
    left: "66%",
    borderStyle: "solid",
    borderWidth: "14.3px 0 0 22.3px",
    borderColor: "#000000 transparent transparent",
    transform: "translate(calc(-50% + 0.8px), 100%) skew(20deg)",
    transformOrigin: "top",
  },
};

const commontTextStyle = {
  fontFamily: "var(--overdoze)",
  fontSize: { lg: "34px", md: "24px", xs: "24px" },
  lineHeight: { lg: "30px", md: "20px", xs: "20px" },
  color: "#fff",
};

const coomonDuckBoxStyle = {
  width: {
    lg: "450px",
    xs: "320px",
  },
};

const HeroDuck = () => {
  const { t } = useTranslation();
  const [isFirst, setIsFirst] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = () => {
    setIsFirst(!isFirst);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", position: "relative" }}>
        <Box
          component="div"
          sx={{
            ...commonBoxStyle,
            opacity: isFirst ? 1 : 0,
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              ...commontTextStyle,
            }}
          >
            {t("shhthisissomethingbetteritsaPWA")}
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            ...commonBoxStyle,
            opacity: isFirst ? 0 : 1,
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              ...commontTextStyle,
            }}
          >
            {t("yourshekelsarewaitingforyou")}
          </Typography>
        </Box>
      </Box>
      <Box onClick={handleClick} sx={{}} style={{ display: "flex", cursor: "pointer", position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            transition: "opacity 0.5s",
            opacity: isFirst ? 1 : 0,
            ...coomonDuckBoxStyle,
          }}
        >
          <img style={{ height: "auto", width: "100%" }} src={imgDuck} alt="Duck" />
        </Box>
        <Box
          sx={{
            transition: "opacity 0.5s",
            opacity: isFirst ? 0 : 1,
            ...coomonDuckBoxStyle,
          }}
        >
          <img style={{ height: "auto", width: "100%" }} src={imgDuckShekel} alt="Duck Schekel" />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroDuck;
