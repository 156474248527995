import React from "react";
import IIcon from "interfaces/icon";

function OurApp({ color = "#6D6777" }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" fill="none" viewBox="0 0 18 12">
      <path
        fill={color}
        d="M1.632 11.417a1.45 1.45 0 01-1.064-.443A1.45 1.45 0 01.125 9.91V2.09c0-.414.148-.769.443-1.064A1.45 1.45 0 011.632.583h7.82c.415 0 .77.148 1.064.443.295.295.443.65.443 1.064v7.82c0 .415-.148.77-.443 1.064a1.45 1.45 0 01-1.064.443h-7.82zm11.75-6.25a.734.734 0 01-.756-.757V1.34a.734.734 0 01.757-.757h3.069a.734.734 0 01.757.758V4.41a.734.734 0 01-.758.757h-3.068zm.494-1.25h2.083V1.834h-2.083v2.083zm-12.244 6.25h7.82a.25.25 0 00.185-.072.25.25 0 00.072-.185V2.09a.25.25 0 00-.072-.184.25.25 0 00-.185-.073h-7.82a.25.25 0 00-.184.073.25.25 0 00-.073.184v7.82a.25.25 0 00.073.185.25.25 0 00.184.072zm1.41-1.707h5a.357.357 0 00.333-.205.345.345 0 00-.032-.398L6.99 6.045a.365.365 0 00-.301-.15.365.365 0 00-.301.15L5.125 7.73l-.844-1.124a.365.365 0 00-.301-.15.365.365 0 00-.302.15l-.937 1.25a.345.345 0 00-.032.398.357.357 0 00.333.205zm10.34 2.957a.734.734 0 01-.756-.758V7.591a.734.734 0 01.757-.758h3.069a.734.734 0 01.757.758v3.068a.734.734 0 01-.758.758h-3.068zm.494-1.25h2.083V8.084h-2.083v2.083z"
      ></path>
    </svg>
  );
}

export default OurApp;
