import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

interface IFeature {
  text: string;
  title: string;
  icon: React.ReactNode;
}

export default function Feature({ text, title, icon }: IFeature) {
  return (
    <Card sx={{ boxShadow: 0, background: "#F6F7F9", height: "100%" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "flex-start", lg: "center" },
          padding: { xs: "24px 16px", xl: "40px 16px" },
          borderRadius: "24px",
        }}
      >
        <Box
          sx={{
            width: { xs: "56px", md: "64px", lg: "80px" },
            height: { xs: "56px", md: "64px", lg: "80px" },
            borderRadius: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(180deg, #F4701B, #FF9652)",
            position: "relative",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: { xs: "5px", sm: "10px", lg: "14px" },
            fontWeight: "bold",
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            paddingLeft: { xs: "0", lg: "18px" },
            paddingTop: { xs: "24px", lg: "0" },
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "var(--cygreMedium), sans-serif",
              fontSize: { xs: "18px", md: "20px", xl: "23px" },
              paddingBottom: "12px",
              lineHeight: "40px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "CygreThin",
              fontSize: { xs: "16px", xl: "18px" },

              lineHeight: "22.5px",
            }}
          >
            {text}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
