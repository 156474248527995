import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography } from "@mui/material";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: "none",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordion-root": {
    marginBottom: theme.spacing(1),
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "orange",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "19px",
  margin: "20px",
  padding: "0 16px",
  borderLeft: "2px solid #F4701B",
}));

export default function ProfileFAQPage() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const titles = ["Lorem", "Ipsum", "Dolor"];
  const contents = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  ];

  return (
    <Box>
      <Typography variant="h5">FAQ</Typography>
      <div>
        {titles.map((title, index) => (
          <CustomAccordion
            key={`panel${index + 1}`}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <CustomAccordionSummary
              expandIcon={
                expanded === `panel${index + 1}` ? (
                  <RemoveIcon sx={{ color: "#F4701B" }} />
                ) : (
                  <AddIcon sx={{ color: "#F4701B" }} />
                )
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography
                sx={{ paddingRight: "20px", fontSize: 18, fontFamily: "var(--cygreMedium), sans-serif", color: "#000" }}
              >
                {title}
              </Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails>{contents[index]}</CustomAccordionDetails>
          </CustomAccordion>
        ))}
      </div>
    </Box>
  );
}
