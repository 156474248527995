import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import IconStar from "assets/icons/Star";
import HeaderSection from "components/HeaderSection";
import FeedbackCard from "./FeedbackCard";
import { testimonials } from "mokcup/testimonials";
import { useEffect, useRef, useState } from "react";
import DuckFootIcon from "assets/icons/DuckFootIcon";

export default function Feedback() {
  const { t } = useTranslation();
  const [showIcon1, setShowIcon1] = useState(false);
  const [showIcon2, setShowIcon2] = useState(false);
  const [showIcon3, setShowIcon3] = useState(false);
  const [showIcon4, setShowIcon4] = useState(false);

  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => setShowIcon1(true), 0);
            setTimeout(() => setShowIcon2(true), 500);
            setTimeout(() => setShowIcon3(true), 1000);
            setTimeout(() => setShowIcon4(true), 1500);
            observer.unobserve(entry.target);
          } else {
          }
        });
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <Box ref={sectionRef} sx={{ width: "100%", padding: "120px 0", position: "relative" }}>
      <Box
        sx={{
          paddingBottom: "56px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ textTransform: "uppercase" }} variant="body2">
            {t("review")}
          </Typography>
          <HeaderSection title={t("whatouruserssayaboutus")} />
        </Box>
        <Box sx={{ width: { xs: 40, lg: 63 }, height: { xs: 40, lg: 61 } }}>
          <IconStar />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", xl: "space-between" },
          gap: "16px",
        }}
      >
        {testimonials.map((testimonial, index) => (
          <FeedbackCard
            key={index}
            name={testimonial.name}
            shortDesc={testimonial.shortDesc}
            longDesc={testimonial.longDesc}
            avatar={testimonial.avatar}
            rating={testimonial.rating}
          />
        ))}
      </Box>
      {showIcon1 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: { xs: "96%", lg: "90%" },
            left: { xs: "51%", md: "40%", lg: "44%" },
            transform: "rotate(28deg)",
            opacity: showIcon1 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon2 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: { xs: "98%", lg: "95%" },
            left: { xs: "51%", md: "42%", lg: "48%" },
            transform: "rotate(17deg)",
            opacity: showIcon2 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon3 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: { xs: "101%", lg: "99%" },
            left: { xs: "46%", md: "30%", lg: "43%" },
            transform: "rotate(45deg)",
            opacity: showIcon3 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
    </Box>
  );
}
