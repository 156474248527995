import { useEffect } from "react";
import i18n from "../../i18n"; // Assuming you're using i18n for translation

export const TranslatedMeta = () => {
  useEffect(() => {
    const currentLanguage = i18n.language || "en";
    updateMetaTags(currentLanguage);

    i18n.on("languageChanged", (lng) => {
      // console.log("Language changed to", lng);
      updateMetaTags(lng);
    });
  }, []);

  return null;
};

const translatedMeta: { [key: string]: { [key: string]: string } } = {
  en: {
    title: "Ducksay PWA | Create Traffic-Generating PWAs for Affiliate Marketing",
    description:
      "Ducksay PWA helps affiliate marketers build Progressive Web Apps to drive traffic from Google and Facebook, optimizing your campaigns.",
  },
  ru: {
    title: "Ducksay PWA | Создание PWAs для Генерации Трафика в Афилиат Маркетинге",
    description:
      "Ducksay PWA помогает аффилиатам создавать прогрессивные веб-приложения (PWAs) для привлечения трафика из Google и Facebook, оптимизируя их кампании.",
  },
  ua: {
    title: "Ducksay PWA | Створення PWAs для Генерації Трафіку в Афілійованому Маркетингу",
    description:
      "Ducksay PWA допомагає афіліатам створювати прогресивні веб-додатки (PWAs) для залучення трафіку з Google і Facebook, оптимізуючи їх кампанії.",
  },
};

const updateMetaTags = (language: string) => {
  const metaTitle = document.querySelector('meta[name="title"]');
  const metaDescription = document.querySelector('meta[name="description"]');
  const odTitle = document.querySelector('meta[property="og:title"]');
  const ogDescription = document.querySelector('meta[property="og:description"]');
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  const twitterDescription = document.querySelector('meta[name="twitter:description"]');

  const { title, description } = translatedMeta[language];

  document.title = title;
  if (metaTitle) {
    metaTitle.setAttribute("content", description);
  }
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  }
  if (odTitle) {
    odTitle.setAttribute("content", title);
  }
  if (ogDescription) {
    ogDescription.setAttribute("content", description);
  }
  if (twitterTitle) {
    twitterTitle.setAttribute("content", title);
  }
  if (twitterDescription) {
    twitterDescription.setAttribute("content", description);
  }
};
