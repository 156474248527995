import { uniqueId } from "lodash";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import { useTranslation } from "react-i18next";

const useMenuItems = () => {
  const { t } = useTranslation();

  const menuItems = [
    {
      id: uniqueId(),
      title: t("dashboard"),
      icon: ViewComfyOutlinedIcon,
      href: "/dashboard",
    },
    {
      id: uniqueId(),
      title: "PWA",
      icon: PhoneIphoneOutlinedIcon,
      href: "/pwa",
    },
    {
      id: uniqueId(),
      title: t("statistics"),
      icon: AssessmentOutlinedIcon,
      href: "/stats",
    },
    // {
    //   id: uniqueId(),
    //   title: t("templates"),
    //   icon: FileCopyOutlinedIcon,
    //   href: "/templates",
    // },
    {
      id: uniqueId(),
      title: "PUSH",
      icon: NotificationsOutlinedIcon,
      href: "/push",
    },
    {
      id: uniqueId(),
      title: "FAQ",
      icon: QuizOutlinedIcon,
      href: "/profile/faq",
    },
    {
      id: uniqueId(),
      title: t("support"),
      icon: SupportOutlinedIcon,
      href: "/support",
    },
  ];

  return menuItems;
};

export default useMenuItems;
