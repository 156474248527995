import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import styled from "@emotion/styled";
import { IPwa } from "interfaces/pwas";
import showPreviewIcon from "utils/showPreviewImage";
import { RenderPWAStatus } from "components/PwaList/RenderPWAStatus";
import ActionButton from "components/ActionButton";
import MenuTableButton from "components/Table/MenuTableButton";
import { useTranslation } from "react-i18next";
import ImageIcon from "assets/icons/ImageIcon";
import { useNavigate } from "react-router";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import useIdStore from "store/pwaId";
import { useAppStore } from "store/appStore";

interface PwaListTableProps {
  filteredItems: IPwa[];
  getPwas: () => void;
}

export const PwaListTable = ({ filteredItems, getPwas }: PwaListTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = getToken();
  const { setId } = useIdStore();
  const { saveAppData } = useAppStore();
  const { triggerFetch: getPwa } = useFetch({
    url: "",
    method: "GET",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const handleEdit = async (id: string) => {
    const res = await getPwa(null, `pwa/${id}`);
    setId(res.data._id);
    saveAppData(res.data);
    // localStorage.setItem("pwaData", JSON.stringify(res.data));
    navigate(`edit-pwa/${id}`);
  };

  return (
    <Table aria-label="simple table">
      <TableHead
        sx={{
          backgroundColor: "#F6F7FB",
          borderRadius: "8px",
        }}
      >
        <TableRow
          sx={{
            backgroundColor: "#F6F7FB",
            th: {
              borderBottom: "none",
              height: "52px",
            },
          }}
        >
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <div
              style={{
                width: 40,
              }}
            />
            <TypographyStyled
              variant="subtitle2"
              style={{
                marginLeft: "5px",
              }}
            >
              {t("pwa")}
            </TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("status")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("domain")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("vertical")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("tracker")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredItems?.length === 0 ? (
          <TableRow>
            <TableCell colSpan={6}>
              <Typography variant="subtitle2">{t("noitems")}</Typography>
            </TableCell>
          </TableRow>
        ) : (
          filteredItems?.map(
            ({ _id, appName, creatives, categoryTracking, categoryVertical, domain, status, metaTags }: IPwa) => {
              // const isItemSelected = isSelected(_id);
              return (
                <TableRow
                  key={_id}
                  // onClick={(event: React.MouseEvent<unknown>) => {
                  //   return handleClick(event, _id);
                  // }}
                  role="checkbox"
                  // aria-checked={isItemSelected}
                  tabIndex={-1}
                  // selected={isItemSelected}
                >
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "5px",
                      borderBottom: "none",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(_id)}
                  >
                    {creatives.icon ? (
                      <img
                        src={showPreviewIcon(creatives.icon)}
                        alt="icon"
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: "5px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: "5px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ImageIcon />
                      </Box>
                    )}

                    <Typography
                      sx={{
                        paddingLeft: "12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                      }}
                    >
                      {appName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {status && (
                      <Box sx={{ borderBottom: "none" }}>
                        <RenderPWAStatus pwaStatus={status} />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                      }}
                    >
                      {domain?.name || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                      }}
                    >
                      {categoryVertical || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                      }}
                    >
                      {categoryTracking || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ActionButton domain={domain?.name} getPwas={getPwas} status={status} id={_id} />
                      <MenuTableButton getPwas={getPwas} id={_id} pwaMeta={metaTags} />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            }
          )
        )}
      </TableBody>
    </Table>
  );
};

const TypographyStyled = styled(Typography)(() => {
  return {
    color: "#6D6777",
    fontWeight: 500,
    fontFamily: "var(--cygreRegular), sans-serif",
  };
});
