export const countries: string[] = [
  "australia",
  "austria",
  "belgium",
  "germany",
  "denmark",
  "ireland",
  "spain",
  "italy",
  "canada",
  "luxembourg",
  "netherlands",
  "new_zealand",
  "norway",
  "united_kingdom",
  "united_states",
  "finland",
  "france",
  "switzerland",
  "sweden",
  "andorra",
  "bahamas",
  "belarus",
  "bulgaria",
  "bolivia",
  "bosnia_and_herzegovina",
  "brazil",
  "brunei_darussalam",
  "vanuatu",
  "hungary",
  "guyana",
  "hong_kong",
  "greece",
  "dominican_republic",
  "egypt",
  "israel",
  "indonesia",
  "iceland",
  "colombia",
  "south_korea",
  "costa_rica",
  "latvia",
  "lithuania",
  "macau",
  "malaysia",
  "malta",
  "morocco",
  "mexico",
  "nepal",
  "united_arab_emirates",
  "oman",
  "panama",
  "paraguay",
  "peru",
  "poland",
  "portugal",
  "puerto_rico",
  "russia",
  "romania",
  "saudi_arabia",
  "serbia",
  "singapore",
  "slovakia",
  "slovenia",
  "thailand",
  "turkey",
  "ukraine",
  "uruguay",
  "fiji",
  "philippines",
  "croatia",
  "montenegro",
  "czech_republic",
  "chile",
  "ecuador",
  "estonia",
  "japan",
  "south_georgia_and_the_south_sandwich_islands",
  "azerbaijan",
  "albania",
  "algeria",
  "american_samoa",
  "anguilla",
  "angola",
  "antarctica",
  "antigua_and_barbuda",
  "argentina",
  "armenia",
  "aruba",
  "afghanistan",
  "bangladesh",
  "barbados",
  "bahrain",
  "belize",
  "benin",
  "bermuda",
  "bonaire_sint_eustatius_and_saba",
  "botswana",
  "british_indian_ocean_territory",
  "burkina_faso",
  "burundi",
  "bhutan",
  "bolivarian_republic_of_venezuela",
  "british_virgin_islands",
  "virgin_islands_us",
  "vietnam",
  "gabon",
  "haiti",
  "gambia",
  "ghana",
  "guadeloupe",
  "guatemala",
  "guinea",
  "guinea_bissau",
  "guernsey",
  "gibraltar",
  "honduras",
  "grenada",
  "greenland",
  "georgia",
  "guam",
  "jersey",
  "djibouti",
  "dominica",
  "zambia",
  "western_sahara",
  "zimbabwe",
  "india",
  "jordan",
  "iraq",
  "iran",
  "yemen",
  "cape_verde",
  "cambodia",
  "cameroon",
  "kenya",
  "kyrgyzstan",
  "kiribati",
  "cocos_keeling_islands",
  "comoros",
  "congo",
  "congo_democratic_republic_of_the",
  "north_korea",
  "ivory_coast",
  "cuba",
  "kuwait",
  "curacao",
  "laos",
  "lesotho",
  "lebanon",
  "libya",
  "liberia",
  "liechtenstein",
  "mauritius",
  "mauritania",
  "madagascar",
  "mayotte",
  "malawi",
  "mali",
  "micronesia_federated_states_of",
  "maldives",
  "martinique",
  "marshall_islands",
  "mozambique",
  "moldova_republic_of",
  "monaco",
  "mongolia",
  "montserrat",
  "myanmar",
  "namibia",
  "nauru",
  "niger",
  "nigeria",
  "nicaragua",
  "niue",
  "new_caledonia",
  "bouvet_island",
  "isle_of_man",
  "norfolk_island",
  "christmas_island",
  "heard_island_and_mcdonald_islands",
  "cayman_islands",
  "cook_islands",
  "turks_and_caicos_islands",
  "pakistan",
  "palau",
  "palestinian_territory",
  "holy_see_vatican_city_state",
  "papua_new_guinea",
  "pitcairn",
  "republic_of_macedonia",
  "reunion",
  "rwanda",
  "samoa",
  "san_marino",
  "sao_tome_and_principe",
  "saint_helena_ascension_and_tristan_da_cunha",
  "northern_mariana_islands",
  "saint_barthelemy",
  "saint_martin_french_part",
  "senegal",
  "saint_vincent_and_the_grenadines",
  "saint_kitts_and_nevis",
  "saint_lucia",
  "saint_pierre_and_miquelon",
  "seychelles",
  "sint_maarten_dutch_part",
  "syrian_arab_republic",
  "solomon_islands",
  "somalia",
  "sudan",
  "suriname",
  "sierra_leone",
  "tajikistan",
  "taiwan",
  "tanzania_united_republic_of",
  "timor_leste",
  "togo",
  "tokelau",
  "tonga",
  "trinidad_and_tobago",
  "tuvalu",
  "tunisia",
  "turkmenistan",
  "uganda",
  "uzbekistan",
  "wallis_and_futuna",
  "faroe_islands",
  "falkland_islands_malvinas",
  "french_guiana",
  "french_polynesia",
  "french_southern_territories",
  "central_african_republic",
  "chad",
  "svalbard_and_jan_mayen",
  "sri_lanka",
  "equatorial_guinea",
  "aland_islands",
  "el_salvador",
  "eritrea",
  "eswatini",
  "ethiopia",
  "south_africa",
  "south_ossetia",
  "south_sudan",
  "jamaica",
  "abkhazia",
];
