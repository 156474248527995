import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchBar from "components/SearchBar";
import { fetchCountries } from "utils/fetchCountries";
import FilterButton from "components/Filter/FilterButton";
import { IAppData, ICountry, INotification, IPushTableProps } from "interfaces/push";
import NotificationsList from "./NotificationsList";
import { AppDataMockup } from "interfaces/mockupApp";

const menuData = [
  {
    title: "status",
    codeTop: "status",
    subItems: [
      { title: "stopped", id: "stopped", code: "stopped" },
      { title: "completed", id: "completed", code: "completed" },
      { title: "scheduled", id: "scheduled", code: "scheduled" },
    ],
  },
  {
    title: "vertical",
    codeTop: "categoryVertical",
    subItems: [
      { title: "nutra", id: "3.1", code: "nutra" },
      { title: "betting", id: "3.2", code: "betting" },
      { title: "dating", id: "3.3", code: "dating" },
      { title: "gambling", id: "3.4", code: "gambling" },
      { title: "trading", id: "3.4", code: "trading" },
    ],
  },
  { title: "country", codeTop: "country" },
  { title: "date", codeTop: "date" },
  { title: "conversion", codeTop: "conversion" },
];

const PushTable = ({ pushes, pwas, getNot }: IPushTableProps) => {
  const [dataPushes, setDataPushes] = useState<any>([]);
  const [filteredPushes, setFilteredPushes] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string[]>([]); // Состояние для хранения выбранных фильтров по статусу
  const [categoryVerticalFilter, setCategoryVerticalFilter] = useState<string[]>([]); // Состояние для хранения выбранных фильтров по categoryVertical
  const [countries, setCountries] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setDataPushes(pushes);
    setFilteredPushes(pushes); // Обновление фильтрованных пушей вместе с основными
  }, [pushes]);

  useEffect(() => {
    const getCountries = async () => {
      const data = await fetchCountries();
      const formattedOptions = data.map((country: ICountry) => ({
        country: country.name,
        flag: country.flag,
      }));
      setCountries(formattedOptions);
    };
    getCountries();
  }, []);

  useEffect(() => {
    let filtered = dataPushes;

    // Поиск по названию
    if (searchQuery !== "") {
      filtered = filtered.map((item: { notifications: INotification[]; appData: IAppData }) => ({
        ...item,
        notifications: item?.notifications?.filter((notification: INotification) =>
          notification?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      }));
    }

    // Фильтрация по статусу
    if (statusFilter.length > 0) {
      filtered = filtered.map((item: { notifications: INotification[]; appData: IAppData }) => ({
        ...item,
        notifications: item.notifications.filter((notification: INotification) => {
          if (statusFilter.includes("stopped") && notification.canceled) {
            return true;
          }
          if (statusFilter.includes("completed") && notification.completed_at) {
            return true;
          }
          if (statusFilter.includes("scheduled") && notification.send_after > Math.floor(Date.now() / 1000)) {
            return true;
          }
          return false;
        }),
      }));
    }

    // Фильтрация по categoryVertical
    if (categoryVerticalFilter.length > 0) {
      filtered = filtered.filter((item: { notifications: INotification[]; appData: AppDataMockup }) => {
        console.log("appData structure:", item.appData);
        return categoryVerticalFilter.includes(item.appData.categoryVertical?.toLowerCase().trim());
      });
    }

    setFilteredPushes(filtered);
  }, [searchQuery, statusFilter, categoryVerticalFilter, dataPushes]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleApplyFilter = (filter: any) => {
    // Обновляем выбранные статусы фильтров
    if (filter?.status?.length > 0) {
      setStatusFilter(filter.status);
    } else {
      setStatusFilter([]);
    }

    // Обновляем выбранные фильтры по categoryVertical
    if (filter?.categoryVertical?.length > 0) {
      setCategoryVerticalFilter(filter.categoryVertical.map((v: string) => v.toLowerCase().trim()));
    } else {
      setCategoryVerticalFilter([]);
    }
  };

  const displayPushes = filteredPushes.filter(
    (item: { notifications: INotification[]; appData: IAppData }) => item.notifications && item.notifications.length > 0
  );

  return (
    <Box>
      <Box sx={{ width: { sm: "auto" } }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "normal",
              width: "100%",
              marginBottom: "16px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <SearchBar onSearch={handleSearch} placeholder={t("searchname")} />
            <FilterButton
              menuData={menuData}
              filter={{ status: statusFilter, categoryVertical: categoryVerticalFilter }}
              handleApply={handleApplyFilter}
            />
          </Box>
        </Grid>
        <Grid container spacing={2} rowSpacing={3} sx={{ marginTop: 0 }}>
          {displayPushes.length > 0 ? (
            displayPushes.map((item: { notifications: INotification[]; appData: IAppData }, index: number) => (
              <NotificationsList
                key={index}
                getNot={getNot}
                notifications={item.notifications}
                countries={countries}
                appData={item.appData}
                pwas={pwas}
              />
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight={600}>
                {t("noitems")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default PushTable;
