import React from "react";

function Search() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill="#6D6777"
        d="M13.25 14.417L8.583 9.75a5.08 5.08 0 01-3.167 1.083c-1.513 0-2.794-.524-3.843-1.572C.524 8.21 0 6.93 0 5.417 0 3.903.524 2.62 1.573 1.572 2.622.524 3.903 0 5.417 0 6.93 0 8.212.524 9.26 1.573c1.048 1.048 1.572 2.33 1.572 3.844A5.08 5.08 0 019.75 8.583l4.688 4.688c.152.153.229.34.229.562a.8.8 0 01-.25.584.79.79 0 01-.584.229.79.79 0 01-.583-.23zm-7.833-5.25c1.041 0 1.927-.365 2.656-1.094.73-.73 1.094-1.615 1.094-2.656 0-1.042-.365-1.928-1.094-2.657-.73-.729-1.615-1.093-2.656-1.093-1.042 0-1.928.364-2.657 1.093-.729.73-1.093 1.615-1.093 2.657 0 1.041.364 1.927 1.093 2.656.73.73 1.615 1.094 2.657 1.094z"
      ></path>
    </svg>
  );
}

export default Search;
