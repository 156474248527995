import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import Email from "assets/icons/Email";
import ToastMessage from "components/ToastMessage";

interface IForgotPassword {
  toggleMode: (newMode: string) => void;
}

export function ForgotPassword({ toggleMode }: IForgotPassword) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { loading, triggerFetch } = useFetch({
    url: "auth/forgot-password",
    method: "POST",
    body: { email, locale: i18n.language },
    context: { no401Redirect: true },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const result = await triggerFetch();
    if (result.success) {
      ToastMessage({ mode: "info", message: result.message });
    } else {
      ToastMessage({ mode: "error", message: result.error });
    }
  };

  return (
    <Box sx={{ width: "100%", paddingTop: "24px" }}>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Typography
          sx={{
            fontSize: { xs: 34, md: 38 },
            lineHeight: "34px",
            textAlign: "center",
            paddingBottom: "12px",
          }}
          variant="subtitle1"
        >
          {t("passwordrecovery")}
        </Typography>
        <Typography sx={{ textAlign: "center", marginBottom: "10px" }} variant="subtitle2">
          {t("enteryouremail")}
        </Typography>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder={t("email")}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleInputChange}
          iconStart={<Email />}
          customBgColor="#F6F7F9"
        />
        <Box sx={{ paddingTop: "15px" }}>
          <CustomButton
            loading={loading}
            customFontSize="18px"
            height={57}
            width="100%"
            loginDisabled={!isEmailValid || loading}
            textButton={t("buttonpasswordrecovery")}
            customBgColor="#000"
            customHoverColor="#F4701B"
          />
        </Box>
        <Box sx={{ paddingTop: "10px", cursor: "pointer" }} onClick={() => toggleMode("login")}>
          <Typography variant="subtitle2">
            <span style={{ paddingRight: "5px" }}>&#8592;</span>
            {t("goBack")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
