import React, { useEffect, useState } from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { fetchCountries } from "utils/fetchCountries";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/appStore";
import { usePushStore } from "store/pushStore";
import { Typography } from "@mui/material";

interface CountryOption {
  value: string;
  label: string;
  flag: string;
}

const GeoMultiSelect: React.FC = () => {
  const [options, setOptions] = useState<CountryOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<CountryOption>>([]);
  const { t } = useTranslation();
  const { saveAppData, appData } = useAppStore();
  const savePushData = usePushStore((state) => state.savePushData);

  useEffect(() => {
    const getCountries = async () => {
      const data = await fetchCountries();
      const formattedOptions = data.map((country: any) => ({
        value: country.name,
        label: country.name,
        flag: country.flag,
      }));
      setOptions(formattedOptions);
    };
    getCountries();
  }, []);

  useEffect(() => {
    if (Array.isArray(appData.geo)) {
      const countries = appData.geo
        .join(",")
        .split(",")
        .map((country) => country.trim());

      const geoArray = countries
        .map((country) => {
          const found = options.find((option) => option.value === country);
          return found || null;
        })
        .filter(Boolean) as MultiValue<CountryOption>;

      setSelectedOptions(geoArray);
    }
  }, [appData.geo, options]);

  const handleChange = (selected: MultiValue<CountryOption>, _actionMeta: ActionMeta<CountryOption>) => {
    setSelectedOptions(selected);
    handleGeoChange(selected);
  };

  const handleGeoChange = (selected: MultiValue<CountryOption>) => {
    const geoArray = selected.map((country) => country.value);
    saveAppData({ geo: geoArray.join(", ") });
    savePushData({ geo: geoArray });
  };

  const customSingleValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      {props.data.label}
    </div>
  );

  const customMultiValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center", backgroundColor: "#F6F7FB", padding: "3px" }}>
      <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      {props.data.label}
      <span onClick={() => props.removeProps.onClick()} style={{ marginLeft: "10px", cursor: "pointer" }}>
        ×
      </span>
    </div>
  );

  return (
    <div style={{ width: "100%", marginRight: "10px" }}>
      <Typography
        component="label"
        sx={{
          textAlign: "left",
          fontSize: "12px",
          color: "#2E263D",
          paddingLeft: "5px",
          fontFamily: "var(--cygreRegular), sans-serif",
          marginBottom: "-4px",
        }}
      >
        {t("geo")}
      </Typography>
      <Select
        options={options}
        isMulti
        id="geo-select"
        onChange={handleChange}
        placeholder={t("setGeo")}
        value={selectedOptions} // Используйте выбранные опции
        components={{ SingleValue: customSingleValue, MultiValue: customMultiValue }}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
            backgroundColor: "#F6F7FB !important",
            borderRadius: "8px",
            marginTop: "16px",
            marginBottom: "8px",
            height: "40px",
          }),
          control: (base) => ({
            ...base,
            backgroundColor: "#F6F7FB !important",
            border: "none !important",
            fontSize: "14px",
            fontFamily: "var(--cygreRegular), sans-serif",
            borderRadius: "8px",
          }),
          multiValue: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            padding: "5px",
            backgroundColor: "#F6F7FB !important",
            border: "none !important",
            borderRadius: "4px",
            margin: "2px",
            flexShrink: 0,
          }),
          multiValueLabel: (base) => ({ ...base, display: "flex", alignItems: "center", backgroundColor: "#F6F7FB" }),
          multiValueRemove: (base) => ({ ...base, cursor: "pointer" }),
          indicatorsContainer: (base) => ({ ...base, color: "#6D6777" }),
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }),
        }}
      />
    </div>
  );
};

export default GeoMultiSelect;
