import { create } from "zustand";

interface ModalState {
  isOpen: boolean;
  pagePath: string | null;
  openModal: () => void;
  closeModal: () => void;
  handlePathChange: (path: string | null) => void;
}

const useCautionStore = create<ModalState>((set) => ({
  isOpen: false,
  pagePath: null,
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  handlePathChange: (path) => set({ pagePath: path }),
}));

export default useCautionStore;
