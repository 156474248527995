import { styled } from "@mui/material";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers";
import { FC, useState } from "react";
import { Dayjs } from "dayjs";
import { usePushStore } from "store/pushStore";

interface TimePickerProps {
  value?: Dayjs | null;
  onChange?: (newValue: Dayjs | null) => void;
}

const CustomTimePicker = styled(MuiTimePicker)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    backgroundColor: "#F6F7FB",
    borderRadius: 8,
    height: 40,
    color: "black",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    padding: "10px 14px",
    fontSize: "14px",
    height: "40px",
    fontWeight: "400",
    fontFamily: "var(--cygreMedium), sans-serif",
    textTransform: "uppercase",
    color: "black",
  },
}));

const TimePicker: FC<TimePickerProps> = ({ value, onChange }) => {
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(value || null);
  const savePushData = usePushStore((state) => state.savePushData);

  const handleTimeChange = (newValue: Dayjs | null) => {
    setSelectedTime(newValue);

    if (onChange) {
      onChange(newValue);
    } else {
      if (newValue) {
        const standartTime = newValue.format("HH:mm");

        savePushData({ standartTime });
      }
    }
  };

  return (
    <CustomTimePicker
      value={selectedTime}
      onChange={handleTimeChange}
      ampm={false}
      slotProps={{
        textField: {
          inputProps: { disabled: true },
        },
      }}
    />
  );
};

export default TimePicker;
