import React, { useState, useEffect } from "react";
import CustomTable from "components/CustomTable";
import { useAppStore } from "store/appStore";
import { Box, Avatar, Tooltip, styled, TooltipProps, tooltipClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import Trash from "assets/icons/Trash";
import Upload from "assets/icons/Upload";
import Shuffle from "assets/icons/Shuffle";
import {
  handleDownloadAvatar,
  handleGenerateRandomAvatarForComment,
  updateCommentAvatar,
  deleteComment,
} from "utils/avatarUtils";
import AddCommentModal from "./AddCommentModal";
import { translateText } from "utils/translationService";
import EditIcon from "assets/icons/EditIcon";

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#2E263D",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: "14px",
    backgroundColor: "#2E263D",
  },
}));

const CommentsTable = () => {
  const { t } = useTranslation();
  const { appData, saveAppData } = useAppStore();
  const [editComment, setEditComment] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const translateComments = async () => {
      const translatedComments = await Promise.all(
        appData.comments.map(async (comment) => {
          const translatedComment = await translateText({ comment: comment.comment }, appData.language);
          return { ...comment, comment: translatedComment.comment };
        })
      );
      saveAppData({ comments: translatedComments });
    };

    if (appData.language) {
      translateComments();
    }
  }, [appData.language]);

  const handleTrashClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    event.stopPropagation();
    deleteComment(row.author, appData.comments, saveAppData);
  };

  const handleUploadClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    event.stopPropagation();
    document.getElementById(`avatarInput-${row.author}`)?.click();
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    event.stopPropagation();
    setEditComment(row);
    setOpenModal(true);
  };

  const handleGenerateAvatarClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    event.stopPropagation();
    handleGenerateRandomAvatarForComment(row.author, appData.comments, saveAppData);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const columns: any[] = [
    { id: "author", label: "author", align: "center" },
    {
      id: "comment",
      label: "comment",
      align: "left",
      render: (row: any) => row.comment && truncateText(row.comment, 17),
    },
    { id: "rating", label: "rating", align: "center" },
    {
      id: "avatar",
      label: "avatar",
      align: "left",
      render: (row: any) => <Avatar sx={{ width: 36, height: 36 }} src={row.avatar} alt={row.author} />,
    },
    {
      id: "",
      label: "",
      align: "left",
      render: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BootstrapTooltip title={t("download")} placement="top">
            <Box onClick={(event) => handleUploadClick(event, row)}>
              <Upload color={"#6D6777"} />
            </Box>
          </BootstrapTooltip>

          <input
            id={`avatarInput-${row.author}`}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => {
              handleDownloadAvatar(
                e,
                (newAvatar) => updateCommentAvatar(row.author, newAvatar, appData.comments, saveAppData),
                (updateFn) => {}
              );
            }}
          />

          <Box
            sx={{
              padding: "0 14px",
              fontSize: "15px",
              fontFamily: "var(--cygreMedium), sans-serif",
              color: "black",
            }}
          >
            {t("or")}
          </Box>
          <BootstrapTooltip title={t("generaterandomly")} placement="top">
            <Box className="generate-tooltip" onClick={(event) => handleGenerateAvatarClick(event, row)}>
              <Shuffle color="#6D6777" />
            </Box>
          </BootstrapTooltip>
        </Box>
      ),
    },
    {
      id: "edit",
      label: "",
      align: "left",
      render: (row: any) => (
        <Box onClick={(event) => handleEditClick(event, row)}>
          <EditIcon />
        </Box>
      ),
    },
    {
      id: "trash",
      label: "",
      align: "left",
      render: (row: any) => (
        <Box onClick={(event) => handleTrashClick(event, row)}>
          <Trash color={""} />
        </Box>
      ),
    },
  ];

  const handleRowClick = () => {
    // console.log("Row clicked");
  };

  return (
    <>
      {appData.comments.length > 0 ? (
        <>
          <CustomTable columns={columns} rows={appData.comments} onRowClick={handleRowClick} />
        </>
      ) : (
        <> {t("nocommentsadded")}</>
      )}
      <AddCommentModal
        editComment={editComment}
        title={t("changeComment")}
        open={openModal}
        setOpen={setOpenModal}
        translations={undefined}
        languages={undefined}
      />
    </>
  );
};

export default CommentsTable;
