import { useTranslation } from "react-i18next";

export default function useLinks() {
  const { t } = useTranslation();
  return [
    {
      id: "create-pwa",
      title: t("howtocreateaPWA"),
      href: "#create-pwa",
    },
    {
      id: "main-features",
      title: t("mainfeatures"),
      href: "#main-features",
    },
    {
      id: "advantages-and-benefits",
      title: t("advantagesandbenefits"),
      href: "#advantages-and-benefits",
    },
    {
      id: "price",
      title: t("price"),
      href: "#price",
    },
  ];
}
