import { Typography } from "@mui/material";

interface IHeaderSection {
  title: string;
}

export default function HeaderSection({ title }: IHeaderSection) {
  return (
    <Typography
      sx={{
        textAlign: "left",
        color: "#000",
        fontWeight: 500,
        fontSize: { xl: "56px", lg: "44px", md: "36px", xs: "36px" },
        lineHeight: { xl: "64px", lg: "55px", md: "45px", xs: "45px" },
      }}
    >
      {title}
    </Typography>
  );
}
