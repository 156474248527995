import { Box, styled, Switch } from "@mui/material";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#F4701B",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#bdbdbd",
    opacity: 1,
  },
}));

interface ICheckBox {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const SwitchComponent: React.FC<ICheckBox> = ({ checked, onChange }) => {
  return (
    <Box>
      <CustomSwitch checked={checked} onChange={onChange} inputProps={{ "aria-label": "controlled" }} />
    </Box>
  );
};

export default SwitchComponent;
