import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSetupStore, { StepStatus } from "store/setupSettingsStore";

const SetupProcess: React.FC = () => {
  const { t } = useTranslation();
  const steps = useSetupStore((state) => state.steps);

  const getStatusColor = (status: StepStatus) => {
    switch (status) {
      case "notconfigured":
        return "black";
      case "inprogress":
        return "orange";
      case "ready":
        return "green";
      default:
        return "black";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "0 20px" }}>
      <Typography
        sx={{
          fontSize: "18px",
          lineHeight: "32px",
          fontFamily: "var(--cygreMedium), sans-serif",
          paddingBottom: "32px",
        }}
      >
        {t("custprocess")}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {(Object.keys(steps) as Array<keyof typeof steps>).map((step) => (
          <Box
            key={step}
            sx={{
              paddingBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#2E263D",
                fontSize: "14px",
                fontFamily: "var(--cygreRegular), sans-serif",
              }}
            >
              {t(step)}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "var(--cygreRegular), sans-serif",
                color: getStatusColor(steps[step]),
              }}
            >
              {t(steps[step])}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SetupProcess;
