import React from "react";

function DuckFootIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="39"
      fill="none"
      viewBox="0 0 40 39"
    >
      <path
        fill="#F4701B"
        d="M24.365 1.688c3.205.834 8.052 2.664 8.052 2.664s3.271 1.047 6.614 2.418c.596.244.643.977.443 1.59-.179.547-.566.759-1.094.99-.464.203-.79.066-1.285.168-1.3.266-2.068.515-3.141 1.295-1.044.76-1.522 1.381-2.156 2.505-1.161 2.061-1.026 3.633-.878 5.994.108 1.732.32 2.857.953 4.473.411 1.049 1.612 2.301.777 3.057l-.425.385c-.365.33-2.204-.418-3.634-.47-.89-.033-2.28.046-2.28.046s-3.3.335-5.116 1.314c-1.804.972-3.01 1.67-3.89 3.521-.807 1.699-.455 2.438-.54 4.168-.053 1.042-.122 2.22-.885 2.321-.572.076-.901-.262-1.3-.703-.867-.957-2.714-3.106-4.148-5.314-1.586-2.442-2.217-3.974-3.445-6.614-1.645-3.538-2.404-5.602-3.66-9.296-.958-2.82-1.71-4.366-2.19-7.306C.815 6.934.745 4.187.745 4.187c-.05-.498-.32-2.309.042-3.047C1.239.22 2.446.483 2.84.271L7.844.055l8.472.072s4.95.755 8.049 1.561z"
      ></path>
    </svg>
  );
}

export default DuckFootIcon;
