import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "components/Button";
import { Typography } from "@mui/material";
import { useCreativesUplodStore, ICreative } from "store/Creatives";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "520px",
  maxHeight: "80vh",
};

interface IModalCheckDomain {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function OwnPicture({ open, setOpen }: IModalCheckDomain) {
  const { t } = useTranslation();
  const handleClose = () => {
    setLocalIcon(null);
    setLocalPreviews([]);
    setOpen(false);
  };

  const { addCreativeUpload } = useCreativesUplodStore();
  const [localIcon, setLocalIcon] = useState<File | null>(null);
  const [localPreviews, setLocalPreviews] = useState<File[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      if (name === "icon-upload") {
        setLocalIcon(files[0]);
      } else if (name === "preview-upload") {
        setLocalPreviews(Array.from(files));
      }
    }
  };

  const handleCopyImage = async () => {
    const newCreative: ICreative = {
      icon: localIcon,
      previews: localPreviews,
    };
    addCreativeUpload(newCreative);
    setLocalIcon(null);
    setLocalPreviews([]);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              textAlign: "left",
              fontSize: "24px",
              lineHeight: "45px",
              fontFamily: "var(--cygreMedium), sans-serif",
              color: "#2E263D",
            }}
          >
            {t("uploadyourimages")}
          </Typography>
          <Box aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "var(--cygreRegular), sans-serif",
              color: "#2E263D",
            }}
          >
            {t("selectfile")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "24px",
          }}
        >
          {localIcon ? (
            <Box
              component="img"
              sx={{
                width: "100px",
                height: "100px",
                border: "1px dashed #C9D0D8",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              src={URL.createObjectURL(localIcon)}
              alt="icon"
            />
          ) : (
            <Box
              sx={{
                width: "100px",
                height: "100px",
                border: "1px dashed #C9D0D8",
                display: "flex",
                backgroundColor: "#F6F7FB",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              <input
                type="file"
                id="icon-upload"
                name="icon-upload"
                onChange={handleInputChange}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "#2E263D",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "var(--cygreRegular), sans-serif",
                }}
              >
                {t("icon")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            paddingTop: "24px",
          }}
        >
          {localPreviews.length > 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px dashed #C9D0D8",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "center",
                position: "relative",
                overflowX: "scroll",
              }}
            >
              {localPreviews.map((file, index) => (
                <Box
                  key={index}
                  component="img"
                  sx={{ width: "100px", margin: "0 5px" }}
                  src={URL.createObjectURL(file)}
                  alt={`preview-${index}`}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px dashed #C9D0D8",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              <input
                type="file"
                multiple
                id="preview-upload"
                name="preview-upload"
                onChange={handleInputChange}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  margin: "auto",
                  color: "#2E263D",
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "var(--cygreRegular), sans-serif",
                }}
              >
                {t("preview")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          <CustomButton
            textButton={t("cancel")}
            height={40}
            width="100%"
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleClose}
          />
          <Box sx={{ width: "24px" }}></Box>
          <CustomButton
            textButton={t("loadButton")}
            height={40}
            width="100%"
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleCopyImage}
          />
        </Box>
      </Box>
    </Modal>
  );
}
