export const testimonials = [
  {
    name: "testimonials.1.name",
    shortDesc: "testimonials.1.title",
    longDesc: "testimonials.1.description",
    avatar: "/avatar1.png",
    rating: 5,
  },
  {
    name: "testimonials.2.name",
    shortDesc: "testimonials.2.title",
    longDesc: "testimonials.2.description",
    avatar: "/avatar2.png",
    rating: 5,
  },
  {
    name: "testimonials.3.name",
    shortDesc: "testimonials.3.title",
    longDesc: "testimonials.3.description",
    avatar: "/avatar3.png",
    rating: 5,
  },
  {
    name: "testimonials.4.name",
    shortDesc: "testimonials.4.title",
    longDesc: "testimonials.4.description",
    avatar: "/avatar4.png",
    rating: 5,
  },

  {
    name: "testimonials.5.name",
    shortDesc: "testimonials.5.title",
    longDesc: "testimonials.5.description",
    avatar: "/avatar4.png",
    rating: 5,
  },
  {
    name: "testimonials.6.name",
    shortDesc: "testimonials.6.title",
    longDesc: "testimonials.6.description",
    avatar: "/avatar4.png",
    rating: 5,
  },
];
