function getCenterCoordinates(coordinates: any): { latitude: number; longitude: number } {
  const flattenCoordinates = (coords: any): number[][] => {
    return coords.flatMap((coord: any) => (Array.isArray(coord[0]) ? flattenCoordinates(coord) : [coord]));
  };

  const allCoordinates = flattenCoordinates(coordinates);
  const total = allCoordinates.reduce(
    (acc, [lon, lat]) => ({ longitude: acc.longitude + lon, latitude: acc.latitude + lat }),
    { longitude: 0, latitude: 0 }
  );
  const count = allCoordinates.length;

  return {
    latitude: total.latitude / count,
    longitude: total.longitude / count,
  };
}

export function getCountryWithCenterData(array: any[], worldMap: any) {
  return array.map((item) => {
    const countryFeature = worldMap.features.find((feature: any) => feature.properties.admin === item.country);

    if (countryFeature) {
      const coordinates = countryFeature.geometry.coordinates;
      const center = getCenterCoordinates(coordinates);

      return {
        country: item.country,
        latitude: center.latitude,
        longitude: center.longitude,
        coordinates: coordinates,
      };
    } else {
      return {
        country: item.country,
        latitude: null,
        longitude: null,
        coordinates: null,
      };
    }
  });
}
