import React from "react";

function Regs() {
  return (
    <div
      style={{
        background: "rgba(244, 112, 27, 0.16)",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg width="36" height="36" fill="none" viewBox="0 0 36 36">
        <mask
          id="mask0_1147_31044"
          style={{ maskType: "alpha" }}
          width="36"
          height="36"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M0 0H36V36H0z"></path>
        </mask>
        <g mask="url(#mask0_1147_31044)">
          <path
            fill="#F4701B"
            d="M9 29.424a2.333 2.333 0 01-1.711-.712 2.333 2.333 0 01-.712-1.711c0-.666.237-1.237.712-1.711A2.333 2.333 0 019 24.578c.666 0 1.237.237 1.711.712.475.474.712 1.045.712 1.711 0 .666-.237 1.237-.712 1.711A2.333 2.333 0 019 29.424zm0-9a2.333 2.333 0 01-1.711-.712 2.333 2.333 0 01-.712-1.711c0-.666.237-1.237.712-1.711A2.333 2.333 0 019 15.578c.666 0 1.237.237 1.711.712.475.474.712 1.045.712 1.711 0 .666-.237 1.237-.712 1.711A2.333 2.333 0 019 20.424zm0-9a2.333 2.333 0 01-1.711-.712 2.333 2.333 0 01-.712-1.711c0-.666.237-1.237.712-1.711A2.333 2.333 0 019 6.578c.666 0 1.237.237 1.711.712.475.474.712 1.045.712 1.711 0 .666-.237 1.237-.712 1.711A2.333 2.333 0 019 11.424zm9 0a2.333 2.333 0 01-1.711-.712 2.333 2.333 0 01-.712-1.711c0-.666.237-1.237.712-1.711A2.333 2.333 0 0118 6.578c.666 0 1.237.237 1.711.712.475.474.712 1.045.712 1.711 0 .666-.237 1.237-.712 1.711a2.333 2.333 0 01-1.711.712zm9 0a2.333 2.333 0 01-1.711-.712 2.333 2.333 0 01-.712-1.711c0-.666.237-1.237.712-1.711A2.333 2.333 0 0127 6.578c.666 0 1.237.237 1.711.712.475.474.712 1.045.712 1.711 0 .666-.237 1.237-.712 1.711a2.333 2.333 0 01-1.711.712zm-9 9a2.333 2.333 0 01-1.711-.712 2.333 2.333 0 01-.712-1.711c0-.666.237-1.237.712-1.711A2.333 2.333 0 0118 15.578c.666 0 1.237.237 1.711.712.475.474.712 1.045.712 1.711 0 .666-.237 1.237-.712 1.711a2.333 2.333 0 01-1.711.712zm1.673 7.644v-2.042a1.35 1.35 0 01.406-.972l7.737-7.7c.187-.186.388-.317.605-.393.216-.076.432-.114.648-.114a1.821 1.821 0 011.286.531l1.387 1.402c.162.186.287.388.375.605.089.218.133.435.133.653 0 .217-.04.438-.121.663-.08.225-.21.43-.387.617l-7.699 7.7a1.352 1.352 0 01-.972.406H21.03c-.384 0-.706-.13-.966-.39s-.39-.581-.39-.966zm1.789-.433h1.425l4.869-4.892-.69-.712-.698-.704-4.906 4.883v1.425zm5.604-5.604l-.698-.704 1.388 1.416-.69-.712z"
          ></path>
        </g>
      </svg>
    </div>
  );
}

export default Regs;
