import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import NSInputs from "./NsInputs";
import useFetch from "hooks/useFetch";
import AnswerModal from "./AnswerModal";
import { getToken } from "utils/getToken";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

interface IModalCheckDomain {
  open: boolean;
  setOpen: (value: boolean) => void;
  nsFirst: string;
  nsSecond: string;
  status: string;
  id: string;
}

export default function ModalCheckDomain({ open, setOpen, nsFirst, nsSecond, status, id }: IModalCheckDomain) {
  const handleClose = () => setOpen(false);
  const [AnswerCheckModal, setAnswerCheckModal] = useState(false);
  const token = getToken();

  const { loading, triggerFetch } = useFetch({
    url: `domain/${id}/check`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleCheck = async () => {
    handleClose();
    const result = await triggerFetch();

    if (result) {
      handleClose();
      setAnswerCheckModal(true);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-check-domain-title"
        aria-describedby="modal-check-domain-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Box aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box>
          <NSInputs nsFirst={nsFirst} nsSecond={nsSecond} status={status} handleClose={handleCheck} id={id} />
        </Box>
      </Modal>
      <AnswerModal open={AnswerCheckModal} setOpen={setAnswerCheckModal} />
    </>
  );
}
