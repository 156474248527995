import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import wallpaper from "assets/wallpaper.png";
import statusbar from "assets/statusbar.png";
import Arrow from "assets/icons/Arrow";
import { usePushStore } from "store/pushStore";
import { IPushTexts } from "interfaces/mockupApp";
import ImageIcon from "assets/icons/ImageIcon";

interface IPhonePushContent {
  isModal: boolean;
  openModal?: boolean;
  setOpenModal?: (value: boolean) => void;
}

const PhonePushContent = ({ isModal }: IPhonePushContent) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const { pushData } = usePushStore();
  // const keyLang = pushData.language || "en";

  const pushTitle: IPushTexts = {
    title: pushData.headings,
  };
  const pushDescription: IPushTexts = {
    title: pushData.contents,
  };

  const formattedTime = `${hours}:${minutes}`;

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: isModal ? "100%" : "401px",
        overflow: "hidden",
        height: isModal ? "103%" : "100%",
        padding: "0px 10px 10px 10px",
        zIndex: "3",
        backgroundImage: `url(${wallpaper})`,
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "320px",
          right: isModal ? "3px" : "8px",
          backgroundImage: `url(${statusbar})`,
          height: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      ></Box>

      <Box sx={{ position: "relative", textAlign: "center", color: "white", fontSize: "55px", top: "100px" }}>
        {formattedTime}
      </Box>

      <Box
        sx={{
          position: "relative",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderRadius: "10px",
          padding: "10px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(10px)",
          width: "300px",
          textAlign: "center",
          justifyContent: "space-between",
          top: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          overflowWrap: "break-word",
          height: isExpanded ? "auto" : "75px",
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", width: "100%" }}>
          <Box>
            {pushData.icon ? (
              <img
                style={{ width: "35px", borderRadius: "50%" }}
                src={pushData.icon ? String(pushData.icon) : undefined}
                alt="icon"
              />
            ) : (
              <Box sx={{ borderRadius: "50%" }}>
                <ImageIcon />
              </Box>
            )}
          </Box>

          <Box sx={{ marginLeft: "10px", flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#2E263D",
                fontFamily: "var(--cygreMedium), sans-serif",
                fontWeight: "500",
                width: "200px",
                whiteSpace: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {pushTitle.title}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#2E263D",
                fontFamily: "var(--cygreMedium), sans-serif",
                fontWeight: "400",
                width: "200px",
                whiteSpace: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {pushDescription.title}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "25px",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              backgroundColor: "rgba(211, 207, 207, 0.8)",
              cursor: "pointer",
            }}
            onClick={toggleExpand}
          >
            <Arrow />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PhonePushContent;
