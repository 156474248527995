import FourOf4Image from "assets/404.png";
import { Box, Typography } from "@mui/material";

export default function FourOf4Page() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={FourOf4Image}
        width={"1000"}
        alt="404"
        style={{
          maxWidth: "100%",
        }}
      />
      <Typography>Ooops, something went wrong</Typography>
    </Box>
  );
}
