import React from "react";

interface IIcon {
  color?: string;
  colorStroke?: string;
}

function Save({ color, colorStroke }: IIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      fill={color}
      stroke={colorStroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="lucide lucide-bookmark"
      viewBox="0 0 24 24"
    >
      <path d="M19 21l-7-4-7 4V5a2 2 0 012-2h10a2 2 0 012 2v16z"></path>
    </svg>
  );
}

export default Save;
