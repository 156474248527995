import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import StatisticWidget from "components/StatisticWidget";
import PushTable from "components/PushTable";
import { useNavigate } from "react-router";
import { IPwaStatisticsItem } from "pages/DashboardPage";
import { IAppData, INotification } from "interfaces/push";
import Preloader from "components/GlobalPreloader/Preloader";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const Pushes = () => {
  const { t } = useTranslation();
  const token = getToken();
  const [loading, setLoading] = useState(false);
  const [pwas, setPwas] = useState([]);
  const [pushes, setPushes] = useState<{ notifications: INotification[]; appData: IAppData }[]>([]);
  console.log("pushes", pushes);
  const navigate = useNavigate();

  const { triggerFetch: getUserInfo } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const [totalInstallCount, setTotalInstallCount] = useState<number>(0);
  const { triggerFetch: getInstalls } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    async function SetInstalls() {
      const res = await getInstalls();

      const totalInstallCount = res?.data.items.reduce(
        (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
        0
      );
      setTotalInstallCount(totalInstallCount);
    }
    SetInstalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function CreatePush() {
    navigate("/push/create");
  }

  const { triggerFetch: getAll } = useFetch({
    url: "push/all-notification",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  async function getNot() {
    setLoading(true);
    const result = await getUserInfo();
    if (result) {
      setPwas(result?.data?.items || null);
      const res = await getAll();
      setPushes(res?.data || []);
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    getNot();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontSize: "24px" }}>
            {t("pushNotifications")}
          </Typography>
          <Box>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#F4701B",
                textTransform: "uppercase",
                "&:hover": {
                  backgroundColor: "#F0670B",
                },
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="medium"
              onClick={CreatePush}
            >
              {t("createPush")}
            </Button>
          </Box>
        </Grid>

        <Box sx={{ display: { xs: "none", sm: "flex", width: "100%", padding: "16px" } }}>
          <StatisticWidget totalInstallCount={totalInstallCount} isPush={true} />
        </Box>

        <Grid item xs={12} lg={12}>
          {loading ? <Preloader /> : <PushTable getNot={getNot} pwas={pwas} pushes={pushes} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pushes;
