import React, { useEffect } from "react";
import { Select, MenuItem, FormControl, SelectChangeEvent, alpha, InputAdornment, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePickerRange } from "components/DatePickers/DatePickerRange";

interface SelectPeriodProps {
  selectedPeriod: string;
  onChange: (period: string) => void;
  onChangeDateRange: (dates: any) => void;
  setError?: (value: boolean) => void;
  error?: boolean;
}

const SelectPeriod: React.FC<SelectPeriodProps> = ({
  error,
  setError,
  selectedPeriod,
  onChange,
  onChangeDateRange,
}) => {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const hoverColor = "#F4701B";
  const hoverBackgroundColor = alpha(hoverColor, 0.1);

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string);

    if (event.target.value === "custom") {
      const dateNow = new Date();
      onChangeDateRange([dateNow, null]);
    }
  };

  const handleDateChange = (dates: any) => {
    onChangeDateRange(dates);

    if (setError) {
      setError(!dates[0] || !dates[1]);
    }
  };

  useEffect(() => {
    setShowDatePicker(selectedPeriod === "custom");
  }, [selectedPeriod]);

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      {showDatePicker && <DatePickerRange error={error} onChangeCallback={handleDateChange} />}
      <Select
        value={selectedPeriod}
        onChange={handleChange}
        label={t("selectperiod")}
        startAdornment={
          <InputAdornment position="start">
            <CalendarMonthIcon
              sx={{
                width: "19px",
                height: "19px",
                marginRight: "5px",
                color: "#6D6777",
              }}
            />
          </InputAdornment>
        }
        sx={{
          position: "relative",
          backgroundColor: "#F6F7FB",
          color: "#6D6777",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "14px",
          boxShadow: "none",
          textTransform: "none",
          height: 40,
          marginLeft: { xs: 0, sm: "10px" },
          width: { xs: "100%", sm: "160px" },
          borderRadius: "8px",
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover": {
            backgroundColor: hoverBackgroundColor,
            color: hoverColor,
            "& .MuiSvgIcon-root": {
              color: hoverColor,
            },
          },
        }}
      >
        <MenuItem value="today">{t("thisday")}</MenuItem>
        <MenuItem value="1_month_ago">{t("thismonth")}</MenuItem>
        <MenuItem value="1_year_ago">{t("thisyear")}</MenuItem>
        <MenuItem value="custom">{t("selectperiod")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectPeriod;
