import React from "react";

export default function Safety() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="57"
      fill="none"
      viewBox="0 0 56 57"
    >
      <g filter="url(#filter0_d_355_3802)">
        <path
          fill="url(#paint0_linear_355_3802)"
          fillRule="evenodd"
          d="M27.937 2.679h.126l.174.014.046.007.17.035.168.052.162.069.155.084.149.101.113.093A27.087 27.087 0 0047.828 9.97l.604-.021a1.81 1.81 0 011.82 1.303A31.005 31.005 0 0147.5 35.197a30.788 30.788 0 01-19.046 14.674c-.298.078-.611.078-.91 0A30.788 30.788 0 018.5 35.197a31.005 31.005 0 01-2.75-23.945 1.81 1.81 0 011.819-1.303 27.083 27.083 0 0018.775-6.418l.602-.513.152-.1.12-.062.162-.069.167-.052.17-.035.173-.019.047-.002z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter1_d_355_3802)">
        <path
          fill="url(#paint1_linear_355_3802)"
          d="M35.452 18.578a2.231 2.231 0 013.147 0 2.21 2.21 0 01.162 2.954l-.162.18-11.867 11.819a2.232 2.232 0 01-2.966.161l-.18-.161-5.934-5.91a2.21 2.21 0 010-3.133 2.232 2.232 0 012.966-.162l.18.162 4.36 4.342 10.294-10.252z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_355_3802"
          width="55.501"
          height="55.751"
          x="0.25"
          y="0.929"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.5"></feOffset>
          <feGaussianBlur stdDeviation="2.125"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.110987 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_355_3802"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_355_3802"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_355_3802"
          width="24.25"
          height="18.25"
          x="16"
          y="17.429"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.5"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.138658 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_355_3802"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_355_3802"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_355_3802"
          x1="4.5"
          x2="4.5"
          y1="2.679"
          y2="49.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.448" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_355_3802"
          x1="17"
          x2="17"
          y1="17.929"
          y2="34.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4701B"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
