import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { styled, keyframes } from "@mui/system";

const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Looper = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  background: "#F4701B",
  clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
  // transform: "rotate(-1deg)",

  [theme.breakpoints.up("sm")]: {
    transform: "rotate(-3deg)",
    marginLeft: "-15%",
    width: "120%",
  },
}));

const LooperInnerList = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
}));

const LooperListInstance = styled(Box)({
  display: "flex",
  width: "max-content",
  animation: `${slideAnimation} linear infinite`,
});

export default function InfiniteLooper({
  speed,
  direction,
  children,
}: {
  speed: number;
  direction: "right" | "left";
  children: React.ReactNode;
}) {
  const [looperInstances, setLooperInstances] = useState(1);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const resetAnimation = () => {
    if (innerRef.current) {
      innerRef.current.setAttribute("data-animate", "false");

      setTimeout(() => {
        if (innerRef.current) {
          innerRef.current.setAttribute("data-animate", "true");
        }
      }, 10);
    }
  };

  const setupInstances = useCallback(() => {
    resetAnimation();
    if (!innerRef.current || !outerRef.current) return;

    const { width } = innerRef.current.getBoundingClientRect();
    const { width: parentWidth } = outerRef.current.getBoundingClientRect();
    const instanceWidth = width / innerRef.current.children.length;

    if (width < parentWidth + instanceWidth) {
      setLooperInstances(looperInstances + Math.ceil((parentWidth - width) / instanceWidth) + 1);
    }
  }, [looperInstances]);

  useEffect(() => {
    window.addEventListener("resize", setupInstances);
    return () => {
      window.removeEventListener("resize", setupInstances);
    };
  }, [setupInstances]);

  useEffect(() => {
    setupInstances();
  }, [setupInstances, children]);

  return (
    <Looper ref={outerRef}>
      <LooperInnerList ref={innerRef}>
        {[...Array(looperInstances)].map((_, ind) => (
          <LooperListInstance
            key={ind}
            className="looperListInstance"
            sx={{
              fontSize: { xs: "32px", md: "36px", lg: "48px", xl: "56px" },
              animationDuration: `${speed}s`,
              animationDirection: direction === "right" ? "reverse" : "normal",
            }}
          >
            {children}
          </LooperListInstance>
        ))}
      </LooperInnerList>
    </Looper>
  );
}
