import { create } from "zustand";

interface IGlobalLoaderStore {
  isGlobalLoading: boolean;
  setIsGlobalLoading: (value: boolean) => void;
}

const globalLoaderStore = create<IGlobalLoaderStore>((set) => ({
  isGlobalLoading: false,
  setIsGlobalLoading: (value) => set({ isGlobalLoading: value }),
}));

export default globalLoaderStore;
