import { useTranslation } from "react-i18next";

export default function useCountriesForThirdRegion() {
  const { t } = useTranslation();
  return [
    t("azerbaijan"),
    t("albania"),
    t("algeria"),
    t("american_samoa"),
    t("anguilla"),
    t("angola"),
    t("antarctica"),
    t("antigua_and_barbuda"),
    t("argentina"),
    t("armenia"),
    t("aruba"),
    t("afghanistan"),
    t("bangladesh"),
    t("barbados"),
    t("bahrain"),
    t("belize"),
    t("benin"),
    t("bermuda"),
    t("bonaire_sint_eustatius_and_saba"),
    t("botswana"),
    t("british_indian_ocean_territory"),
    t("burkina_faso"),
    t("burundi"),
    t("bhutan"),
    t("bolivarian_republic_of_venezuela"),
    t("british_virgin_islands"),
    t("virgin_islands_us"),
    t("vietnam"),
    t("gabon"),
    t("haiti"),
    t("gambia"),
    t("ghana"),
    t("guadeloupe"),
    t("guatemala"),
    t("guinea"),
    t("guinea_bissau"),
    t("guernsey"),
    t("gibraltar"),
    t("honduras"),
    t("grenada"),
    t("greenland"),
    t("georgia"),
    t("guam"),
    t("jersey"),
    t("djibouti"),
    t("dominica"),
    t("zambia"),
    t("western_sahara"),
    t("zimbabwe"),
    t("india"),
    t("jordan"),
    t("iraq"),
    t("iran"),
    t("yemen"),
    t("cape_verde"),
    t("cambodia"),
    t("cameroon"),
    t("kenya"),
    t("kyrgyzstan"),
    t("kiribati"),
    t("cocos_keeling_islands"),
    t("comoros"),
    t("congo"),
    t("congo_democratic_republic_of_the"),
    t("north_korea"),
    t("ivory_coast"),
    t("cuba"),
    t("kuwait"),
    t("curacao"),
    t("laos"),
    t("lesotho"),
    t("lebanon"),
    t("libya"),
    t("liberia"),
    t("liechtenstein"),
    t("mauritius"),
    t("mauritania"),
    t("madagascar"),
    t("mayotte"),
    t("malawi"),
    t("mali"),
    t("micronesia_federated_states_of"),
    t("maldives"),
    t("martinique"),
    t("marshall_islands"),
    t("mozambique"),
    t("moldova_republic_of"),
    t("monaco"),
    t("mongolia"),
    t("montserrat"),
    t("myanmar"),
    t("namibia"),
    t("nauru"),
    t("niger"),
    t("nigeria"),
    t("nicaragua"),
    t("niue"),
    t("new_caledonia"),
    t("bouvet_island"),
    t("isle_of_man"),
    t("norfolk_island"),
    t("christmas_island"),
    t("heard_island_and_mcdonald_islands"),
    t("cayman_islands"),
    t("cook_islands"),
    t("turks_and_caicos_islands"),
    t("pakistan"),
    t("palau"),
    t("palestinian_territory"),
    t("holy_see_vatican_city_state"),
    t("papua_new_guinea"),
    t("pitcairn"),
    t("republic_of_macedonia"),
    t("reunion"),
    t("rwanda"),
    t("samoa"),
    t("san_marino"),
    t("sao_tome_and_principe"),
    t("saint_helena_ascension_and_tristan_da_cunha"),
    t("northern_mariana_islands"),
    t("saint_barthelemy"),
    t("saint_martin_french_part"),
    t("senegal"),
    t("saint_vincent_and_the_grenadines"),
    t("saint_kitts_and_nevis"),
    t("saint_lucia"),
    t("saint_pierre_and_miquelon"),
    t("seychelles"),
    t("sint_maarten_dutch_part"),
    t("syrian_arab_republic"),
    t("solomon_islands"),
    t("somalia"),
    t("sudan"),
    t("suriname"),
    t("sierra_leone"),
    t("tajikistan"),
    t("taiwan"),
    t("tanzania_united_republic_of"),
    t("timor_leste"),
    t("togo"),
    t("tokelau"),
    t("tonga"),
    t("trinidad_and_tobago"),
    t("tuvalu"),
    t("tunisia"),
    t("turkmenistan"),
    t("uganda"),
    t("uzbekistan"),
    t("wallis_and_futuna"),
    t("faroe_islands"),
    t("falkland_islands_malvinas"),
    t("french_guiana"),
    t("french_polynesia"),
    t("french_southern_territories"),
    t("central_african_republic"),
    t("chad"),
    t("svalbard_and_jan_mayen"),
    t("sri_lanka"),
    t("equatorial_guinea"),
    t("aland_islands"),
    t("el_salvador"),
    t("eritrea"),
    t("eswatini"),
    t("ethiopia"),
    t("south_africa"),
    t("south_ossetia"),
    t("south_sudan"),
    t("jamaica"),
    t("abkhazia"),
  ];
}
