import React from "react";

export default function Cached() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="59"
      fill="none"
      viewBox="0 0 58 59"
    >
      <mask
        id="mask0_355_3723"
        style={{ maskType: "alpha" }}
        width="58"
        height="59"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0.179H58V58.179H0z"></path>
      </mask>
      <g filter="url(#filter0_d_355_3723)" mask="url(#mask0_355_3723)">
        <path
          fill="url(#paint0_linear_355_3723)"
          d="M29.12 48.512c-5.397 0-9.989-1.873-13.775-5.62-3.786-3.745-5.68-8.317-5.68-13.714v-.423L7.492 30.93c-.443.443-1.007.665-1.692.665s-1.248-.222-1.692-.665c-.443-.443-.664-1.007-.664-1.691 0-.685.221-1.249.664-1.692l6.284-6.283c.483-.484 1.047-.725 1.691-.725.645 0 1.209.241 1.692.725l6.284 6.283c.442.443.664 1.007.664 1.692 0 .684-.222 1.248-.665 1.691-.443.443-1.006.665-1.691.665s-1.249-.222-1.692-.665L14.5 28.755v.423c0 4.028 1.42 7.452 4.26 10.271 2.84 2.82 6.293 4.23 10.361 4.23.644 0 1.279-.041 1.903-.122a9.778 9.778 0 001.843-.422c.684-.202 1.329-.182 1.933.06.604.242 1.068.665 1.39 1.269.322.644.352 1.279.09 1.903-.262.624-.735 1.037-1.42 1.238-.926.323-1.872.554-2.839.695-.967.141-1.933.212-2.9.212zm-.242-33.834c-.644 0-1.279.04-1.903.121a9.769 9.769 0 00-1.843.423c-.684.201-1.339.181-1.963-.06a2.65 2.65 0 01-1.42-1.27c-.322-.603-.352-1.217-.09-1.842a2.084 2.084 0 011.359-1.238 19.082 19.082 0 012.9-.725 17.98 17.98 0 012.96-.242c5.398 0 9.99 1.873 13.775 5.619 3.786 3.746 5.68 8.317 5.68 13.714v.423l2.175-2.175c.442-.443 1.006-.664 1.691-.664s1.249.221 1.692.664c.443.443.664 1.007.664 1.692s-.221 1.248-.664 1.692l-6.283 6.283c-.484.483-1.048.725-1.692.725-.645 0-1.208-.242-1.692-.725l-6.283-6.283c-.443-.444-.665-1.007-.665-1.692s.222-1.249.665-1.692c.443-.443 1.007-.664 1.691-.664.685 0 1.25.221 1.692.664l2.175 2.175v-.423c0-4.027-1.42-7.451-4.26-10.27-2.839-2.82-6.293-4.23-10.36-4.23z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_355_3723"
          width="59.612"
          height="47.167"
          x="-0.807"
          y="8.095"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.5"></feOffset>
          <feGaussianBlur stdDeviation="2.125"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.110987 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_355_3723"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_355_3723"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_355_3723"
          x1="3.443"
          x2="3.443"
          y1="-3"
          y2="59.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFECDF"></stop>
          <stop offset="0.337" stopColor="#FFFCF1"></stop>
          <stop offset="1" stopColor="#FF9652"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
