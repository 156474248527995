import styled from "@emotion/styled";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StatsListTable = ({ totalInstallCount, groupedClicks }: any) => {
  const { t } = useTranslation();

  return (
    <Table aria-label="simple table" sx={{ whiteSpace: "nowrap" }}>
      <TableHead sx={{ backgroundColor: "#F6F7FB" }}>
        <TableRow>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("grouping")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("clicks")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("uniques")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("installs")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("opens")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("push")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("reopen")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("cpi")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("regs")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("deps")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("crInst")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("crPush")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("crInstPush")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("uniquePreland")}</TypographyStyled>
          </TableCell>
          <TableCell>
            <TypographyStyled variant="subtitle2">{t("uniquePostland")}</TypographyStyled>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(groupedClicks).map(([day, aggregatedData]: [string, any]) => {
          return (
            <TableRow key={day}>
              <TableCell
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                }}
              >
                <Typography
                  sx={{
                    paddingLeft: "16px",
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                  }}
                >
                  {day || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  {aggregatedData.clicks || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  {aggregatedData.campaign_unique_clicks || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  {totalInstallCount || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  {aggregatedData.clicks || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  {aggregatedData.regs || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  {aggregatedData.deposits || 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--cygreRegular), sans-serif",
                    textAlign: "center",
                  }}
                >
                  0
                </Typography>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                ></Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const TypographyStyled = styled(Typography)(() => {
  return {
    color: "#6D6777",
    fontWeight: 500,
    fontFamily: "var(--cygreRegular), sans-serif",
  };
});

export default StatsListTable;
