import DropdownIcon from "assets/arrow_drop_down.png";
import { useEffect, useRef } from "react";
import "./dropdown.css";

type Option = {
  name: string;
  component?: () => React.JSX.Element;
  action: () => void;
  selected?: boolean;
};

interface DropdownProps {
  options: Option[];
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  selectionCallback?: (option: Option) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, setIsOpen, isOpen }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <img
        width={10}
        height={8}
        src={DropdownIcon}
        alt="dropdown"
        style={{ marginLeft: "5px", cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => {
            return (
              <li
                key={option.name}
                style={{
                  fontFamily: "var(--cygreRegular), sans-serif",
                  fontSize: "16px",
                  color: "#2E263D",
                }}
                onClick={() => option.action()}
                className={`dropdown-item ${option?.selected ? "selected" : ""}`}
              >
                {option.component ? option?.component() : option.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
