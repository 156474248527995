import IIcon from "interfaces/icon";
import React from "react";

function Notification({ color }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" fill="none" viewBox="0 0 14 17">
      <path
        fill={color}
        d="M1.375 13.737a.605.605 0 01-.445-.18.605.605 0 01-.18-.445c0-.178.06-.326.18-.446s.268-.179.445-.179h.881V6.269c0-1.12.346-2.112 1.038-2.973a4.598 4.598 0 012.664-1.652v-.52c0-.289.102-.535.304-.737.202-.203.448-.304.737-.304.29 0 .535.101.738.304.203.202.305.448.305.738v.519a4.598 4.598 0 012.664 1.652 4.612 4.612 0 011.037 2.973v6.218h.882c.177 0 .325.06.445.18s.18.268.18.445-.06.326-.18.445a.605.605 0 01-.445.18H1.375zm5.624 2.34c-.415 0-.77-.148-1.064-.443a1.452 1.452 0 01-.441-1.064h3.012c0 .416-.147.77-.442 1.065-.296.294-.65.442-1.065.442zm-3.493-3.59h6.987V6.269c0-.965-.34-1.788-1.023-2.47A3.366 3.366 0 007 2.775c-.965 0-1.788.341-2.47 1.024a3.366 3.366 0 00-1.024 2.47v6.218z"
      ></path>
    </svg>
  );
}

export default Notification;
