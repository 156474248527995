import React from "react";

function Copy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none" viewBox="0 0 17 19">
      <path
        fill="#2E263D"
        d="M6.058 15.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V2.308c0-.505.175-.933.525-1.283C5.125.675 5.553.5 6.058.5h8.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.058zm0-1.5h8.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V2.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H6.058a.294.294 0 00-.212.096.294.294 0 00-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 00.212.096zm-3.5 5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V5.058c0-.213.072-.391.216-.535a.726.726 0 01.534-.215c.213 0 .391.072.535.215a.727.727 0 01.215.535v12.134c0 .077.032.148.096.212a.294.294 0 00.212.096h9.134c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.535.726.726 0 01-.535.215H2.558z"
      ></path>
    </svg>
  );
}

export default Copy;
