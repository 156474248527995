import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwitchComponent from "components/Checkbox";
import { usePushStore } from "store/pushStore";

const styles = {
  title: {
    fontFamily: "var(--cygreMedium), sans-serif",
    color: "#2E263D",
    fontSize: "14px",
  },
  text: {
    fontFamily: "var(--cygreMedium), sans-serif",
    color: "#2E263D",
    fontSize: "14px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "24px",
    width: "100%",
  },
  summary: {
    lineHeight: "32px",
    fontSize: "16px",
    color: "#2E263D",
    fontFamily: "var(--cygreMedium), sans-serif",
  },
  accordion: {
    boxShadow: "none",
    border: "1px solid #EEEEEE",
    borderRadius: "8px",
    background: "none",
    "&::before": {
      content: "none",
    },
  },
};

export default function Optional() {
  const { t } = useTranslation();
  const savePushData = usePushStore((state) => state.savePushData);

  const [notifyAgain, setNotifyAgain] = useState<boolean>(false);
  const [awaitResponse, setAwaitResponse] = useState<boolean>(false);
  const [silentMode, setSilentMode] = useState<boolean>(false);

  const handleSwitchChange = (key: "notifyAgain" | "awaitResponse" | "silentMode", checked: boolean) => {
    switch (key) {
      case "notifyAgain":
        setNotifyAgain(checked);
        break;
      case "awaitResponse":
        setAwaitResponse(checked);
        break;
      case "silentMode":
        setSilentMode(checked);
        break;
    }

    savePushData({ [key]: checked });
  };

  return (
    <Accordion sx={styles.accordion}>
      <AccordionSummary sx={{ height: "48px" }} expandIcon={<ExpandMoreIcon />}>
        <Typography sx={styles.summary}>{t("additional")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.container}>
          <Box sx={{ paddingRight: "32px" }}>
            <Typography sx={styles.title}>{t("notifyAgain")}</Typography>
            <Typography sx={styles.text}>{t("notifyAgainDescription")}</Typography>
          </Box>
          <SwitchComponent
            checked={notifyAgain}
            onChange={(e, checked) => handleSwitchChange("notifyAgain", checked)}
          />
        </Box>
        <Box sx={styles.container}>
          <Box sx={{ paddingRight: "32px" }}>
            <Typography sx={styles.title}>{t("awaitResponse")}</Typography>
            <Typography sx={styles.text}>{t("awaitResponseDescription")}</Typography>
          </Box>
          <SwitchComponent
            checked={awaitResponse}
            onChange={(e, checked) => handleSwitchChange("awaitResponse", checked)}
          />
        </Box>
        <Box sx={styles.container}>
          <Box sx={{ paddingRight: "32px" }}>
            <Typography sx={styles.title}>{t("silentMode")}</Typography>
            <Typography sx={styles.text}>{t("silentModeDescription")}</Typography>
          </Box>
          <SwitchComponent checked={silentMode} onChange={(e, checked) => handleSwitchChange("silentMode", checked)} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
