import React from "react";

function Email() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" fill="none" viewBox="0 0 20 15">
      <path
        fill="#000"
        d="M1 0h18c.265 0 .52.088.707.244a.77.77 0 01.293.59v13.333a.77.77 0 01-.293.589A1.11 1.11 0 0119 15H1a1.11 1.11 0 01-.707-.244.77.77 0 01-.293-.59V.834A.77.77 0 01.293.244 1.11 1.11 0 011 0zm17 3.532l-7.928 5.916L2 3.513v9.82h16V3.532zM2.511 1.667l7.55 5.551 7.441-5.551H2.511z"
      ></path>
    </svg>
  );
}

export default Email;
