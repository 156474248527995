import React, { useState, useEffect, ChangeEvent } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import ImageIcon from "assets/icons/ImageIcon";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { useAppStore, useErrorAppStore } from "store/appStore";
import showPreviewIcon from "utils/showPreviewImage";
import { getToken } from "components/Stats";
import { usePushStore } from "store/pushStore";

export const buttonsStyles = {
  width: 44,
  height: 44,
  borderRadius: "8px",
  background: "#fff",
  border: "1px solid #C9D0D8",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export default function PushIcon() {
  const { pushData, savePushData } = usePushStore();
  const { t } = useTranslation();
  // const [image, setImage] = useState<string | File | null>(null);
  // const [newsletterName, setNewsletterName] = useState("");
  // const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  // const [session, setSession] = useState("");
  // useEffect(() => {
  //   const token = getToken();
  //   setSession(token!);
  // }, []);

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     if (file.type.startsWith("image/")) {
  //       setImage(file);
  //     } else {
  //       alert("Select image!");
  //     }
  //   }
  // };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    savePushData({ pushName: value });
  };
  //
  // const handleImageRemove = () => {
  //   setImage(null);
  // };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "space-between", flex: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          {/*
          TODO: return later
          <Box
            sx={{
              position: "relative",
              marginRight: 2,
              "&:hover .delete-icon": {
                opacity: 1,
              },
            }}
            onMouseEnter={() => setShowDeleteIcon(true)}
            onMouseLeave={() => setShowDeleteIcon(false)}
          >
            {image ? (
              <>
                {showDeleteIcon && (
                  <IconButton
                    className="delete-icon"
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      zIndex: 1,
                      color: "white",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={handleImageRemove}
                  >
                    <CancelIcon color="warning" fontSize="small" />
                  </IconButton>
                )}
                <img
                  src={showPreviewIcon(image)}
                  alt="selected image"
                  className="uploaded-image"
                  style={{
                    width: 88,
                    height: 88,
                    borderRadius: "16px",
                  }}
                />
              </>
            ) : (
              <label className="custom-input">
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <IconButton
                  sx={{
                    background: "#F6F7FB",
                    width: 88,
                    height: 88,
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  component="span"
                >
                  {newsletterName ? (
                    <img
                      src={showPreviewIcon(newsletterName)}
                      alt="upload image"
                      style={{
                        width: 88,
                        height: 88,
                        borderRadius: "16px",
                      }}
                    />
                  ) : (
                    <ImageIcon />
                  )}
                </IconButton>
              </label>
            )}
          </Box> */}
          <Box sx={{ width: "100%", paddingRight: "10px" }}>
            <TextField
              required
              sx={{
                input: {
                  "&::placeholder": {
                    textOverflow: "ellipsis !important",
                    color: "black",
                    fontFamily: "CygreThin",
                    fontSize: "24px",
                    width: "auto",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              fullWidth
              placeholder={t("campaignName")}
              value={pushData.pushName}
              onChange={handleInputChange}
              type="text"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
