import React, { useEffect, useRef, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModalCheckDomain from "./ModalCheckDomain";
import { formatDate } from "utils/isoDate";
import DomainVerificationIcon from "assets/icons/DomainVerificationIcon";
import Union from "assets/icons/Union";
import { Box, Typography, Tooltip, styled, TooltipProps, tooltipClasses } from "@mui/material";
import CustomTable, { IColumn } from "components/CustomTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Preloader from "components/GlobalPreloader/Preloader";
import { useAppStore } from "store/appStore";

interface IDomain {
  name: string;
  listedDomain: string;
  createdAt: string;
  nsFirst: string;
  nsSecond: string;
  status: string;
  _id: string;
  pwa?: string;
}

interface DomainTableProps {
  domains: IDomain[];
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  getDomains: () => void;
  buyTMPDomainId?: string;
}

const textTableStyle = {
  fontFamily: "var(--cygreRegular), sans-serif",
  fontSize: "14px",
  paddingLeft: "6px",
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#2E263D",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: "14px",
    backgroundColor: "#2E263D",
  },
}));

const DomainTable: React.FC<DomainTableProps> = ({ domains, getDomains, buyTMPDomainId }) => {
  const { appData } = useAppStore();
  const { id: linkId } = useParams();
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<IDomain | null>(null);
  const [attachDomainId, setAttachDomainId] = useState("");
  const [hoveredRowId, setHoveredRowId] = React.useState<string | null>(null);
  const token = getToken();
  const id = linkId || appData?._id;

  const storageDomain = localStorage.getItem("tmpDomainID");
  const [tmpDomainID, setTmpDomainID] = useState<string | null>(storageDomain);

  useEffect(() => {
    if (buyTMPDomainId) {
      const domain = domains.find((item) => item.listedDomain === buyTMPDomainId);
      if (domain) {
        setTmpDomainID(domain?._id);
        localStorage.setItem("tmpDomainID", domain?._id);
      }
    }
  }, [buyTMPDomainId, domains]);

  useEffect(() => {
    if (appData?.domain?.status) {
      setTmpDomainID(null);
      localStorage.removeItem("tmpDomainID");
      return;
    }
  }, [tmpDomainID, appData?.domain?.status]);

  const {
    loading: loadingDomain,
    error: failDomain,
    triggerFetch: attachDomain,
  } = useFetch({
    url: `pwa/${id}/domain`,
    method: "POST",
    body: {
      domain: attachDomainId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    if (attachDomainId) {
      void attachDomainAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachDomainId]);

  async function attachDomainAction() {
    if (!appData?._id) {
      setTmpDomainID(attachDomainId);
      localStorage.setItem("tmpDomainID", attachDomainId);
      return;
    }

    try {
      const result = await attachDomain();
      if (!result.success) {
        toast.error(result?.message || "Attach domain failed");
        return;
      }
      toast.success("Domain attached successfully");
      await getDomains();
    } catch (error: any) {
      toast.error("Error", error?.message || "Attach domain failed");
    }
  }

  async function changePwaDomain(domain: any) {
    setAttachDomainId(domain._id);
  }

  const handleRowClick = (domain: IDomain) => {
    setSelectedDomain(domain);
    setOpenCheckModal(true);
  };

  const columns: IColumn[] = [
    {
      id: "name",
      label: "domain",
      align: "left",
      render: (row: IDomain) => {
        const isSelected = (row.pwa && row.pwa === id) || tmpDomainID === row?._id || tmpDomainID === row?.listedDomain;
        const isShowAddIcon = !isSelected && hoveredRowId === row._id;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {isShowAddIcon && (
              <BootstrapTooltip title={"Set domain to PWA"} placement="top">
                <AddCircleIcon
                  sx={{
                    position: "absolute",
                    left: 0,
                    width: "19px",
                    height: "19px",
                    color: "#F4701B",
                  }}
                  onClick={(event: React.MouseEvent<unknown>) => {
                    event.stopPropagation();
                    void changePwaDomain(row);
                  }}
                />
              </BootstrapTooltip>
            )}
            {isSelected && (
              <CheckCircleIcon
                onClick={() => {
                  void changePwaDomain(row);
                }}
                sx={{
                  position: "absolute",
                  left: 0,
                  width: "19px",
                  height: "19px",
                  color: "#F4701B",
                }}
              />
            )}
            <Typography
              sx={{
                ...textTableStyle,
                paddingLeft: "26px",
              }}
            >
              {row.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "status",
      label: "status",
      align: "left",
      render: (row) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {row.status === "pending" ? <DomainVerificationIcon /> : <Union />}
          <Typography component="span" sx={{ ...textTableStyle }}>
            {row.status}
          </Typography>
        </Box>
      ),
    },
    {
      id: "createdAt",
      label: "registrationdate",
      align: "left",
      format: formatDate,
    },
    {
      id: "info",
      label: "",
      align: "left",
      render: (row) => {
        return row.type === "custom" ? (
          <InfoIcon sx={{ width: "19px", height: "19px", color: "#F4701B" }} onClick={() => handleRowClick(row)} />
        ) : null;
      },
    },
  ];

  const rows = domains.map((domain) => ({
    ...domain,
    rowStyle: domain.pwa === id ? { backgroundColor: "rgba(244, 112, 27, 0.1) !important" } : {},
  }));

  return (
    <>
      <CustomTable columns={columns} setHoveredRowId={setHoveredRowId} rows={rows} showLoader={loadingDomain} />

      {selectedDomain && (
        <ModalCheckDomain
          id={selectedDomain._id}
          status={selectedDomain.status}
          nsFirst={selectedDomain.nsFirst}
          nsSecond={selectedDomain.nsSecond}
          open={openCheckModal}
          setOpen={setOpenCheckModal}
        />
      )}
    </>
  );
};

export default DomainTable;
