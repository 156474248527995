import React, { useState, useEffect } from "react";
import { Box, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderSection from "../HeaderSection";
import DuckFootIcon from "assets/icons/DuckFootIcon";

import CreationStepsAnimation from "components/CreationStepsAnimation";

export default function CreateSteps() {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(0);
  const [showFirstIcon, setShowFirstIcon] = useState(true);

  const handleItemClick = (index: number) => {
    if (index !== selectedStep) {
      setTimeout(() => {
        setSelectedStep(index);
        setShowFirstIcon(!showFirstIcon);
      }, 300);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleItemClick(selectedStep < 4 ? selectedStep + 1 : 0);
    }, 3300);

    return () => clearInterval(interval);
  }, [selectedStep]);

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: { xs: "60px", lg: "140px" },
        height: "100%",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "50px",
          width: "100%",
          position: "relative",
          height: "50vh",
        }}
      >
        <CreationStepsAnimation step={selectedStep} />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", lg: "50%" },
          paddingTop: "22px",
          display: "flex",
          flexDirection: "column",
          paddingRight: "24px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <HeaderSection title={t("howtocreateaPWAapp")} />
          <Box sx={{ padding: "35px 20px 0 15px" }}>
            <Box
              sx={{
                paddingRight: "20px",
                transform: "rotate(60deg)",
                opacity: showFirstIcon ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              <DuckFootIcon />
            </Box>
            <Box
              sx={{
                transform: "rotate(70deg)",
                opacity: showFirstIcon ? 0 : 1,
                transition: "opacity 0.5s ease",
              }}
            >
              <DuckFootIcon />
            </Box>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ marginTop: "auto" }}>
          <List>
            {[
              t("сomeupwithatitleanddescription"),
              t("addcreatives"),
              t("tеieupthedomain"),
              t("сreateatrackinglink"),
              t("ready"),
            ].map((text, index) => (
              <ListItem
                key={index}
                onClick={() => handleItemClick(index)}
                sx={{
                  position: "relative",
                  color: selectedStep === index ? "black" : "#9F9F9F",
                  cursor: "pointer",
                  fontSize: { xs: "20px", md: "28px", lg: "32px" },
                  padding: "16px 0 16px 0",
                  lineHeight: { xs: "28px", md: "40px" },
                  transition: "color 0.5s ease",
                  "&::before":
                    selectedStep === index
                      ? {
                          content: '""',
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "100%",
                          height: "2px",
                          background: "#E1E2EC",
                        }
                      : {},
                  "&::after":
                    selectedStep === index
                      ? {
                          content: '""',
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "0%",
                          height: "2px",
                          background: "black",
                          transition: "width 4s ease",
                          animation: "borderFill 4s forwards",
                        }
                      : {},
                }}
              >
                {index + 1}. {text}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
          alignItems: "center",
          justifyContent: "center",
          padding: "50px 0",
          width: "50%",
          position: "relative",
          height: "80vh",
        }}
      >
        <CreationStepsAnimation step={selectedStep} />
      </Box>
      <style>
        {`
          @keyframes borderFill {
            from {
              width: 0%;
            }
            to {
              width: 100%;
            }
          }
        `}
      </style>
    </Box>
  );
}
