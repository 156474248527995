import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import CustomButton from "components/Button";
import CustomInput from "components/Input";
import { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import NSInputs from "./NsInputs";
import AnswerModal from "./AnswerModal";
import ToastMessage from "components/ToastMessage";
import useIdStore from "store/pwaId";
import { getToken } from "utils/getToken";
import { useParams } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

interface IModalDomain {
  open: boolean;
  setOpen: (value: boolean) => void;

  getDomains: () => void;
}

export default function ModalBindDomain({ open, setOpen, getDomains }: IModalDomain) {
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  // const { id: staticID } = useParams();
  const { id } = useIdStore();

  const token = getToken();
  const [newDomain, setNewDomain] = useState({
    nsFirst: "",
    nsSecond: "",
    _id: "",
    status: "",
  });
  const [AnswerCheckModal, setAnswerCheckModal] = useState(false);
  const [formData, setFormData] = useState({
    domain: "",
  });

  const {
    loading: loadingDomain,
    error: failDomain,
    triggerFetch: createDomain,
  } = useFetch({
    url: "domain/attach-to-pwa",
    method: "POST",
    body: { name: formData.domain, pwaId: id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: checkLoading, triggerFetch: triggercheckFetch } = useFetch({
    url: `domain/${newDomain?._id}/check`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const [isLinkDomainDisabled, setIsLinkDomainDisabled] = useState(true);
  const [showNSInstructions, setShowNSInstructions] = useState(false);

  useEffect(() => {
    setIsLinkDomainDisabled(!formData.domain);
  }, [formData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function handleLinkDomain() {
    if (id) {
      const result = await createDomain();

      if (result.success === true) {
        await getDomains();
        setNewDomain(result.data);
        setShowNSInstructions(true);
      }

      if (result.success === false) {
        handleClose();
        const message = result.message;
        setFormData((prevState) => ({
          ...prevState,
          domain: "",
        }));
        ToastMessage({ mode: "error", message });
      }
    } else {
      const message = t("attachdomainmessage");
      ToastMessage({ mode: "error", message });
    }
  }

  const handleCheck = async () => {
    const result = await triggercheckFetch();

    if (result) {
      setShowNSInstructions(false);
      handleClose();
      await getDomains();
    }

    setAnswerCheckModal(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                textAlign: "left",
                fontSize: "24px",
                lineHeight: "45px",
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#2E263D",
              }}
            >
              {t("linkdomain")}
            </Typography>
            <Box aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box>
          {!showNSInstructions ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomInput
                  margin="normal"
                  required
                  fullWidth
                  id="domain"
                  placeholder="mynewpwadomain.com"
                  name="domain"
                  label={t("domain")}
                  autoFocus
                  value={formData.domain}
                  onChange={handleInputChange}
                  customBgColor="#F6F7F9"
                />
              </Box>
              {/*//TODO: Move to attach NS modal*/}
              {/*<Typography*/}
              {/*  sx={{*/}
              {/*    fontSize: "12px",*/}
              {/*    lineHeight: "19px",*/}
              {/*    color: "#555",*/}
              {/*    marginTop: "-10px",*/}
              {/*    marginBottom: "32px",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {t("toconfigurethedomain")}{" "}*/}
              {/*  <a*/}
              {/*    style={{ color: "#F4701B" }}*/}
              {/*    href="https://www.cloudflare.com/a/account/my-account"*/}
              {/*    target="_blank"*/}
              {/*    rel="noopener noreferrer"*/}
              {/*  >*/}
              {/*    API Keys*/}
              {/*  </a>{" "}*/}
              {/*  {t("andgettheglobalAPIkey")}*/}
              {/*</Typography>*/}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <CustomButton
                  textButton={t("cancel")}
                  customBgColor="inhirit"
                  height={40}
                  width="100%"
                  customColor="#6D6777"
                  customBorderColor="#C9D0D8"
                  customHoverColor="transparent"
                  customBorderRadius="8px"
                  customFontSize="14px"
                  onClick={handleClose}
                />
                <CustomButton
                  textButton={t("linkdomain")}
                  height={40}
                  width="100%"
                  customBgColor="#F4701B"
                  customHoverColor="#F4701B"
                  customBorderRadius="8px"
                  customFontSize="14px"
                  onClick={handleLinkDomain}
                  disabled={isLinkDomainDisabled || loadingDomain}
                />
              </Box>
            </>
          ) : (
            newDomain && (
              <NSInputs
                status={newDomain.status}
                id={newDomain._id}
                nsFirst={newDomain.nsFirst}
                nsSecond={newDomain.nsSecond}
                handleClose={handleCheck}
              />
            )
          )}
        </Box>
      </Modal>
      <AnswerModal open={AnswerCheckModal} setOpen={setAnswerCheckModal} />
    </>
  );
}
