import React from "react";

function GroupItems() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="lucide lucide-users-round"
      viewBox="0 0 24 24"
    >
      <path d="M18 21a8 8 0 00-16 0"></path>
      <circle cx="10" cy="8" r="5"></circle>
      <path d="M22 20c0-3.37-2-6.5-4-8a5 5 0 00-.45-8.3"></path>
    </svg>
  );
}

export default GroupItems;
