export const preloaderData = {
  nm: "Preloader 1",
  ddd: 0,
  h: 130,
  w: 130,
  meta: { g: "LottieFiles Figma v64" },
  layers: [
    {
      ty: 4,
      nm: "Vector 34",
      sr: 1,
      st: 0,
      op: 133,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [19.39, 20.26], t: 132 },
            { s: [19.39, 20.26], t: 180 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100, 100], t: 132 },
            { s: [100, 100], t: 180 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [64.85, 35.68], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [64.85, 35.68], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [34.85, 64.68], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [34.85, 64.68], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [64.85, 35.68], t: 132 },
            { s: [64.85, 35.68], t: 180 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [53.01], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [53.01], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [53.01], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [53.01], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [53.01], t: 132 },
            { s: [53.01], t: 180 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 132 },
            { s: [100], t: 180 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 48,
              },
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 66,
              },
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 114,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 1, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 132,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 180,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 0 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 48 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 66 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 114 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [1, 0.3529, 0.1373], t: 132 },
              { s: [1, 0.3529, 0.1373], t: 180 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 0 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 48 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 66 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 114 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 132 },
              { s: [100], t: 180 },
            ],
          },
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "Vector 35",
      sr: 1,
      st: 0,
      op: 133,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [19.39, 20.26], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [19.39, 20.26], t: 132 },
            { s: [19.39, 20.26], t: 180 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100, 100], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100, 100], t: 132 },
            { s: [100, 100], t: 180 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [65.35, 96.79], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [65.35, 96.79], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [97.25, 64.79], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [97.25, 64.79], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [65.35, 96.79], t: 132 },
            { s: [65.35, 96.79], t: 180 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [66.88], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [66.88], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [66.88], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [66.88], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [66.88], t: 132 },
            { s: [66.88], t: 180 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 0 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 48 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 66 },
            { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 114 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 132 },
            { s: [100], t: 180 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 48,
              },
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 66,
              },
              {
                o: { x: 0.33, y: 1 },
                i: { x: 0.68, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 114,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 1, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 132,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.56, 3.26],
                      [-0.56, 0.32],
                      [-0.55, -0.18],
                      [-0.44, -0.26],
                      [-1.32, -0.14],
                      [-1.22, 0.41],
                      [-1.48, 1.85],
                      [-0.61, 1.62],
                      [-1.13, 0],
                      [0, 0],
                      [-1.02, -1],
                      [0, 0],
                      [-2, -0.49],
                      [-1.89, 0.78],
                      [-1.22, 1.22],
                      [-0.63, -0.44],
                      [0, -0.59],
                      [0.42, -2.6],
                      [0.86, -2.78],
                      [1.55, -3.58],
                      [1.62, -2.5],
                      [0, 0],
                      [0.76, -0.3],
                      [0.44, 0.11],
                      [0, 0],
                      [0, 0],
                      [1.76, 2.68],
                    ],
                    o: [
                      [-1.82, -2.77],
                      [0, 0],
                      [-0.28, -0.58],
                      [0.5, -0.29],
                      [0.48, 0.16],
                      [1.14, 0.67],
                      [1.28, 0.14],
                      [2.24, -0.75],
                      [1.08, -1.36],
                      [0.4, -1.05],
                      [0, 0],
                      [0.49, 0],
                      [0.64, 0.62],
                      [0, 0],
                      [1.99, 0.49],
                      [1.74, -0.72],
                      [0.74, -0.74],
                      [0.48, 0.33],
                      [0, 1.29],
                      [-0.46, 2.88],
                      [-1.15, 3.73],
                      [-1.18, 2.73],
                      [-1.08, 1.67],
                      [-0.3, 0.4],
                      [-0.95, 0.38],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.54, 24.21],
                      [3.36, 16.83],
                      [0.08, 10.6],
                      [0.82, 9.12],
                      [2.3, 9.12],
                      [3.36, 9.86],
                      [6.56, 11.01],
                      [9.84, 10.6],
                      [14.51, 6.74],
                      [16.81, 2.79],
                      [18.29, 0],
                      [18.86, 0],
                      [21.24, 2.79],
                      [22.96, 4.28],
                      [27.63, 6.74],
                      [32.88, 6.74],
                      [36.08, 4.02],
                      [38.29, 2.89],
                      [38.78, 4.28],
                      [38.29, 11.01],
                      [36.41, 18.22],
                      [32.88, 27.57],
                      [29.6, 34.46],
                      [26.73, 38.21],
                      [24.66, 40.44],
                      [22.55, 39.7],
                      [18.7, 36.51],
                      [12.46, 30.77],
                      [7.54, 24.21],
                    ],
                  },
                ],
                t: 180,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 0 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 48 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 66 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [1, 0.3529, 0.1373], t: 114 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [1, 0.3529, 0.1373], t: 132 },
              { s: [1, 0.3529, 0.1373], t: 180 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 0 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 48 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 66 },
              { o: { x: 0.33, y: 1 }, i: { x: 0.68, y: 1 }, s: [100], t: 114 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 132 },
              { s: [100], t: 180 },
            ],
          },
        },
      ],
      ind: 2,
    },
  ],
  v: "5.7.0",
  fr: 60,
  op: 132,
  ip: 0,
  assets: [],
};

export default preloaderData;
