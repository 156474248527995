import { Box, Modal, Typography } from "@mui/material";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import useCautionStore from "store/cautionStore";
import { styled, css } from "@mui/system";
import React from "react";
import Fade from "@mui/material/Fade";

import useSetupStore from "store/setupSettingsStore";
import { useAppStore } from "store/appStore";
import useIdStore from "store/pwaId";
import { useNavigate } from "react-router";

interface IPhoneMockupModal {
  openModal: boolean;
  isLoading: boolean;
  handleUpdatePwa: () => void;
}

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function CautionModal({ openModal, handleUpdatePwa, isLoading }: IPhoneMockupModal) {
  const { closeModal } = useCautionStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const router = useRouter();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);
  const resetSteps = useSetupStore((state) => state.resetAllSteps);
  const { clearAppData } = useAppStore();
  const { clearId } = useIdStore();

  const cautionModal = () => {
    clearAppData();
    setSettingProcess(false);
    resetSteps();
    clearId();
    // localStorage.removeItem("pwaData");
    closeModal();
    navigate(-1);
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
      slots={{ backdrop: StyledBackdrop }}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <ModalContent sx={{ ...style }}>
          {/* <IconButton
            sx={{ position: "absolute", color: "#000", top: { xs: 24, md: 24 }, right: 24 }}
            onClick={closeModal}
          >
            <CloseIcon fontSize="large" color="inherit" />
          </IconButton> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              width: "100%",
              padding: "60px 40px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#2E263D",
                  fontFamily: "var(--cygreMedium), sans-serif",
                  fontSize: "18px",
                  lineHeight: "20px",
                  padding: "16px 0",
                  textAlign: "center",
                }}
              >
                {t("cautionmodaltext")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <CustomButton
                  textButton={t("no")}
                  height={40}
                  width={"48%"}
                  customBgColor="#F4701B"
                  customHoverColor="#F4701B"
                  customBorderRadius="8px"
                  customFontSize="14px"
                  onClick={cautionModal}
                />
                <CustomButton
                  textButton={t("yes")}
                  height={40}
                  width={"48%"}
                  customBgColor="#F4701B"
                  customHoverColor="#F4701B"
                  customBorderRadius="8px"
                  customFontSize="14px"
                  onClick={handleUpdatePwa}
                  loading={isLoading}
                />
              </Box>
            </Box>
          </Box>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

export const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export const ModalContent = styled("div")(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    background-color: ${theme.palette.mode === "dark" ? "#1C2025" : "#fff"};
    border-radius: 20px;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434D5B" : "#DAE2ED"};
    box-shadow: 0 4px 12px ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    color: ${theme.palette.mode === "dark" ? "#F3F6F9" : "#1C2025"};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? "#B0B8C4" : "#303740"};
      margin-bottom: 4px;
    }
  `
);
