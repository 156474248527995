import { Box, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Profile from "assets/Profile_pic.png";
import { useState } from "react";
import showPreviewIcon from "utils/showPreviewImage";
import useFetchFormData from "hooks/useFetchFormData";
import useFetch from "hooks/useFetch";
import { useAuthStore } from "store/authStore";
import { getToken } from "utils/getToken";

interface ProfileAvatarUpdaterProps {
  image: string | File | null;
  onImageChange: (image: File | null) => void;
  userIcon: string;
}

const ProfileAvatarUpdater: React.FC<ProfileAvatarUpdaterProps> = ({ image, onImageChange, userIcon }) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const token = getToken();
  const { setUser } = useAuthStore();
  const { triggerFetch } = useFetchFormData({
    url: "profile/update-avatar",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getUserInfo } = useFetch({
    url: "profile/user-info",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const userInfo = async () => {
    const res = await getUserInfo();
    if (res.data) {
      localStorage.setItem("userData", JSON.stringify(res.data));
      setUser(res.data);
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        onImageChange(file);
        const formData = new FormData();
        formData.append("icon", file);

        const result = await triggerFetch(formData);

        if (result) {
          await userInfo();
        }
      }
    }
  };

  const handleImageRemove = () => {
    onImageChange(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        marginRight: 2,
        "&:hover .delete-icon": {
          opacity: 1,
        },
      }}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
    >
      {image ? (
        <>
          {showDeleteIcon && (
            <IconButton
              className="delete-icon"
              sx={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                zIndex: 1,
                color: "white",
                opacity: 0,
                transition: "opacity 0.3s ease",
              }}
              onClick={handleImageRemove}
            >
              <CancelIcon color="warning" fontSize="small" />
            </IconButton>
          )}
          <img
            src={showPreviewIcon(image)}
            alt="image"
            className="uploaded-image"
            style={{
              width: 88,
              height: 88,
              borderRadius: "50%",
            }}
          />
        </>
      ) : (
        <>
          <label className="custom-input">
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <IconButton
              sx={{
                width: 110,
                height: 110,
                borderRadius: "50%",
                display: "flex",
                border: "1px dashed #C9D0D8",
                borderStyle: "dashed",
                borderWidth: "2px",
                borderColor: "#C9D0D8",
                borderSpacing: "40px",
                alignItems: "center",
                justifyContent: "center",
              }}
              component="span"
            >
              {userIcon ? (
                <img
                  src={showPreviewIcon(userIcon)}
                  alt="Загруженное изображение"
                  style={{
                    width: 88,
                    height: 88,
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img width={88} height={88} src={Profile} alt="profile" />
              )}
            </IconButton>
          </label>
        </>
      )}
    </Box>
  );
};

export default ProfileAvatarUpdater;
