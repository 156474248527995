import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { useLocation } from "react-router-dom";
import Success from "assets/icons/Success";
import Cancel from "assets/icons/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  backgroundColor: "#fff",
  boxShadow: 24,
  padding: "0 20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

export const PaymentStatusModal = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get("success");
  const [isOpened, setIsOpened] = React.useState(Boolean(success));
  const isSuccessful = success === "1";
  const icon = isSuccessful ? <Success /> : <Cancel />;
  const color = isSuccessful ? "#F4701B" : "#E02B00";
  const text = isSuccessful ? "paymentsuccessful" : "paymentfailed";

  return (
    <Modal
      open={isOpened}
      onClose={() => setIsOpened(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ borderRadius: "20px", padding: "0" }}
    >
      <Fade in={isOpened}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              padding: "10px",
              position: "sticky",
              top: 0,
              bgcolor: "background.paper",
              zIndex: 1,
            }}
          >
            <Box>{icon}</Box>
            <Typography
              sx={{
                color: { color },
                fontSize: { xs: "24px", lg: "36px" },
                fontFamily: "var(--cygreMedium), sans-serif",
                alignItems: "center",
                textAlign: "center",
                lineHeight: { xs: "30px", lg: "45px" },
              }}
            >
              {t(text)}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
