import React, { ChangeEvent, useCallback } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
import { Box } from "@mui/material";
import CustomInput from "components/Input";
import { useTranslation } from "react-i18next";
import CustomSelectComponent from "components/Select";
import useWhites from "mokcup/whites";
import GeoMultiSelect from "components/GeoMultiSelect";

const ModalTrack = () => {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const whites = useWhites();
  // const fixedPrefix = "https://";

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof AppDataMockup) => {
      const { value } = event.target;
      saveAppData({ [field]: value });
    },
    [saveAppData]
  );
  const offerLinkValue = appData.offerLink;

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>, field: keyof AppDataMockup) => {
    const { value } = event.target;
    saveAppData({ [field]: value as string });
  };
  return (
    <Box
      sx={{
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "0 27px",
      }}
    >
      <h2
        style={{
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "16px",
          lineHeight: "32px",
        }}
      >
        {t("tracking")}
      </h2>
      <Box
        sx={{
          marginTop: "10px",
          padding: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <GeoMultiSelect />
        <Box sx={{ width: "100%", marginRight: "5px" }}>
          <CustomInput
            margin="normal"
            required
            fullWidth
            id="offerLink"
            height="40px"
            placeholder={t("enterLink")}
            name="offerLink"
            label={t("offerLink")}
            value={offerLinkValue}
            onChange={(e: any) => handleInputChange(e, "offerLink")}
            customBgColor="#F6F7F9"
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="fbPixel"
          height="40px"
          placeholder={t("enterFbPixel")}
          name="fbPixel"
          label={t("fbPixel")}
          value={appData.fbpixel}
          onChange={(e: any) => handleInputChange(e, "fbpixel")}
          customBgColor="#F6F7F9"
        />
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="fbToken"
          height="40px"
          placeholder={t("enterFbToken")}
          name="fbToken"
          label={t("fbToken")}
          value={appData.fbtoken}
          onChange={(e: any) => handleInputChange(e, "fbtoken")}
          customBgColor="#F6F7F9"
        />
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="inappropriateTraffic"
          height="40px"
          placeholder={t("setInappropriateTraffic")}
          name="inappropriateTraffic"
          label={t("inappropriateTraffic")}
          value={appData.inappropriateTraffic}
          onChange={(e: any) => handleInputChange(e, "inappropriateTraffic")}
          customBgColor="#F6F7F9"
        />
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <CustomSelectComponent
          title={t("wightSource")}
          options={whites}
          backgroundColor="#F6F7F9"
          placeholder={t("setWhite")}
          noBorder={true}
          value={appData.wightSource}
          onChange={(e: any) => handleSelectChange(e, "wightSource")}
        />
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="trafficBack"
          height="40px"
          placeholder={t("setTrafficBack")}
          name="trafficBack"
          label={t("trafficBack")}
          value={appData.trafficBack}
          onChange={(e: any) => handleInputChange(e, "trafficBack")}
          customBgColor="#F6F7F9"
        />
      </Box>
    </Box>
  );
};

export default ModalTrack;
